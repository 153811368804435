const getSubTaskStateSeverity = (subTask) => {
  if (subTask.state === "FINISHED") {
    if (subTask.cause_of_unfinished === "FAULT") {
      return "warning"
    }

    return "success"
  }

  if (subTask.state === "NOT_FINISHED") {
    return "danger"
  }

  return "info"
}

export default getSubTaskStateSeverity
