import { useContext, useEffect, useState } from "react"
import { NavLink, useParams, Redirect } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Divider } from "primereact/divider"
import { useImmer } from "use-immer"
import commonStyles from "../common.module.css"
import api from "../../api"
import userContext from "../../context/userContext"
import { useMachines } from "../../api/queries/useMachines"
import backConfirmation from "../../function/backConfirmation"
import confirmation from "../../function/confirmation"
import FileList from "../File/FileList"
import TaskHistory from "../Task/TaskHistory"
import FileUpload from "../File/FileUpload"
import FileThumb from "../File/FileThumb"
import { Fieldset } from "primereact/fieldset"
import { useOperations } from "../../api/queries/useOperations"
import { usePlaces } from "../../api/queries/usePlaces"
import { useMachineTypes } from "../../api/queries/useMachineTypes"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { useGFPShifts } from "../../api/queries/useGFPShifts"

export default function GFPForm() {
  const history = useHistory()
  const { uuid } = useParams()
  const back = () => window.history.back()
  const { user: userDetail, hasRole } = useContext(userContext)
  const { data: GFPShiftsOptions } = useGFPShifts()

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)

  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)

  const [shift, setShift] = useState("DAY")
  const handleShiftChange = (e) => setShift(e.value)

  // machine
  const { data: machines = [] } = useMachines()
  const { data: operations = [] } = useOperations()
  const { data: places = [] } = usePlaces()
  const { data: machineTypes = [] } = useMachineTypes()

  const [machine, setMachine] = useState(null)
  const handleMachineChange = (e) => setMachine(e.target.value || null)

  const [MFOperation, setMFOperation] = useState(userDetail.operation ? userDetail.operation.uuid : null)
  const handleMFOperationChange = (e) => {
    setMFOperation(e.target.value)
    setMachine(null)
    setMFPlace(null)
    setMFType(null)
  }
  const getMFOperationOptions = () => operations // operation is not filtered

  const [MFPlace, setMFPlace] = useState(null)
  const handleMFPlaceChange = (e) => {
    setMFPlace(e.target.value)
    setMachine(null)
    setMFType(null)
  }
  const getMFPlaceOptions = () => MFOperation
    ? places.filter((place) => place.operation.uuid === MFOperation)
    : places

  const [MFType, setMFType] = useState(null)
  const handleMFTypeChange = (e) => {
    setMFType(e.target.value)
    setMachine(null)
  }
  const getMFTypeOptions = () => {
    const _places = getMFPlaceOptions().map((place) => place.uuid)
    const _machines = MFPlace
      ? machines.filter((machine) => machine.place.uuid === MFPlace)
      : machines.filter((machine) => _places.indexOf(machine.place.uuid) > -1)
    const _types = _machines.map((machine) => machine.type.uuid)
    return machineTypes
      .filter((machineFilter) => _types.indexOf(machineFilter.uuid) > -1)
      .filter((machineType) => machineType.gfs)
  }

  const getMachineOptions = () => {
    let _machines = [...machines]

    if (MFOperation) {
      _machines = _machines.filter((machine) => machine.place.operation.uuid === MFOperation)
    }

    if (MFPlace) {
      _machines = _machines.filter((machine) => machine.place.uuid === MFPlace)
    }

    if (MFType) {
      _machines = _machines.filter((machine) => machine.type.uuid === MFType)
    }

    _machines = _machines.filter((machine) => machine.type.gfs)

    return _machines
  }

  // sub tasks
  const [someSubTaskWithoutPosition, setSomeSubTaskWithoutPosition] = useState(false)
  const [editedSubTaskPosition, setEditedSubTaskPosition] = useState(null)

  const [subTaskDescription, setSubTaskDescription] = useState("")
  const handleSubTaskDescriptionChange = (e) => setSubTaskDescription(e.target.value)

  const [subTaskValueDescription, setSubTaskValueDescription] = useState("")
  const handleSubTaskValueDescriptionChange = (e) => setSubTaskValueDescription(e.target.value)

  const [subTaskValueUnit, setSubTaskValueUnit] = useState("")
  const handleSubTaskValueUnitChange = (e) => setSubTaskValueUnit(e.target.value)

  const [subTaskFiles, setSubTaskFiles] = useState([])

  const [subTasks, setSubTasks] = useImmer([])

  const addSubTask = () => {
    setSubTasks((draft) => {
      draft.push({
        description: subTaskDescription,
        value_description: subTaskValueDescription,
        value_unit: subTaskValueUnit,
        position: subTasks.length + 1,
        files: subTaskFiles,
      })
    })

    setSubTaskDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskFiles([])
  }

  const moveSubTaskUp = (index) => {
    if (index > 1) {
      setSubTasks((draft) => {
        let prev = draft.find((subTask) => subTask.position === index - 1)
        let curr = draft.find((subTask) => subTask.position === index)

        prev.position = index
        curr.position = index - 1

        draft.sort((a, b) => a.position - b.position)
      })
    }
  }

  const moveSubTaskUpAsync = async (index) => {
    if (index > 1) {
      const prev = subTasks.find((subTask) => subTask.position === index - 1)
      const curr = subTasks.find((subTask) => subTask.position === index)

      const prevPayload = {
        uuid: prev.uuid,
        description: prev.description,
        value_description: prev.value_description || "",
        value_unit: prev.value_unit || "",
        position: index,
      }

      const currPayload = {
        uuid: curr.uuid,
        description: curr.description,
        value_description: curr.value_description || "",
        value_unit: curr.value_unit || "",
        position: index - 1,
      }

      await api.post(`/gfs/${uuid}/sub/${prev.uuid}`, prevPayload)
      await api.post(`/gfs/${uuid}/sub/${curr.uuid}`, currPayload)

      refresh()
    }
  }

  const moveSubTaskDown = (index) => {
    if (index < subTasks.length) {
      setSubTasks((draft) => {
        let next = draft.find((subTask) => subTask.position === index + 1)
        let curr = draft.find((subTask) => subTask.position === index)

        next.position = index
        curr.position = index + 1

        draft.sort((a, b) => a.position - b.position)
      })
    }
  }

  const moveSubTaskDownAsync = async (index) => {
    if (index < subTasks.length) {
      const next = subTasks.find((subTask) => subTask.position === index + 1)
      const curr = subTasks.find((subTask) => subTask.position === index)

      const nextPayload = {
        uuid: next.uuid,
        description: next.description,
        value_description: next.value_description || "",
        value_unit: next.value_unit || "",
        position: index,
      }

      const currPayload = {
        uuid: curr.uuid,
        description: curr.description,
        value_description: curr.value_description || "",
        value_unit: curr.value_unit || "",
        position: index + 1,
      }

      await api.post(`/gfs/${uuid}/sub/${next.uuid}`, nextPayload)
      await api.post(`/gfs/${uuid}/sub/${curr.uuid}`, currPayload)

      refresh()
    }
  }

  const removeSubTask = (index) => {
    setSubTasks(() => {
      let newDraft = []

      subTasks.forEach((subTask) => {
        if (subTask.position !== index)
          newDraft.push(Object.assign({}, subTask))
      })

      newDraft.forEach((subTask, i) => {
        subTask.position = i + 1
      })

      return newDraft
    })
  }

  const handleEditSubTaskClick = (index) => {
    setEditedSubTaskPosition(index)

    const subTask = subTasks.find((subTask) => subTask.position === index)

    setSubTaskDescription(subTask.description || "")
    setSubTaskValueDescription(subTask.value_description || "")
    setSubTaskValueUnit(subTask.value_unit || "")
    setSubTaskFiles(subTask.files || [])
  }

  const editSubTask = () => {
    setSubTasks((draft) => {
      const subTask = draft.find((subTask) => subTask.position === editedSubTaskPosition)

      subTask.description = subTaskDescription
      subTask.value_description = subTaskValueDescription
      subTask.value_unit = subTaskValueUnit
      subTask.files = subTaskFiles
    })

    // reset
    setSubTaskDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskFiles([])
    setEditedSubTaskPosition(null)
  }

  const editSubTaskAsync = async () => {
    const subTask = subTasks.find((subTask) => subTask.position === editedSubTaskPosition)

    const payload = {
      uuid: subTask.uuid,
      description: subTaskDescription,
      value_description: subTaskValueDescription,
      value_unit: subTaskValueUnit,
    }

    await api.post(`/gfs/${uuid}/sub/${subTask.uuid}`, payload)

    const addedFiles = subTaskFiles.filter((file) => !subTask.files.some((origFile) => file.uuid === origFile.uuid))
    const deletedFiles = subTask.files.filter((origFile) => !subTaskFiles.some((file) => file.uuid === origFile.uuid))

    for (let i = 0; i < addedFiles.length; i++) {
      await api.put(`/gfs/${uuid}/sub/${subTask.uuid}/file`, addedFiles[i])
    }

    for (let i = 0; i < deletedFiles.length; i++) {
      await api.delete(`/gfs/${uuid}/sub/${subTask.uuid}/file/${deletedFiles[i].uuid}`)
    }

    // reset
    setSubTaskDescription("")
    setSubTaskValueDescription("")
    setSubTaskValueUnit("")
    setSubTaskFiles([])
    setEditedSubTaskPosition(null)
    refresh()
  }

  const [taskHistory, setTaskHistory] = useState([])

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [isLoading, setIsLoading] = useState(false)

  const create = (isCloning) => {
    setIsLoading(true)

    const payload = {
      operation: userDetail.operation.uuid,
      name,
      description,
      starts_at: moment().format("YYYY-MM-DD"),
      shift: shift,
      machine,
      tasks: subTasks,
      // files,
    }

    // cloning does not preserve files. request from customer...
    payload.files = isCloning ? [] : files

    api
      .put("/gfs", payload)
      .then((response) => {
        history.push(`/gfp/${response.data.data.uuid}/subtasks`)
      })
  }

  const close = () => api
    .patch(`/gfs/${uuid}/close`, {})
    .then(back)

  const closeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu uzavřít?", close)

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (subTasks.length === 0) {
      alert("GFP neobsahuje žádné podúkoly. Pokud chcete záznam uložit přidejte podúkol.")
      return
    }

    const isCloning = e.nativeEvent.submitter.value === "save-as-new"

    if (uuid && !isCloning) {
      handleCloseAndFormSubmit()
    } else {
      create(isCloning)
    }
  }

  const handleCloseAndFormSubmit = async () => {
    await api.patch(`/gfs/${uuid}/close`, {})
    create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/gfs/${uuid}`)
        .then(({ data }) => {
          setName(data.data.name)
          setDescription(data.data.description)
          setMachine(data.data.machine.uuid)
          setSomeSubTaskWithoutPosition(data.data.tasks.some((subTask) => !subTask.position))
          setSubTasks(() => {
            data.data.tasks.forEach((subTask, index) => {
              if (!subTask.position) {
                subTask.position = index + 1
              }
            })
            return data.data.tasks
          })
          setFiles(data.data.files || [])
          setTaskHistory(data.data.history)
        })
    }
  }, [uuid, refresher, setSubTasks])

  if (hasRole("USER")) {
    return <Redirect to={"/gfps"} />
  }

  return (
    <>
      <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <label>Název:</label>
            <InputText value={name} onChange={handleNameChange} />
          </div>

          <div className={"field"}>
            <label>Popis:</label>
            <InputTextarea value={description} onChange={handleDescriptionChange} />
          </div>

          <div className={"field"}>
            <label>Směna:</label>
            <Dropdown value={shift} onChange={handleShiftChange} options={GFPShiftsOptions}
              optionLabel={"name"} optionsValue={"value"} />
          </div>

          <Fieldset className={"p-0 mb-3"}>
            <div className={"formgrid grid"}>
              <div className={"field col pl-0"}>
                <label>Provoz:</label>
                <Dropdown options={getMFOperationOptions()} value={MFOperation}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFOperationChange} />
              </div>

              <div className={"field col"}>
                <label>Místo:</label>
                <Dropdown options={getMFPlaceOptions()} value={MFPlace}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFPlaceChange} />
              </div>

              <div className={"field col pr-0"}>
                <label>Typ stroje:</label>
                <Dropdown options={getMFTypeOptions()} value={MFType}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFTypeChange} />
              </div>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Stroj:</label>
              <Dropdown required={true} value={machine} options={getMachineOptions()} optionValue={"uuid"}
                optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                filter={true} showClear={true} filterBy={"name"}
                onChange={handleMachineChange} />
            </div>
          </Fieldset>

          <Divider>Podúkoly:</Divider>
          {subTasks.length === 0 && <p>Žádné podúkoly.</p>}

          <ul className={"pl-4"}>
            {subTasks.map((subTask) => (
              <li key={subTask.description}>
                <div className={"flex align-items-center"}>
                  {subTask.description}

                  {subTask.value_description && (
                    <small className={"text-sm ml-1"}>
                      ({subTask.value_description}/{subTask.value_unit})
                    </small>
                  )}

                  <div className={"py-1 ml-auto"}>
                    <Button className={"p-button-text p-1"}
                      icon={"pi pi-arrow-up"}
                      tooltip={"Posunout o pozici výše"}
                      type={"button"}
                      disabled={someSubTaskWithoutPosition}
                      onClick={() => uuid
                        ? moveSubTaskUpAsync(subTask.position)
                        : moveSubTaskUp(subTask.position)} />

                    <Button className={"p-button-text p-1"}
                      icon={"pi pi-arrow-down"}
                      tooltip={"Posunout o pozici níže"}
                      type={"button"}
                      disabled={someSubTaskWithoutPosition}
                      onClick={() => uuid
                        ? moveSubTaskDownAsync(subTask.position)
                        : moveSubTaskDown(subTask.position)} />

                    <Button className={"p-button-text p-1"}
                      icon={"pi pi-pencil"}
                      tooltip={"Upravit"}
                      type={"button"}
                      onClick={() => handleEditSubTaskClick(subTask.position)} />

                    <Button className={"p-button-text p-1 p-button-danger"}
                      icon={"pi pi-times"}
                      tooltip={"Smazat"}
                      type={"button"}
                      onClick={() => removeSubTask(subTask.position)} />
                  </div>
                </div>

                <div className={"flex flex-wrap"}>
                  {subTask.files && subTask.files
                    .filter((file) => file.createdBy !== "USER")
                    .map((file) => (
                      <FileThumb key={file.uuid} file={file} isLarge={false} />
                    ))}
                </div>
              </li>
            ))}
          </ul>

          <Divider className={`font-normal`}>
            <span>{editedSubTaskPosition ? "Aktualizovat" : "Nový"} podúkol:</span>
          </Divider>
          <div className={"field"}>
            <label>Popis:</label>
            <InputText value={subTaskDescription} onChange={handleSubTaskDescriptionChange}
              placeholder={"Popis"} />
          </div>

          <div className={"field"}>
            <label>Sledovaná hodnota:</label>
            <div className={"p-inputgroup"}>
              <InputText value={subTaskValueDescription}
                onChange={handleSubTaskValueDescriptionChange} placeholder={"Název"}
                disabled={uuid && editedSubTaskPosition && !subTaskValueDescription} />
              <InputText value={subTaskValueUnit} onChange={handleSubTaskValueUnitChange}
                placeholder={"Jednotka"}
                disabled={uuid && editedSubTaskPosition && !subTaskValueUnit} />
            </div>
            <small className={commonStyles.fieldHelp}>Zadejte pouze v případě, že chcete aby
              pracovník vyplňoval proměnnou veličinu (např. velikost nákolku kola
              jeřábu).</small>
          </div>

          <div className={"field"}>
            <FileUpload files={subTaskFiles} setFiles={setSubTaskFiles} showClear={true} />
          </div>
        </div>

        <Button className={"p-button-raised p-button-text ml-1 mb-3"}
          type={"button"}
          disabled={!subTaskDescription}
          label={editedSubTaskPosition ? "Aktualizovat podúkol" : "Přidat podúkol"}
          onClick={editedSubTaskPosition
            ? uuid
              ? editSubTaskAsync
              : editSubTask
            : addSubTask} />

        <div className={"flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={uuid ? back : backConfirmation} />
          <div className={"ml-auto"}>
            {uuid && (
              <>
                <Button type={"button"} label={"Uzavřít"} className={"p-button-danger m-1"}
                  onClick={closeConfirmation}
                  tooltip={"Uzavřením dojde k ukončení opakování GFP."}
                  tooltipOptions={{ position: 'top' }} />

                <NavLink to={`/gfp/${uuid}/subtasks`}
                  className={`p-button m-1 ${uuid ? "" : "p-disabled"}`}>Zobrazit
                  stav podúkolů</NavLink>
              </>
            )}

            {uuid ? (
              <>
                <Button type={"submit"} label={"Uložit"} className={"m-1"} disabled={isLoading}
                  tooltip={"Uloží provedené změny."}
                  tooltipOptions={{ position: 'top' }}
                />
                <Button type={"submit"} label={"Uložit novou"} className={"m-1"} disabled={isLoading}
                  value={"save-as-new"}
                  tooltip={"Původní GFP zůstane beze změny a vytvoří se nový."}
                  tooltipOptions={{ position: 'top' }}
                />
              </>
            ) : (
              <Button type={"submit"} label={"Uložit"} className={"m-1"} disabled={isLoading}
                tooltip={"Uloží provedené změny."}
                tooltipOptions={{ position: 'top' }}
              />
            )}
          </div>
        </div>
      </form>

      <div className={"col-12 md:col-6"}>
        <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
          entityUrl={uuid ? `/gfs/${uuid}` : ""} />

        {uuid && (
          <TaskHistory history={taskHistory} taskType={"prevention"} />
        )}
      </div>
    </>
  )
}
