import {useContext} from "react"
import {NavLink} from "react-router-dom"
import {Sidebar} from "primereact/sidebar"
import {Button} from "primereact/button"
import {Divider} from "primereact/divider"
import notificationContext from "../../context/notificationContext"
import userContext from "../../context/userContext"

export default function NotificationList() {
  const user = useContext(userContext)

  return (
    <notificationContext.Consumer>
      {({notifications, isVisible, toggle, read, readAll}) => (
        <Sidebar visible={isVisible} onHide={toggle} position={"right"}>
          {notifications.length === 0 && (
            <div className={"font-italic"}>Žádné záznamy.</div>
          )}

          {notifications.map((notification) => {
            const type = notification.data.type ? notification.data.type.toLowerCase() : ""

            return (
              <div key={notification.uuid} className={"flex align-items-center py-2"}>
                <div>
                  {!type && <span>{notification.message}</span>}

                  {type && type === "report" && (
                    <NavLink to={`/task/report/${notification.data.uuid}/detail`}
                             className={"text-primary underline mr-1"}>
                      {notification.message}
                    </NavLink>
                  )}

                  {type && type === "prevention" && (
                    <NavLink to={`/task/prevention/${notification.data.uuid}${user.isAdmin() ? "" : "/subtasks"}`}
                             className={"text-primary underline mr-1"}>
                      {notification.message}
                    </NavLink>
                  )}

                  <span className={"text-sm"}> {notification.data.machine ? notification.data.machine.name : ""}</span>
                </div>

                <Button className={"p-button-danger p-button-text ml-auto p-1"}
                        icon={"pi pi-times"} onClick={() => read(notification.uuid)}/>
              </div>
            )
          })}

          {notifications.length > 0 && (
            <>
              <Divider/>

              <Button label={"Označit vše jako přečtené"}
                      className={"p-button-danger p-button-sm w-full"}
                      icon={"pi pi-times"} onClick={readAll}/>
            </>
          )}
        </Sidebar>
      )}
    </notificationContext.Consumer>
  )
}
