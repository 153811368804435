import {useEffect, useState} from "react"

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize);
    }
  }, [])

  return width
}
