import { Dropdown } from "primereact/dropdown"
import { useOperations } from "../api/queries/useOperations"

export default function OperationFilter(props) {
  const { data: operations = [] } = useOperations()

  return (
    <Dropdown options={operations} value={props.value} optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={"Provoz"} onChange={props.onChange} filter={true} />
  )
}
