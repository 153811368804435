import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import {Dropdown} from "primereact/dropdown"
import commonStyles from "../../../common.module.css"
import api from "../../../../api"
import userContext from "../../../../context/userContext"
import { useMachines } from "../../../../api/queries/useMachines"
import backConfirmation from "../../../../function/backConfirmation"
import confirmation from "../../../../function/confirmation"
import { useQueryClient } from "react-query"
import { queryKeys } from "../../../../client"

export default function EnumWarehouseSupplierForm() {
  const {uuid} = useParams()
  const { data: machines = [] } = useMachines()
  const back = () => window.history.back()
  const queryClient = useQueryClient()

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const [specification, setSpecification] = useState("")
  const handleSpecificationChange = (e) => setSpecification(e.target.value)
  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)
  const [machine, setMachine] = useState("")
  const handleMachineChange = (e) => setMachine(e.target.value)
  const [email, setEmail] = useState("")
  const handleEmailChange = (e) => setEmail(e.target.value)
  const [phone, setPhone] = useState("")
  const handlePhoneChange = (e) => setPhone(e.target.value)

  const create = () => api
    .put("/suppliers", {name, specification, description, machine, email, phone})
    .then(back)
  const update = () => api
    .post(`/suppliers/${uuid}`, {uuid, name, specification, description, machine, email, phone})
    .then(back)
  const remove = () => {
    queryClient.invalidateQueries(queryKeys.SUPPLIERS, { refetchInactive: true })
    api.delete(`/suppliers/${uuid}`)
    .then(back)
  }
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.SUPPLIERS, { refetchInactive: true })
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/suppliers/${uuid}`)
        .then(({data}) => {
          setName(data.data.name)
          setSpecification(data.data.specification)
          setDescription(data.data.description)
          setMachine(data.data.machine.uuid)
          setEmail(data.data.email)
          setPhone(data.data.phone)
        })
    }
  }, [uuid])

  return (
    <userContext.Consumer>
      {({hasPerm, isAdmin}) => (
        <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
          <div className={"p-fluid"}>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Název:</label>
              <InputText required={true} value={name} onChange={handleNameChange}/>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Specifikace:</label>
              <InputText required={true} value={specification}
                         onChange={handleSpecificationChange}/>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Popis dodávek:</label>
              <InputText required={true} value={description} onChange={handleDescriptionChange}/>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Stroj:</label>
              <Dropdown value={machine} options={machines} optionValue={"uuid"}
                        optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                        onChange={handleMachineChange}/>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Email:</label>
              <InputText type={"email"} required={true} value={email} onChange={handleEmailChange}/>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Telefon:</label>
              <InputText type={"tel"} required={true} value={phone} onChange={handlePhoneChange}/>
            </div>
          </div>

          <div className={"flex"}>
            <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
                    onClick={backConfirmation}/>
            <div className={"ml-auto"}>
              <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
                      onClick={removeConfirmation}
                      disabled={!uuid || !isAdmin()}/>
              <Button type={"submit"} label={"Uložit"} className={"m-1"}
                      disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")}/>
            </div>
          </div>
        </form>
      )}
    </userContext.Consumer>
  )
}
