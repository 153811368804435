import React from "react"
import styles from "./styles.module.css"

export default function TopButton() {
  const scrollTop = () => window.scrollTo(0, 0)

  return (
    <button type={"button"} className={`p-button p-3 md:hidden ${styles.btnTop}`}
            onClick={scrollTop}>
      <i className={"fas fa-arrow-up"}/>
    </button>
  )
}
