import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async (machineId) => {
  const { data } = await api.get(`/gfs/unique?machine=${machineId}`)
  return data.data
}

export const useGFPUnique = (machineId) => {
  const { data, ...props } = useQuery(
    queryKeys.GFP_UNIQUE,
    () => getData(machineId),
    { enabled: !!machineId }
  )
  return { data, ...props }
}
