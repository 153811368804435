import { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { Fieldset } from "primereact/fieldset"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { usePlaces } from "../../../api/queries/usePlaces"
import { useMachineTypes } from "../../../api/queries/useMachineTypes"
import { useMachines } from "../../../api/queries/useMachines"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import FileList from "../../File/FileList"
import { queryKeys } from "../../../client"
import { useQueryClient } from "react-query"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTaskRepeated } from "../../../api/queries/useTaskRepeated"
import query from "../../../function/query"
import { NavLink } from "react-router-dom";
import Tmpl from "../../../function/Tmpl"
import { useOperations } from "../../../api/queries/useOperations"
import { Checkbox } from "primereact/checkbox"

export default function EnumMachineForm() {
  const { uuid } = useParams()
  const { data: places = [] } = usePlaces()
  const [placeOptions, setPlaceOptions] = useState([])
  const { data: machineTypes = [] } = useMachineTypes()
  const { data: machines = [] } = useMachines()
  const { data: taskRepeatedOptions = [] } = useTaskRepeated()
  const { data: operationsOptions = [] } = useOperations()
  const { user } = useContext(userContext)
  const queryClient = useQueryClient()
  const back = () => window.history.back()

  const [tasks, setTasks] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(20)
  const [offset, setOffset] = useState(0)
  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
  }

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const [type, setType] = useState("")
  const handleTypeChange = (e) => setType(e.target.value)
  const [place, setPlace] = useState("")
  const handlePlaceChange = (e) => setPlace(e.target.value)
  const [parent, setParent] = useState("")
  const handleParentChange = (e) => setParent(e.target.value)
  const [operation, setOperation] = useState(user.operation ? user.operation.uuid : "")
  const handleOperationChange = (e) => setOperation(e.target.value)
  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [rented, setRented] = useState(false)
  const onRentedChange = (event) => setRented(event.target.checked)

  const [multioperation, setMultioperation] = useState(false)
  const onMultioperationChange = (event) => setMultioperation(event.target.checked)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [createdOncePreventions, setCreatedOncePreventions] = useState([])

  const create = () => api
    .put("/machines", { name, type, place, parent, files, description, rented, multioperation })
    .then(back)
  const update = () => api
    .post(`/machines/${uuid}`, { uuid, name, type, place, parent, description, rented, multioperation })
    .then(back)
  const remove = () => {
    queryClient.invalidateQueries(queryKeys.MACHINES(), { refetchInactive: true });
    api
      .delete(`/machines/${uuid}`)
      .then(back)
  }
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.MACHINES(), { refetchInactive: true });
    uuid ? update() : create()
  }

  useEffect(() => {
    setPlaceOptions(places.filter(place => place.operation.uuid === operation))
  }, [operation, places])

  useEffect(() => {
    if (uuid) {
      api.get(`/machines/${uuid}`)
        .then(({ data }) => {
          setName(data.data.name)
          setType(data.data.type.uuid)
          setPlace(data.data.place.uuid)
          setParent(data.data.parent ? data.data.parent.uuid : "")
          setFiles(data.data.files || [])
          setOperation(data.data.place.operation.uuid)
          setDescription(data.data.description || "")
          setRented(data.data.rented)
          setMultioperation(data.data.multioperation)
        })
    }
  }, [uuid, refresher])

  useEffect(() => {
    if (uuid) {
      setIsLoading(true)
      const prms = {
        machine: uuid,
        limit: limit,
        offset: offset,
        type: "PREVENTION",
        active: 1,
      }

      const queryPrmsStr = query.toString(prms)
      api.get(`/tasks/unique${queryPrmsStr}`)
        .then(({ data, headers }) => {
          setTasks(data.data)
          setTotal(parseInt(headers["x-total-count"], 10))
        })
        .finally(() => setIsLoading(false))
    }
  }, [uuid, refresher, offset, limit])

  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      <NavLink to={`/task/prevention/${row.uuid}`}>{row.name}</NavLink>
    </Tmpl>
  )

  const repeatTmpl = (rowData) => {
    const repeated = taskRepeatedOptions.find(repeated => repeated.value === rowData.repeated)
    return <Tmpl title={"Opakování"} value={repeated ? repeated.name : ""} />
  }

  const onceTmpl = (rowData) => {
    const createdOncePrevention = createdOncePreventions.find((createdOncePrevention) =>
      createdOncePrevention.current === rowData.uuid)

    if (createdOncePrevention) {
      return (
        <span>
          <i className="pi pi-check text-green-400 mr-1" />
          Údržba úspěšně vytvořena. <NavLink to={`/task/prevention/${createdOncePrevention.created}/subtasks`}>Přejít na detail</NavLink>.
        </span>
      )
    } else {
      return (
        <Button type="button" label="Mimořádná údržba" className="p-button-sm p-button-secondary"
          onClick={() => makeOncePrevention(rowData.uuid)} />
      )
    }
  }

  const makeOncePrevention = async (preventionUuid) => {
    const { data } = await api.get(`/tasks/${preventionUuid}`)
    const { statusCode, data: prevention } = data

    if (statusCode === 200) {
      // copied from prevention form submit
      const payload = {
        repeated: "ONCE",
        type: prevention.type,
        operation: prevention.operation.uuid,
        name: prevention.name,
        description: prevention.description || "",
        team: prevention.team.uuid,
        machine: prevention.machine.uuid,
        starts_at: prevention.starts_at,
        order: prevention.order || "",
        files: prevention.files,
        tasks: prevention.tasks.map((subTask) => {
          let newSubTask = {
            description: subTask.description || "",
            value_description: subTask.value_description || "",
            value_unit: subTask.value_unit || "",
            position: subTask.position,
            files: subTask.files,
          }

          if (subTask.value_min !== null) {
            newSubTask.value_min = subTask.value_min
          }

          if (subTask.value_max !== null) {
            newSubTask.value_max = subTask.value_max
          }

          return newSubTask
        }),
      }

      api
        .put("/tasks", payload)
        .then((response) => {
          setCreatedOncePreventions([...createdOncePreventions, {
            current: preventionUuid,
            created: response.data.data.uuid,
          }])
        })
    }
  }

  return (
    <userContext.Consumer>
      {({ hasPerm, isAdmin, hasRole }) => (
        <>
          <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
            <div className={"p-fluid"}>

              <div className={"field"}>
                <label className={commonStyles.labelRequired}>Název:</label>
                <InputText required={true} value={name} onChange={handleNameChange} maxLength={50} />
                <small className={commonStyles.fieldHelp}>{50 - name.length} znaků zbývá.</small>
              </div>

              <Fieldset className={"p-0 mb-3"}>
                <div className={"field"}>
                  <label>Provoz:</label>
                  <Dropdown required={true} value={operation} options={operationsOptions} optionValue={"uuid"}
                    optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                    onChange={handleOperationChange} />
                </div>

                <div className={"field"}>
                  <label className={commonStyles.labelRequired}>Místo:</label>
                  <Dropdown required={true} value={place} options={placeOptions} optionValue={"uuid"}
                    optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                    onChange={handlePlaceChange} />
                </div>
              </Fieldset>

              <div className={"field"}>
                <label className={commonStyles.labelRequired}>Typ:</label>
                <Dropdown required={true} value={type} options={machineTypes} optionValue={"uuid"}
                  optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                  onChange={handleTypeChange} />
              </div>

              <div className={"field"}>
                <label>Nadřazený stroj:</label>
                <Dropdown value={parent} options={machines.filter(machine => machine.uuid !== uuid)} optionValue={"uuid"}
                  optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                  onChange={handleParentChange} />
                <small className={commonStyles.fieldHelp}>Vyplňte pouze pokud je stroj součástí
                  jiného stroje.</small>
              </div>

              <div className={"field"}>
                <label>Poznámka:</label>
                <InputText value={description} onChange={handleDescriptionChange} />
              </div>

              <div className={"field-checkbox"}>
                <Checkbox checked={rented} onChange={onRentedChange} inputId={"rented"} />
                <label htmlFor={"rented"}>Vypůjčeno</label>
              </div>

              <div className={"field-checkbox"}>
                <Checkbox checked={multioperation} onChange={onMultioperationChange} 
                inputId={"multioperation"} />
                <label htmlFor={"multioperation"}>Multiprovozní</label>
              </div>
            </div>

            <div className={"flex"}>
              <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
                onClick={backConfirmation} />
              <div className={"ml-auto"}>
                <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
                  onClick={removeConfirmation}
                  disabled={!uuid || !isAdmin()} />
                <Button type={"submit"} label={"Uložit"} className={"m-1"}
                  disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")} />
              </div>
            </div>
          </form>

          <div className={"col-12 md:col-6"}>
            <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
              entityUrl={uuid ? `/machines/${uuid}` : ""}
              isDisabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")} />
          </div>

          {uuid && (
            <div className={"col-12 mt-4"}>
              <DataTable value={tasks} paginator={true} lazy={true}
                responsiveLayout="scroll" first={offset}
                limit={limit} offset={offset} loading={isLoading} rows={limit} totalRecords={total}
                className={`p-datatable-sm p-datatable-collapsible machine-datatable`} emptyMessage={"Žádné záznamy"}
                rowsPerPageOptions={[20, 50, 100]} onPage={onPage}
                paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
              >
                <Column
                  key={"name"} columnKey={"name"}
                  field={"name"}
                  header={"Název"} body={nameTmpl}
                />
                <Column
                  field={"name"}
                  key={"repeated"} columnKey={"repeated"}
                  header={"Opakování"} body={repeatTmpl}
                />
                {!hasRole("USER") && (
                  <Column
                    field={"once"}
                    key={"once"} columnKey={"once"}
                    header={"Mimořádná údržba"} body={onceTmpl}
                  />
                )}
              </DataTable>
            </div>
          )}
        </>
      )
      }
    </userContext.Consumer >
  )
}
