const query = {}

query.parse = (queryString) => {
  const obj = {}

  queryString
    .replace("?", "")
    .split("&")
    .map(param => param.split("="))
    .map(params => ({key: params[0], value: params[1]}))
    .forEach(({key, value}) => {
      const num = +value
      if (isNaN(num)) {
        obj[key] = value
      } else {
        obj[key] = num
      }
    })

  // parse sort query param
  //   param has this format:
  //     <column_name> (eg. name, type, ...) for ASC sorted column
  //     <column_name>_desc (eg. name_desc, type_desc, ...) for DESC sorted column
  //   output is 2 fields:
  //     sortField = <column_name>
  //     sortOrder = -1 (DESC) | 1 (ASC)

  obj.sortField = ""
  obj.sortOrder = 0

  if (obj.order) {
    const [sortField, sortOrder] = obj.order.split("_")
    obj.sortField = sortField
    obj.sortOrder = sortOrder ? -1 : 1
  }

  return obj
}

query.toString = (obj) => {
  if (obj.sortField) {
    obj.order = `${obj.sortField}${obj.sortOrder === 1 ? "" : "_desc"}`
  }

  let queryString = Object
    .keys(obj)
    .filter(key => key !== "sortField")
    .filter(key => key !== "sortOrder")
    .filter(key => obj[key] !== undefined)
    .filter(key => obj[key] !== null)
    .filter(key => obj[key] !== '')
    .map(key => `${key}=${obj[key]}`)
    .join("&")

  return queryString ? `?${queryString}` : ""
}

export default query
