import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async () => {
  const { data } = await api.get(`/task-units`)
  
  return data.data
}

export const useTaskUnits = () => {
  const { data, ...props } = useQuery(
    queryKeys.TASK_UNITS,
    () => getData(),
  )
  return { data, ...props }
}
