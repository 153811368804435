import { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { InputNumber } from "primereact/inputnumber"
import api from "../../../api"
import userContext from "../../../context/userContext"
import backConfirmation from "../../../function/backConfirmation"
import commonStyles from "../../common.module.css"
import { useOperations } from "../../../api/queries/useOperations"
import { useOperationsUsers } from "../../../api/queries/useOperationUsers"

export default function TaskReportFinishForm() {
  const { uuid, isPossible } = useParams()
  const history = useHistory()
  const back = () => window.history.back()
  const { user: userDetail, hasPerm } = useContext(userContext)

  const [isNekvalita, setIsNekvalita] = useState(false)

  const [time, setTime] = useState(0)
  const onTimeChange = (e) => setTime(parseFloat(e.target.value))

  const [note, setNote] = useState("")
  const onNoteChange = (e) => setNote(e.target.value)

  const [outcome, setOutcome] = useState("")
  const onOutcomeChange = (event) => setOutcome(event.target.value)

  const [released, setReleased] = useState(0)
  const onReleasedChange = (event) => setReleased(event.value)

  const [waste, setWaste] = useState(0)
  const onWasteChange = (event) => setWaste(event.value)

  const { data: operations } = useOperations()
  const [operation, setOperation] = useState(userDetail.operation ? userDetail.operation.uuid : "")
  const handleOperationChange = (e) => {
    setOperation(e.value)
    setResponsibleUser("")
  }

  const { data: users } = useOperationsUsers(operation)
  const userTemplate = (user) => <div>{user.name} ({user.operation.name}, {user.team.name})</div>

  const [responsibleUser, setResponsibleUser] = useState(userDetail.uuid)
  const onResponsibleUserChange = (e) => setResponsibleUser(e.target.value)

  const onFormSubmit = async (e) => {
    e.preventDefault()

    const payload = {
      uuid,
      state: isPossible === "1" ? "FINISHED" : "PARTIALY_FINISHED",
      type: "REPORT",
      note,
    }

    if (time) {
      payload.time = time
    }

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    if (isNekvalita) {
      payload.additional = {
        outcome, waste, released
      }
    }

    api.post(`/tasks/${uuid}`, payload)
      .then(() => history.push("/tasks/reports"))
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          const nekvalita = data.data.tag.key === "nekvalita"

          setOperation(data.data.created_by.operation.uuid)
          setResponsibleUser(
            nekvalita && data.data.additional && data.data.additional.processed_by
              ? data.data.additional.processed_by.uuid
              : data.data.created_by.uuid
          )

          setIsNekvalita(nekvalita)
        })
    }
  }, [uuid])

  const isFormFilled = () => {
    if (isNekvalita) {
      return outcome
    }

    if (isPossible === "1") {
      return time && note
    }

    return note && responsibleUser
  }

  return (
    <form className={"col-12 md:col-6"} onSubmit={onFormSubmit}>
      <div className={"p-fluid"}>
        {isNekvalita && (
          <>
            <div className="grid">
              <div className={"field col pl-0"}>
                <label className={commonStyles.labelRequired}>Uvolněno:</label>
                <InputNumber value={released} onChange={onReleasedChange} required={true} />
              </div>

              <div className={"field col pr-0"}>
                <label className={commonStyles.labelRequired}>Odpad:</label>
                <InputNumber value={waste} onChange={onWasteChange} required={true} />
              </div>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Výstup:</label>
              <InputText value={outcome} onChange={onOutcomeChange} required={true} />
            </div>
          </>
        )}

        {!isNekvalita && (
          <>
            <div className={"field"}>
              <label className={isPossible === "1" ? commonStyles.labelRequired : ""}>Reálně
                strávený
                čas:</label>
              <InputText value={time} onChange={onTimeChange} type={"number"}
                placeholder={"Reálně strávený čas"} required={true} step={0.25} min={0} />
              <small className={commonStyles.fieldHelp}>Počet hodin celkem strávených s tímto
                hlášením. Můžete zadat i čísla s desetinnou částí (0,5 = půl hodiny).</small>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Poznámka:</label>
              <InputText value={note} onChange={onNoteChange} placeholder={"Poznámka"}
                required={true} />
            </div>
          </>
        )}

        <div className={"field"}>
          <label>Provoz:</label>
          <Dropdown value={operation} options={operations}
            optionValue={"uuid"} optionLabel={"name"}
            emptyMessage={"Žádné záznamy"}
            onChange={handleOperationChange} />
        </div>

        <div className={"field"}>
          <label className={isPossible === "1" ? "" : commonStyles.labelRequired}>Odpovědná
            osoba:</label>
          <Dropdown value={responsibleUser} options={users} optionValue={"uuid"}
            itemTemplate={userTemplate} optionLabel={"name"}
            emptyMessage={"Žádné záznamy"}
            onChange={onResponsibleUserChange} />
        </div>
      </div>

      <div className={"flex"}>
        <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
          onClick={uuid ? back : backConfirmation} />

        <div className={"ml-auto"}>
          <Button type={"submit"} label={"Uložit"} className={"m-1"}
            disabled={!hasPerm("ROLE_PERM_REPORT_WRITE") || !isFormFilled()} />
        </div>
      </div>
    </form>
  )
}
