import {useContext, useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {InputTextarea} from "primereact/inputtextarea"
import {Button} from "primereact/button"
import {Dropdown} from "primereact/dropdown"
import {Calendar} from "primereact/calendar"
import {Divider} from "primereact/divider"
import moment from "moment"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { useMachines } from "../../../api/queries/useMachines"
import { useTeams } from "../../../api/queries/useTeams"
import { useTaskTags } from "../../../api/queries/useTaskTags"
import backConfirmation from "../../../function/backConfirmation"
import { useOperations } from "../../../api/queries/useOperations"
import useParentUsers from "../../../hook/useParentUsers"
import FileList from "../../File/FileList"

export default function TaskManagementForm() {
  const {uuid} = useParams()
  const { data: machines = [] } = useMachines()
  const { data: teams = [] } = useTeams()
  const back = () => window.history.back()
  const {user: userDetail, hasRole} = useContext(userContext)
  const nav = useHistory()

  const [state, setState] = useState("OPEN")
  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)
  const [startsAt, setStartsAt] = useState("")
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)
  const [endsAt, setEndsAt] = useState("")
  const handleEndsAtChange = (e) => setEndsAt(e.target.value)
  const [machine, setMachine] = useState("")
  const handleMachineChange = (e) => setMachine(e.target.value)
  const [team, setTeam] = useState("")
  const handleTeamChange = (e) => setTeam(e.target.value)
  const { data: taskTagOptions = [] } = useTaskTags()
  const [tag, setTag] = useState("")
  const handleTagChange = (e) => setTag(e.target.value)
  const { data: operations = [] } = useOperations()
  const [operation, setOperation] = useState(userDetail.operation ? userDetail.operation.uuid : "")
  const handleOperationChange = (e) => setOperation(e.target.value)
  const users = useParentUsers(userDetail, operation)
  const [responsibleUser, setResponsibleUser] = useState("")
  const handleResponsibleUserChange = (e) => setResponsibleUser(e.target.value)
  const userTemplate = (user) => <div>{user.name} ({user.operation.name}, {user.team.name})</div>
  const [note, setNote] = useState("")
  const handleNoteChange = (e) => setNote(e.target.value)

  const [order, setOrder] = useState("")
  const handleOrderChange = (e) => setOrder(e.target.value)

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(!hasRole("USER"))
  const toggleAdditionalInfo = () => setShowAdditionalInfo(!showAdditionalInfo)

  const [isLoading, setIsLoading] = useState(false)

  const [createdBy, setCreatedBy] = useState({})

  const create = (subUrl = "") => {
    setIsLoading(true)

    const payload = {
      name, description, tag,
    }

    if (machine) {
      payload.machine = machine
    }

    if (startsAt) {
      payload.starts_at = moment(startsAt).toISOString()
    }

    if (endsAt) {
      payload.ends_at = moment(endsAt).toISOString()
    }

    if (operation) {
      payload.operation = operation
    }

    if (team) {
      payload.team = team
    }

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    if (order) {
      payload.order = order
    }

    if (files.length > 0) {
      payload.files = files
    }

    api.put("/task-managements", payload)
      .then(({data}) => {
        if (subUrl) {
          nav.push(`/task/management/${data.data.uuid}/finish${subUrl}`)
        } else {
          nav.push(`/task/management/${data.data.uuid}/detail`)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const update = () => {
    setIsLoading(true)

    const payload = {
      uuid, state: "IN_PROGRESS", name, description, machine, tag, order,
    }

    if (startsAt) {
      payload.starts_at = moment(startsAt).toISOString()
    }

    if (endsAt) {
      payload.ends_at = moment(endsAt).toISOString()
    }

    if (operation) {
      payload.operation = operation
    }

    if (team) {
      payload.team = team
    }

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    if (note) {
      payload.note = note
    }

    api.post(`/task-managements/${uuid}`, payload)
      .then(() => nav.push(`/task/management/${uuid}/detail`))
      .finally(() => setIsLoading(false))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/task-managements/${uuid}`)
        .then(({data}) => {
          setState(data.data.state)
          setName(data.data.name)
          setDescription(data.data.description)
          if (data.data.machine) {
            setMachine(data.data.machine.uuid)
          }
          setOrder(data.data.order || "")

          if (data.data.tag) {
            setTag(data.data.tag.uuid)
          }

          if (data.data.starts_at) {
            setStartsAt(moment(data.data.starts_at).toDate())
          }

          if (data.data.ends_at) {
            setEndsAt(moment(data.data.ends_at).toDate())
          }

          if (data.data.operation) {
            setOperation(data.data.operation.uuid)
          }

          if (data.data.team) {
            setTeam(data.data.team.uuid)
          }

          if (data.data.user) {
            setResponsibleUser(data.data.user.uuid)
          }

          setFiles(data.data.files || [])

          setCreatedBy(data.data.created_by)
        })
    }
  }, [uuid, refresher])

  const onFinishClick = () => {
    if (uuid) {
      nav.push(`/task/management/${uuid}/finish/1`)
    } else {
      create("/1")
    }
  }

  const onNotFinishClick = () => {
    if (uuid) {
      nav.push(`/task/management/${uuid}/finish/0`)
    } else {
      create("/0")
    }
  }

  const onOpenClick = async () => {
    await api.post(`/task-managements/${uuid}`, {uuid, state: "IN_PROGRESS"})
    window.history.back()
  }

  const onCloseClick = async () => {
    await api.post(`/task-managements/${uuid}`, {uuid, state: "CLOSED"})
    window.history.back()
  }

  const isAuthor = () => createdBy.uuid === userDetail.uuid

  const isFormFilled = name && description && tag

  const isFormFieldDisabled = () => {
    if (uuid) {
      return !isAuthor()
    }

    return false
  }

  return (
    <userContext.Consumer>
      {({hasPerm, hasRole, isAdmin}) => (
        <>
          <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
            <div className={"p-fluid"}>
              <div className={"field"}>
                <label className={commonStyles.labelRequired}>Název:</label>
                <InputText required={true} value={name} onChange={handleNameChange} maxLength={30}
                           disabled={isFormFieldDisabled()}/>
                <small className={commonStyles.fieldHelp}>{30 - name.length} znaků zbývá.</small>
              </div>

              <div className={"field"}>
                <label className={commonStyles.labelRequired}>Detailní popis:</label>
                <InputTextarea required={true} value={description} rows={5}
                               onChange={handleDescriptionChange} disabled={isFormFieldDisabled()}/>
              </div>

              <div className={"field"}>
                <label>Stroj:</label>
                <Dropdown value={machine} options={machines} optionValue={"uuid"}
                          optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                          onChange={handleMachineChange} disabled={isFormFieldDisabled()}/>
              </div>

              <div className={"field"}>
                <label className={commonStyles.labelRequired}>Typ:</label>
                <Dropdown required={true} value={tag} options={taskTagOptions} optionValue={"uuid"}
                          optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                          onChange={handleTagChange}
                          disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}/>
              </div>

              <Divider>
                <Button type={"button"} label={"Nepovinné doplňující informace"}
                        icon={showAdditionalInfo ? "pi pi-caret-down" : "pi pi-caret-up"}
                        className={"p-button-text p-button-outlined"}
                        onClick={toggleAdditionalInfo}/>
              </Divider>

              {showAdditionalInfo && (
                <>
                  <div className={"field"}>
                    <label>Řeší provoz:</label>
                    <Dropdown value={operation} options={operations}
                              optionValue={"uuid"} optionLabel={"name"}
                              emptyMessage={"Žádné záznamy"}
                              onChange={handleOperationChange}
                              disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}/>
                  </div>

                  <div className={"field"}>
                    <label>Požadovaný termín od:</label>
                    <Calendar value={startsAt} dateFormat={"dd. mm. yy"} showTime={true}
                              hourFormat={24} onChange={handleStartsAtChange}
                              disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}
                              hideOnDateTimeSelect={true}/>
                  </div>

                  <div className={"field"}>
                    <label>Požadovaný termín do:</label>
                    <Calendar value={endsAt} dateFormat={"dd. mm. yy"} showTime={true}
                              hourFormat={24} onChange={handleEndsAtChange}
                              disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}
                              hideOnDateTimeSelect={true}/>
                  </div>

                  <div className={"field"}>
                    <label>Odpovědný tým:</label>
                    <Dropdown value={team} options={teams} optionValue={"uuid"}
                              optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                              onChange={handleTeamChange}
                              disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}/>
                  </div>

                  <div className={"field"}>
                    <label>Odpovědná osoba:</label>
                    <Dropdown value={responsibleUser} options={users} optionValue={"uuid"}
                              itemTemplate={userTemplate} optionLabel={"name"}
                              emptyMessage={"Žádné záznamy"}
                              onChange={handleResponsibleUserChange}/>
                  </div>

                  <div className={"field"}>
                    <label>Zakázka:</label>
                    <InputText value={order} onChange={handleOrderChange}/>
                  </div>

                  {uuid && (
                    <>
                      <Divider/>
                      <div className={"field"}>
                        <label>Poznámka k provedeným změnám:</label>
                        <InputTextarea value={note} onChange={handleNoteChange}
                                       placeholder={"Poznámka k provedeným změnám"}/>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </form>

          <div className={"col-12 md:col-6"}>
            {showAdditionalInfo && (
              <>
                <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
                          entityUrl={uuid ? `/task-managements/${uuid}` : ""}
                          isDisabled={!hasPerm("ROLE_PERM_MANAGEMENT_WRITE")}/>
              </>
            )}
          </div>

          <div className={"col-12 lg:col-6"}>
            <div className={"lg:flex"}>
              <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
                      onClick={uuid ? back : backConfirmation}/>
              <Button label={"Nelze dokončit"} onClick={onNotFinishClick} className={"m-1"}
                      disabled={!hasPerm("ROLE_PERM_MANAGEMENT_WRITE") || !isFormFilled || state === "FINISHED" || state === "CLOSED"}/>
              <Button label={"Hotovo"} onClick={onFinishClick} className={"m-1"}
                      disabled={!hasPerm("ROLE_PERM_MANAGEMENT_WRITE") || !isFormFilled || state === "FINISHED" || state === "CLOSED"}/>
              {isAdmin() && (
                <Button label={"Otevřít"} onClick={onOpenClick} className={"m-1"}
                        disabled={!isAdmin() || !uuid || state === "CLOSED"}/>
              )}
              {isAdmin() && (
                <Button label={"Uzavřít"} onClick={onCloseClick} className={"m-1"}
                        disabled={!isAdmin() || !uuid || state === "CLOSED"}/>
              )}
              <div className={"ml-auto"}>
                <Button type={"submit"}
                        label={"Uložit"}
                        className={"m-1"}
                        icon={isLoading ? "pi pi-spin pi-spinner" : ""}
                        onClick={handleFormSubmit}
                        disabled={isLoading || !isFormFilled || !hasPerm("ROLE_PERM_MANAGEMENT_WRITE") || state === "FINISHED"}/>
              </div>
            </div>


          </div>
        </>
      )}
    </userContext.Consumer>
  )
}
