const getTaskStateSeverity = (state) => {
  // red
  if (state === "PARTIALY_FINISHED") {
    return "danger"
  }

  // yellow
  if (state === "IN_PROGRESS") {
    return "warning"
  }

  // green
  if (state === "FINISHED") {
    return "success"
  }

  // gray
  if (state === "NOT_FINISHED") {
    return ""
  }

  // gray
  if (state === "CLOSED") {
    return ""
  }

  // blue
  // if(state === "OPEN") {
  return "info"
  // }
}

export default getTaskStateSeverity
