import {NavLink, useHistory, useLocation} from "react-router-dom"
import {useContext, useEffect, useState} from "react"
import {DataTable} from "primereact/datatable"
import {Column} from "primereact/column"
import {Badge} from "primereact/badge"
import {Button} from "primereact/button"
import {SelectButton} from "primereact/selectbutton"
import moment from "moment"
import api from "../../../api"
import query from "../../../function/query"
import userContext from "../../../context/userContext"
import { useTaskTypes } from "../../../api/queries/useTaskTypes"
import TaskTypeFilter from "../../../filter/TaskTypeFilter"
import TaskTagFilter from "../../../filter/TaskTagFilter"
import MachineFilter from "../../../filter/MachineFilter"
import TeamFilter from "../../../filter/TeamFilter"
import { useTaskStates } from "../../../api/queries/useTaskStates"
import TaskStateFilter from "../../../filter/TaskStateFilter"
import getTaskStateSeverity from "../../../function/getTaskStateSeverity"
import Tmpl from "../../../function/Tmpl"
import DateFilter from "../../../filter/DateFilter"
import commonStyles from "../../common.module.css"
import UserFilter from "../../../filter/UserFilter"
import OperationFilter from "../../../filter/OperationFilter"
import MachineTypeFilter from "../../../filter/MachineTypeFilter"
import {useWindowWidth} from "../../../hook/useWindowWidth"
import styles from "./styles.module.css"
import DateRange from "../DateRange/DateRange"
import Initials from "../Initials/Initials"
import TopButton from "../TopButton/TopButton"
import { storage } from "../../../storage"

export default function TaskManagementList() {
  const TWO_MINUTES = 120000
  const history = useHistory()
  const searchStr = useLocation().search
  const search = query.parse(searchStr)
  const [sortField, setSortField] = useState(search.sortField || "created")
  const [sortOrder, setSortOrder] = useState(search.sortOrder || -1)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }
  const user = useContext(userContext)

  const windowWidth = useWindowWidth()

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const onPage = ({first, rows}) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const idTmpl = (row) => <Tmpl title={"Číslo"} value={row.id}/>
  const nameTmpl = (row) => (
      <Tmpl title={"Název"}>
        {user.hasPerm("ROLE_PERM_MANAGEMENT_READ")
          ? <NavLink to={`/task/management/${row.uuid}/detail`}>{row.name}</NavLink>
          : row.name
        }
      </Tmpl>
    )

  const { data: taskStates = [] } = useTaskStates()
  const taskStateTmpl = (row) => {
    const taskState = taskStates.find(taskState => taskState.value === row.state)
    return (
      <Tmpl title={"Stav"}>
        <Badge value={taskState ? taskState.name : ""}
               severity={getTaskStateSeverity(taskState ? taskState.value : "")}
               className={"block white-space-nowrap overflow-hidden text-overflow-ellipsis"}/>
      </Tmpl>
    )
  }
  const [taskState, setTaskState] = useState(search.state || null)
  const handleTaskStateChange = (e) => setTaskState(e.target.value)
  const taskStateFilter = <TaskStateFilter value={taskState} onChange={handleTaskStateChange}/>

  const createdByTmpl = (row) => (
    <Tmpl title={"Založil"}>
      {row.created_by && (
        <span className={"word-break-word"}>{row.created_by.name}</span>
      )}
    </Tmpl>
  )
  const [createdBy, setCreatedBy] = useState(search.created_by || null)
  const handleCreatedByChange = (e) => setCreatedBy(e.target.value)
  const createdByFilter = <UserFilter value={createdBy} onChange={handleCreatedByChange}
                                      placeholder={"Založil"}/>

  const { data: taskTypes = [] } = useTaskTypes()
  const taskTypeTmpl = (row) => {
    const taskType = taskTypes.find(taskType => taskType.value === row.type)
    return <Tmpl title={"Kategorie"} value={taskType ? taskType.name : ""}/>
  }
  const [taskType, setTaskType] = useState(search.type || null)
  const handleTaskTypeChange = (e) => setTaskType(e.target.value)
  const taskTypeFilter = <TaskTypeFilter value={taskType} onChange={handleTaskTypeChange}/>

  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = <MachineFilter value={machine} onChange={handleMachineChange}/>
  const machineTmpl = (row) => (
    <Tmpl title={"Stroj"}>
      <strong>{row.machine ? row.machine.name : ""}</strong>
    </Tmpl>
  )

  const [machineType, setMachineType] = useState(search.machine_type || null)
  const handleMachineTypeChange = (e) => setMachineType(e.target.value)
  const machineTypeFilter = <MachineTypeFilter value={machineType}
                                               onChange={handleMachineTypeChange}/>
  const machineTypeTmpl = (row) => <Tmpl title={"Typ stroje"}
                                         value={row.machine && row.machine.type ? row.machine.type.name : ""}/>

  const [taskTag, setTaskTag] = useState(search.tag || null)
  const handleTaskTagChange = (e) => setTaskTag(e.target.value)
  const taskTagFilter = <TaskTagFilter value={taskTag} onChange={handleTaskTagChange}/>
  const taskTagTmpl = (row) => {
    if (windowWidth >= 768) {
      return <Tmpl title={"Typ"} value={row.tag ? row.tag.name : ""}/>
    }

    if (!row.tag) {
      return ""
    }

    return (
      <span className={styles.taskTagAbbr}>
        {row.tag ? row.tag.abbr : ""}
      </span>
    )
  }

  const [team, setTeam] = useState(search.team || null)
  const handleTeamChange = (e) => setTeam(e.target.value)
  const teamFilter = <TeamFilter value={team} onChange={handleTeamChange}/>
  const teamTmpl = (row) => <Tmpl title={"Tým"} value={row.team ? row.team.name : ""}/>

  const [userOption, setUserOption] = useState(search.user || null)
  const handleUserOptionChange = (e) => setUserOption(e.target.value)
  const userFilter = <UserFilter value={userOption} onChange={handleUserOptionChange}
                                 placeholder={"Odpovědná osoba"}/>
  const userTmpl = (row) => <Tmpl title={"Odpovědná osoba"} value={row.user ? row.user.name : ""}/>

  const [operation, setOperation] = useState(search.operation || null)
  const handleOperationChange = (e) => setOperation(e.target.value)
  const operationFilter = <OperationFilter value={operation} onChange={handleOperationChange}/>
  const operationTmpl = (row) => <Tmpl title={"Řeší provoz"}
                                       value={row.operation ? row.operation.name : ""}/>

  const [startsAt, setStartsAt] = useState(search.starts_at
    ? moment(search.starts_at).toDate() : null)
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)
  const startsAtFilter = <DateFilter value={startsAt} onChange={handleStartsAtChange}
                                     placeholder={"Požadovaný termín od"}/>
  const startsAtTmpl = (row) => (
    <Tmpl
      title={"Požadovaný termín od"}
      value={row.starts_at ? moment(row.starts_at).format("DD. MM. YYYY") : ""}
    />
  )

  const [endsAt, setEndsAt] = useState(search.ends_at
    ? moment(search.ends_at).toDate() : null)
  const handleEndsAtChange = (e) => setEndsAt(e.target.value)
  const endsAtFilter = <DateFilter value={endsAt} onChange={handleEndsAtChange}
                                   placeholder={"Požadovaný termín do"}/>
  const endsAtTmpl = (row) => (
    <Tmpl
      title={"Požadovaný termín do"}
      value={row.ends_at ? moment(row.ends_at).format("DD. MM. YYYY") : ""}
    />
  )

  const isUrgent = (row) => (row.state === "OPEN" || row.state === "IN_PROGRESS") &&
    row.tag && row.tag.key === "akutni"

  const isWarn = (row) => (row.state === "OPEN" || row.state === "IN_PROGRESS") &&
    row.tag && row.tag.key === "varovani"

  const rowClass = (row) => ({
    [commonStyles.tableRowDanger]: isUrgent(row),
    [commonStyles.tableRowWarning]: isWarn(row),
  })

  const [selectedRow, setSelectedRow] = useState("")
  const onRowClick = (e) => setSelectedRow(
    selectedRow && selectedRow.uuid === e.data.uuid
      ? ""
      : e.data
  )

  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const toggleFilters = () => setIsFiltersVisible(!isFiltersVisible)

  const viewTypes = [
    {label: "Aktivní úkoly", value: "active"},
    {label: 'Historie úkolů', value: "history"},
  ]
  const [viewType, setViewType] = useState(search.view || "active")
  const changeViewType = (e) => e.value && setViewType(e.value)

  const header = (
    <>
      <label className={"hidden sm:inline"}>Zobrazit:</label>
      <SelectButton value={viewType} options={viewTypes} onChange={changeViewType}
                    className={"inline-flex mx-1"}/>

      <Button className={"button-toggle-filters mx-1 p-button-raised p-button-text"}
              label={"Filtrovat"} onClick={toggleFilters}/>
    </>
  )

  const handleExport = () => {
    const params = Object.assign({}, search)
    delete params.limit
    delete params.offset

    api.get(`/task-managements/export${query.toString(params)}`)
      .then(({data}) => {
        window.open(data.data.url)
      })
  }

  useEffect(() => {
    const getData = () => {
      setIsLoading(true)

      let prms = {
        limit, offset, state: taskState, type: taskType, machine, tag: taskTag, team,
        user: userOption, operation, sortField, sortOrder, machine_type: machineType,
        created_by: createdBy,
        view: viewType,
      }

      if (startsAt) {
        prms.starts_at = moment(startsAt).format("YYYY-MM-DD")
      }

      if (endsAt) {
        prms.ends_at = moment(endsAt).format("YYYY-MM-DD")
      }

      if (search.my_tasks) {
        prms.my_tasks = search.my_tasks
      }

      const queryParamsStr = query.toString(prms)
      history.push({search: queryParamsStr})

      //
      let storageParams = Object.assign({}, prms)
      // delete storageParams.limit
      // delete storageParams.offset
      storage.set("managementListQueryParams", query.toString(storageParams))
      //

      api.get(`/task-managements${queryParamsStr}`)
        .then(({data, headers}) => {
          setTotal(parseInt(headers["x-total-count"], 10))
          setData(data.data)
        })
        .finally(() => setIsLoading(false))
    }

    const updateInterval = setInterval(getData, TWO_MINUTES)

    getData()

    return () => {
      clearInterval(updateInterval)
    }
  }, [limit, offset, taskState, taskType, machine, taskTag, team, history, sortField, sortOrder,
    startsAt, endsAt, userOption, operation, machineType, viewType, createdBy, search.my_tasks])

  const desktopColumns = [
    <Column header={"#"} body={idTmpl} sortable={true} sortField={"id"}/>,
    <Column header={"Stroj"} body={machineTmpl} filter={true}
            filterElement={machineFilter} sortable={true} sortField={"machine"}/>,
    <Column header={"Název"} body={nameTmpl} sortable={true} sortField={"name"}/>,
    <Column header={"Stav"} body={taskStateTmpl} filter={true}
            filterElement={taskStateFilter} sortable={true} sortField={"state"}/>,
    <Column header={"Založil"} body={createdByTmpl} filter={true}
            filterElement={createdByFilter} className={"p-column-hidden"} sortable={true}
            sortField={"createdby"}/>,
    <Column header={"Kategorie"} body={taskTypeTmpl} filter={true}
            filterElement={taskTypeFilter} sortable={true} sortField={"type"}
            className={"p-column-hidden"}/>,
    <Column header={"Typ stroje"} body={machineTypeTmpl} filter={true}
            filterElement={machineTypeFilter} sortable={true} sortField={"machinetype"}/>,
    <Column header={"Typ"} body={taskTagTmpl} filter={true} filterElement={taskTagFilter}
            sortable={true} sortField={"tag"} className={"p-column-hidden"}/>,
    <Column header={"Tým"} body={teamTmpl} filter={true} filterElement={teamFilter}
            className={"p-column-hidden"} sortable={true} sortField={"team"}/>,
    <Column header={"Odpovědná osoba"} body={userTmpl} filter={true}
            filterElement={userFilter} className={"p-column-hidden"} sortable={true}
            sortField={"user"}/>,
    <Column header={"Řeší provoz"} body={operationTmpl} filter={true}
            filterElement={operationFilter} className={"p-column-hidden"} sortable={true}
            sortField={"operation"}/>,
    <Column header={"Požadovaný termín od"} body={startsAtTmpl} filter={true}
            filterElement={startsAtFilter} className={"p-column-hidden"} sortable={true}
            sortField={"start"}/>,
    <Column header={"Požadovaný termín do"} body={endsAtTmpl} filter={true}
            filterElement={endsAtFilter} className={"p-column-hidden"} sortable={true}
            sortField={"endsat"}/>
  ]

  const mobileMessTemplate = (row) => (
    <>
      <div className={"flex align-items-center"}>
        <div className={styles.w20}>{idTmpl(row)}</div>
        <div className={styles.w20}>{machineTmpl(row)}</div>
        <div className={styles.w60}>{nameTmpl(row)}</div>
      </div>
      <div className={"flex align-items-center"}>
        <div className={styles.w20}>{taskTagTmpl(row)}</div>
        <div className={styles.w20}>
          {row.user && <Initials name={row.user.name}/>}
        </div>
        <div className={styles.w40}>
          <DateRange startsAt={row.starts_at} endsAt={row.ends_at} finishedAt={row.finished_at}/>
        </div>
        <div className={styles.w15}>{taskStateTmpl(row)}</div>
      </div>
    </>
  )

  const mobileColumns = [
    <Column header={""} key={"mobile"} body={mobileMessTemplate} className={"p-column-common"}/>,
  ]

  return (
    <userContext.Consumer>
      {({hasPerm}) => (
        <>
          <div className={"col-12"}>
            <div className={"flex"}>
              <NavLink to={"/task/management"}
                       className={`p-button mx-1 ${hasPerm("ROLE_PERM_MANAGEMENT_WRITE") ? "" : "p-disabled"}`}>
                Nové hlášení</NavLink>
              <Button className={"p-button-sm p-button-secondary ml-auto mx-1"} onClick={handleExport}>Export</Button>
            </div>
          </div>

          <div className={"col-12"}>
            <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
                       rows={limit} first={offset} totalRecords={total} onPage={onPage}
                       loading={isLoading} onSort={onSort} sortField={sortField}
                       sortOrder={sortOrder} rowClassName={rowClass}
                       className={`p-datatable-sm p-datatable-collapsible ${isFiltersVisible ? "p-datatable-filters" : ""}`}
                       selectionMode={"single"} selection={selectedRow} onRowClick={onRowClick}
                       header={header}
                       paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
                       rowsPerPageOptions={[20, 50, 100]}>
              {windowWidth >= 768 ? desktopColumns : mobileColumns}
            </DataTable>
          </div>

          <TopButton/>
        </>
      )}
    </userContext.Consumer>
  )
}
