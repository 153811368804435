import { useContext, useEffect, useRef, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { Menubar } from "primereact/menubar"
import { Button } from "primereact/button"
import { Badge } from "primereact/badge"
import { Menu } from "primereact/menu"
import { Avatar } from "primereact/avatar"
import userContext from "../../context/userContext"
import notificationContext from "../../context/notificationContext"
import styles from "./styles.module.css"
import logo from "./logo.svg"
// import logoText from "./logotext.svg"

export default function Navbar() {
  const history = useHistory()
  const location = useLocation()
  const handleNavigate = (e) => history.push(e.item.link)
  const user = useContext(userContext)

  const [showMyTasks, setShowMyTasks] = useState(false)
  useEffect(() => {
    if (
      location.pathname.indexOf("/tasks/reports") > -1 ||
      location.pathname.indexOf("/tasks/preventions") > -1 ||
      location.pathname.indexOf("/tasks/management") > -1
    ) {
      setShowMyTasks(true)
    } else {
      setShowMyTasks(false)
    }
  }, [location])

  const userMenuRef = useRef()
  const userMenuModel = [
    {
      template: (item, options) => {
        const split = user.user.name.split(" ")
        const initials = split.length > 1
          ? `${split[0].charAt(0)}${split[1].charAt(0)}`
          : `${split[0].charAt(0)}`

        return (
          <div className={options.className}>
            <Avatar label={initials} style={{ backgroundColor: "#ced500" }}
              className="mr-3" shape="circle" />
            <div className="flex flex-column align">
              <span className="font-bold">{user.user.name}</span>
              <span className="text-sm">{user.user.operation ? user.user.operation.name : ""}</span>
            </div>
          </div>
        )
      }
    },
    {
      separator: true
    },
    {
      label: "Změnit heslo",
      command: () => history.push("/profile/change-password"),
    },
    {
      label: "Odhlásit",
      command: () => user.logout(),
    }
  ]

  const logout = (
    <>
      <Menu model={userMenuModel} ref={userMenuRef} popup={true} />
      <Button icon="fas fa-circle-user fa-xl" className="p-button-rounded ml-3"
        onClick={(e) => userMenuRef.current.toggle(e)} />

    </>
    // <Button onClick={logoutConfirmation} className={"p-button-sm ml-3 white-space-nowrap"}>
    //   <span className={"hidden lg:inline"}>Odhlásit ({user.user.name}, {user.user.operation.name})</span>
    //   <i className={"pi pi-sign-out lg:hidden"} />
    // </Button>
  )

  const envName = process.env.REACT_APP_ENV_NAME
  const envTitle = `Používáte ${envName === "TEST" ? "testovací" : "vývojovou"} verzi aplikace DORA. Všechny provedené změny mohou být ztraceny. Přejděte na https://dora-dec.cz pro produkční verzi aplikace.`

  const start = (
    <NavLink to={"/"} className={"flex align-items-center"}>
      {/* <img src={logoText} alt={"Logo"} width={125} className={"hidden lg:inline-block mr-2"} /> */}
      <img src={logo} alt={"Logo"} height={30} className={"mr-1"} />
      <span className={styles.logoBold}>DORA</span>
      <span className={styles.logoThin}>MAINTENANCE</span>
      {envName && <span className={styles.envName} title={envTitle}>{envName}</span>}
    </NavLink>
  )

  const end = (
    <div className={"flex align-items-center justify-content-between"}>
      <NavLink to={"/colors"} className={"text-xl py-2 px-3 text-green-400"}
        title="Legenda barev">
        <i className="fa fa-palette" />
      </NavLink>

      {showMyTasks && (
        <NavLink to={`?my_tasks=${user.user.uuid}`} title={"Moje úkoly"}
          className={`${styles.btnMyTasks} py-2 px-3`}>
          <i className={"fas fa-tasks fa-lg"} />
        </NavLink>
      )}

      <notificationContext.Consumer>
        {({ notifications, toggle }) => (
          <i className={"pi pi-bell py-2 px-3 p-overlay-badge text-xl cursor-pointer"}
            onClick={toggle} title={"Notifikace"}>
            {notifications.length > 0 && (
              <Badge value={`${notifications.length}`} severity={"danger"}
                className={styles.badge} />
            )}
          </i>
        )}
      </notificationContext.Consumer>

      <span className={""}>
        {logout}
      </span>
    </div>
  )

  const items = [{
    label: "Úkoly",
    items: [{
      label: "Pravidelná údržba",
      link: "/tasks/preventions",
      command: handleNavigate,
    }, {
      label: "Hlášení",
      link: "/tasks/reports",
      command: handleNavigate,
    }, {
      label: "Stroje",
      link: "/tasks/machines",
      command: handleNavigate,
    },],
  }, {
    label: "GFP",
    link: "/gfps",
    command: handleNavigate,
  }, {
    label: "VZV",
    link: "/vzvs",
    command: handleNavigate,
  }, {
    label: "Management",
    items: [{
      label: "Management",
      link: "/tasks/management",
      disabled: !user.hasPerm("ROLE_PERM_MANAGEMENT_READ"),
      command: handleNavigate,
    }, {
      label: "Reporty",
      items: [{
        label: "Pravidelná údržba",
        link: "/report/tasks",
        command: handleNavigate,
      },],
    }]
  }, {
    label: "Sklad",
    link: "/warehouses",
    command: handleNavigate,
  }, {
    label: "GWP",
    link: "/gwps",
    command: handleNavigate,
  }, {
    label: "Nastavení",
    disabled: !user.hasPerm("ROLE_PERM_ENUMS_WRITE"),
    items: [{
      label: "Stroje",
      link: "/enum/machines",
      command: handleNavigate,
    }, {
      label: "Typy strojů",
      link: "/enum/machine-types",
      command: handleNavigate,
    }, {
      label: "Pracovníci",
      link: "/enum/users",
      command: handleNavigate,
    }, {
      label: "Týmy",
      link: "/enum/teams",
      command: handleNavigate,
    }, {
      label: "Provozy",
      link: "/enum/operations",
      command: handleNavigate,
    }, {
      label: "Místa",
      link: "/enum/places",
      command: handleNavigate,
    }, {
      label: "Typy úkolů",
      link: "/enum/task-tags",
      command: handleNavigate,
    }, {
      label: "Sklad",
      items: [{
        label: "Seznam skladů",
        link: "/enum/warehouse/warehouses",
        command: handleNavigate,
      }, {
        label: "Dodavatelé",
        link: "/enum/warehouse/suppliers",
        command: handleNavigate,
      }, {
        label: "Kategorie",
        link: "/enum/warehouse/categories",
        command: handleNavigate,
      }, {
        label: "Umístění",
        link: "/enum/warehouse/positions",
        command: handleNavigate,
      }, {
        label: "Díly",
        link: "/enum/warehouse/parts",
        command: handleNavigate,
      },],
    }, {
      label: "Vady",
      link: "/enum/task-defects",
      command: handleNavigate,
    // }, {
    //   label: "Množství",
    //   link: "/enum/task-amounts",
    //   command: handleNavigate,
    }, {
      label: "Postupy",
      link: "/enum/task-processes",
      command: handleNavigate,
    }, {
      label: "Jednotky",
      link: "/enum/task-units",
      command: handleNavigate,
    },],
  },]

  return (
    <Menubar
      model={items}
      start={start}
      end={end}
      className={styles.navbar}
    />
  )
}
