import store from "store"

const GKEY = "cora"

export const storage = {
  set: (key, val) => {
    const data = store.get(GKEY) || {}
    data[key] = val
    store.set(GKEY, data)
  },

  get: (key) => {
    const data = store.get(GKEY) || {}

    if (data[key]) {
      return data[key]
    }

    return null
  },

  delete: (key) => {
    const data = store.get(GKEY) || {}
    delete data[key]
    store.set(GKEY, data)
  }
}
