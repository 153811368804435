import { v4 as uuid } from "uuid"
import styles from "./styles.module.css"
import FileThumb from "./FileThumb"
import { useRef } from "react"

export default function FileUpload({ files, setFiles, disabled, showClear, originalFileUuids }) {
  const fileInputRef = useRef()

  const resetFileInput = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => resolve({
        uuid: uuid(),
        name: file.name,
        content: reader.result,
        mimeType: file.type,
      });

      reader.readAsDataURL(file);
    })
  }

  const onFilesSelect = async (event) => {
    if (event.target.files.length > 0) {
      const eventFiles = [...event.target.files]
      const newFiles = await Promise.all(eventFiles.map((file) => readFile(file)))

      setFiles([...files, ...newFiles])
      resetFileInput()
    }
  }

  const onFileDelete = (uuid) => {
    setFiles(files.filter((file) => file.uuid !== uuid))
    resetFileInput()
  }

  return (
    <div className={styles.fileContainer}>
      {files.map((file) => {
        let isFileDeletable = false

        if (Array.isArray(originalFileUuids)) {
          isFileDeletable = showClear && originalFileUuids.indexOf(file.uuid) === -1
        } else {
          isFileDeletable = showClear
        }

        return (
          <FileThumb
            key={file.uuid}
            file={file}
            deleteHandler={isFileDeletable ? onFileDelete : null}
          />
        )
      })}

      {!disabled && (
        <label className={styles.fileUploadButton} title={"Přidat přílohu"}>
          <i className={"fas fa-camera fa-xl"} />
          <input
            type={"file"}
            onChange={onFilesSelect}
            className={"hidden"}
            ref={fileInputRef}
            multiple
          />
        </label>
      )}
    </div>
  )
}
