import React, {useEffect, useState} from "react"
import moment from "moment"
import {useParams} from "react-router-dom"
import {RadioButton} from "primereact/radiobutton"
import {Button} from "primereact/button"
import {Calendar} from "primereact/calendar"
import api from "../../../../api"

export default function TaskReportRemind() {
  const {uuid} = useParams()

  const [task, setTask] = useState({})

  const [radioButtonValue, setRadioButtonValue] = useState("")
  const handleRadioButtonValueChange = (e) => setRadioButtonValue(e.value)

  const handleCalendarValueChange = (e) => setDate(e.value)

  const [date, setDate] = useState("")

  const submit = (e) => {
    e.preventDefault()

    api.post(`/reminder`, {
      task: uuid,
      date: moment(date).toISOString(true)
    }).then(() => window.history.back())
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({data}) => setTask(data.data))
    }
  }, [uuid])

  useEffect(() => {
    let date

    switch (radioButtonValue) {
      case "1h":
        date = moment().add(1, "hours")
        break
      case "2h":
        date = moment().add(2, "hours")
        break
      case "4h":
        date = moment().add(4, "hours")
        break
      case "tomorrow9":
        date = moment().add(1, "days").hour(9).minute(0)
        break
      case "tomorrow14":
        date = moment().add(1, "days").hour(14).minute(0)
        break
      case "2d":
        date = moment().add(2, "days")
        break
      case "4d":
        date = moment().add(4, "days")
        break
      case "1w":
        date = moment().add(1, "weeks")
        break
      case "2w":
        date = moment().add(2, "weeks")
        break
      case "1m":
        date = moment().add(1, "months")
        break
      case "2m":
        date = moment().add(2, "months")
        break
      default:
        return setDate("")
    }

    setDate(date.toDate())
  }, [radioButtonValue])

  return (
    <form className={"col-12"} onSubmit={submit}>
      <h3>Připomenout úkol {task.name}</h3>

      <div className={"grid"}>
        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"1h"} inputId={"1h"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "1h"}/>
            <label htmlFor={"1h"}>Za hodinu</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"2h"} inputId={"2h"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "2h"}/>
            <label htmlFor={"2h"}>Za 2 hodiny</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"4h"} inputId={"4h"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "4h"}/>
            <label htmlFor={"4h"}>Za 4 hodiny</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"tomorrow9"} inputId={"tomorrow9"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "tomorrow9"}/>
            <label htmlFor={"tomorrow9"}>Zítra v 9:00</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"tomorrow14"} inputId={"tomorrow14"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "tomorrow14"}/>
            <label htmlFor={"tomorrow14"}>Zítra v 14:00</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"2d"} inputId={"2d"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "2d"}/>
            <label htmlFor={"2d"}>Za 2 dny</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"4d"} inputId={"4d"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "4d"}/>
            <label htmlFor={"4d"}>Za 4 dny</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"1w"} inputId={"1w"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "1w"}/>
            <label htmlFor={"1w"}>Za týden</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"2w"} inputId={"2w"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "2w"}/>
            <label htmlFor={"2w"}>Za 2 týdny</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"1m"} inputId={"1m"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "1m"}/>
            <label htmlFor={"1m"}>Za měsíc</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"2m"} inputId={"2m"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "2m"}/>
            <label htmlFor={"2m"}>Za 2 měsíce</label>
          </div>
        </div>

        <div className={"col-12 md:col-4"}>
          <div className={"field-radiobutton"}>
            <RadioButton value={"custom"} inputId={"custom"} name={"notify"}
                         onChange={handleRadioButtonValueChange}
                         checked={radioButtonValue === "custom"}/>
            <label htmlFor={"custom"}>Vlastní</label>
          </div>
        </div>

        <div className={"col-12"}>
          {radioButtonValue === "custom" && (
            <Calendar
              inline={true}
              showTime={true}
              hourFormat={24}
              value={date}
              onChange={handleCalendarValueChange}
            />
          )}
        </div>

        <div className={"col-12"}>
          Úkol bude připomenut {date ? moment(date).format("D. M. YYYY HH:mm") : "..."}
        </div>

        <div className={"col-12"}>
          <Button label={"Uložit"} type={"submit"} disabled={!date}/>
        </div>
      </div>
    </form>
  )
}
