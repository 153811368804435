import {useEffect, useState} from "react"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {BreadCrumb} from "primereact/breadcrumb"
import {regUUID, urls} from "./urls.js"
import styles from "./styles.module.css"

export default function Breadcrumb() {
  const location = useLocation()
  const history = useHistory()
  const [model, setModel] = useState([])
  const home = {
    icon: "pi pi-home",
    command: () => history.push("/")
  }

  useEffect(() => {
    const url = Object
      .keys(urls)
      .find((key) => new RegExp(`^${key}$`).test(location.pathname))

    if (url) {
      const UUIDMatch = location.pathname.match(regUUID)
      const UUID = UUIDMatch ? UUIDMatch[0] : ""
      const model = urls[url].map((crumb, i) => ({
        template: () => urls[url].length === i + 1 // is last
          ? <span className={styles.breadcrumbItem}>{crumb.label}</span>
          : <NavLink to={crumb.url.replace("{uuid}", UUID)}
                     className={styles.breadcrumbItemLink}>{crumb.label}</NavLink>,
      }))

      setModel(model)
    }
  }, [location.pathname])

  return (
    <div className={"col-12"}>
      <BreadCrumb model={model} home={home} className={styles.breadcrumb}/>
    </div>
  )
}
