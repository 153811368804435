import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, NavLink, useHistory, useParams } from "react-router-dom"
import { MentionsInput, Mention } from "react-mentions"
import moment from "moment"
import { Button } from "primereact/button"
import { AutoComplete } from "primereact/autocomplete"
import { Accordion, AccordionTab } from "primereact/accordion"
import { Badge } from "primereact/badge"
import { ScrollPanel } from "primereact/scrollpanel"
import api from "../../../../api"
import TaskHistory from "../../TaskHistory"
import styles from "./styles.module.css"
import DateRange from "../../DateRange/DateRange"
import WarehouseItemForm from "../../WarehouseItemForm"
import userContext from "../../../../context/userContext"
import confirmation from "../../../../function/confirmation"
import { useWindowWidth } from "../../../../hook/useWindowWidth"
import TopButton from "../../TopButton/TopButton"
import commonStyles from "../../../common.module.css"
import FileUpload from "../../../File/FileUpload"
import FileThumb from "../../../File/FileThumb"
import mentionsStyles from "./mentions.module.css"
import { useTaskSwitch } from "../../../../api/queries/useTaskSwitch"
import { storage } from "../../../../storage"
import { formatMachineName } from "../../../../function/machine"

export default function TaskReportDetail() {
  const history = useHistory()
  const { uuid } = useParams()
  const { user, hasPerm, isAdmin, hasRole } = useContext(userContext)
  const [task, setTask] = useState({})

  const hasUnfinishedRelatedChildTask = (
    task &&
    task.related &&
    task.related.some((relatedTask) =>
      relatedTask.is_child &&
      (relatedTask.state === "OPEN" || relatedTask.state === "IN_PROGRESS"))
  ) || false

  const hasNekvalitaEmptyProcess = (
    task &&
    task.tag &&
    task.tag.key === "nekvalita" &&
    task.additional &&
    (!task.additional.task_process || !task.additional.process_description)
  ) || false

  const getDiscMentionsTimeout = useRef()
  const [isDiscLoading, setIsDiscLoading] = useState("")
  const [discMsg, setDiscMsg] = useState("")
  const [discFiles, setDiscFiles] = useState([])
  const handleDiscMsgChange = (event, newValue, newPlainTextValue, mentions) => setDiscMsg(newValue)
  const getDiscMentions = async (search, callback) => {
    clearTimeout(getDiscMentionsTimeout.current)

    if (search) {
      getDiscMentionsTimeout.current = setTimeout(async () => {
        const { data } = await api.get(`/users?fulltext=${search}&limit=10`)
        const arr = data.data.map((mentionsUser) => ({
          id: mentionsUser.uuid,
          display: mentionsUser.name,
        }))

        callback(arr)
      }, 250)
    }
  }

  const getFormattedDiscContent = (post) => {
    let content = post.text
    const mentions = []

    post.mentions.forEach((mention) => {
      // mention format is (user name)[userUuid:1111-2222-aaaa-bbbb]
      // groups are for 'name! and 'uuid'
      const reg = new RegExp(`\\((.*)\\)\\[userUuid:(${mention.uuid})\\]`)
      const match = content.match(reg)

      if (match.length === 3) {
        const str = match[0]  // original string
        const name = match[1] // user name
        const uuid = match[2] // user uuid

        mentions.push({ str, name, uuid })

        content = content.replace(str, "___mention___")
      }
    })

    let mentionIndex = 0
    let arr = content
      .split(/(___mention___|\n)/) // split but keep values
      .map((item, i) => {
        // mention
        if (item === "___mention___") {
          const { uuid, name } = mentions[mentionIndex]
          mentionIndex++

          return (
            <span
              key={i}
              onClick={() => history.push(`/enum/user/${uuid}`)}
              className={mentionsStyles.mention_item_link}
            >{name}</span>
          )
        }

        // new line
        if (item === "\n") {
          return <br key={i} />
        }

        // text
        return <span key={i}>{item}</span>
      })

    return arr
  }

  const handleDiscussionSubmit = (e) => {
    e.preventDefault()
    setIsDiscLoading(true)

    const payload = {
      text: discMsg,
      files: discFiles.map((file) => ({
        name: file.name,
        note: "",
        content: file.content,
      }))
    }

    api.put(`/tasks/${uuid}/discussion`, payload)
      .then(() => {
        setDiscMsg("")
        setDiscFiles([])
        refresh()
      })
      .finally(() => setIsDiscLoading(false))
  }

  const [loading, setLoading] = useState(false)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const windowWidth = useWindowWidth()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen)

  const back = () => window.history.back()
  const edit = () => history.push(`/task/report/${uuid}`)
  const remind = () => history.push(`/task/report/${uuid}/remind`)

  const finish = () => {
    if (!hasUnfinishedRelatedChildTask && !hasNekvalitaEmptyProcess) {
      history.push(`/task/report/${uuid}/finish/1`)
    }
  }

  const notFinish = () => {
    if (!hasUnfinishedRelatedChildTask && !hasNekvalitaEmptyProcess) {
      history.push(`/task/report/${uuid}/finish/0`)
    }
  }

  const handleExport = () => api
    .get(`/tasks/${uuid}/pdf`)
    .then(({ data }) => window.open(data.data.url))
  const take = () => {
    setLoading(true)
    api.post(`/tasks/${uuid}`, { uuid, user: user.uuid, state: "IN_PROGRESS" })
      .then(refresh)
      .finally(() => setLoading(false))
  }
  const remove = () => api
    .delete(`/tasks/${uuid}`)
    .then(back)
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)
  const onOpenClick = async () => {
    await api.post(`/tasks/${uuid}`, { uuid, state: "IN_PROGRESS" })
    refresh()
  }
  const onCloseClick = async () => {
    await api.post(`/tasks/${uuid}`, { uuid, state: "CLOSED" })
    refresh()
  }

  const handleSubReportCreate = () => {
    history.push(`/task/report/subtask/${uuid}`)
  }

  const [activeIndex, setActiveIndex] = useState([])
  const handleActiveIndexChange = (e) => setActiveIndex(e.index)

  const [relatedTask, setRelatedTask] = useState(null)
  const handleRelatedTaskChange = (e) => setRelatedTask(e.value)
  const handleRelatedTaskSuggestions = (e) => api
    .get(`/tasks?id=${e.query}`)
    .then(({ data }) => {
      // filter already related tasks
      const filtered = data.data.filter((item) => {
        return !task.related.some((relatedTask) => relatedTask.uuid === item.uuid)
      })
      setRelatedTaskSuggestions(filtered)
    })
  const [relatedTaskSuggestions, setRelatedTaskSuggestions] = useState([])
  const relatedTaskTmpl = (item) => `${item.id} - ${item.name}`
  const handleRelatedTaskSubmit = (e) => {
    e.preventDefault()

    api.post(`/tasks/${uuid}/related`, {
      uuid: relatedTask.uuid,
      is_child: false,
    }).then(() => {
      setRelatedTask(null)
      refresh()
    })
  }

  const relatedTasksHeaderTmpl = () => {
    let count = task.related ? task.related.length : 0

    if (task.vzv) {
      count += 1
    }

    return (
      <h3 className={"m-0"}>
        Související úkoly <Badge value={`${count}`} className={`ml-3 ${styles.badge}`} />
      </h3>
    )
  }

  const attachmentsHeaderTmpl = () => (
    <h3 className={"m-0"}>
      Přílohy
      <Badge value={`${task.files && task.files.length}`} className={`ml-3 ${styles.badge}`} />
    </h3>
  )

  const warehouseHeaderTmpl = () => (
    <h3 className={"m-0"}>
      Díly k vyskladnění
    </h3>
  )

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)
        })
    }
  }, [uuid, refresher])

  const listQuery = storage.get("reportListQueryParams") || ""
  const { data: taskSwitch, isLoading: isTaskSwitchLoading } = useTaskSwitch("tasks", uuid, listQuery)

  const onTaskProcessClick = () => history.push(`/task/report/${uuid}/process`)

  return (
    <>
      <div className={"col-12"}>
        <div className="flex">
          {isTaskSwitchLoading && (
            <Button className="p-button p-button-sm p-button-link" />
          )}
          {taskSwitch && taskSwitch.prev && (
            <NavLink to={`/task/report/${taskSwitch.prev}/detail`}
              className={"p-button p-button-sm p-button-link"}>
              <i className="pi pi-chevron-left text-sm mr-2" />
              Předchozí hlášení
            </NavLink>
          )}
          {taskSwitch && taskSwitch.next && (
            <NavLink to={`/task/report/${taskSwitch.next}/detail`}
              className={"p-button p-button-sm p-button-link ml-auto"}>
              Následující hlášení
              <i className="pi pi-chevron-right text-sm ml-2" />
            </NavLink>
          )}
        </div>
      </div>

      <div className={"col-12"}>
        <div className={"flex relative"}>
          <Button label={"Zpět"} onClick={back} className={`mr-1 ${styles.btnBack}`} />

          <Button label={"Převzít úkol"} className={`mr-1 ${styles.btnTake}`} onClick={take}
            disabled={loading} />

          {task.state !== "CLOSED" && (
            <>
              {task.state === "FINISHED" || task.state === "PARTIALY_FINISHED" ? (
                <>
                  {isAdmin() && (
                    <>
                      <Button label={"Otevřít"} onClick={onOpenClick} className={"mr-1"} />
                      <Button label={"Uzavřít"} onClick={onCloseClick} className={"mr-1"} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {hasPerm("ROLE_PERM_REPORT_WRITE") && (
                    <>
                      <Button label={windowWidth < 768 ? "Nelze" : "Nelze dokončit"}
                        onClick={notFinish} className={"mr-1"}
                        disabled={loading}
                        tooltip={
                          hasUnfinishedRelatedChildTask
                            ? "Hlášení má nedokončené podúkoly."
                            : hasNekvalitaEmptyProcess
                              ? "Nekvalita nemá vyplněný postup."
                              : ""
                        }
                        tooltipOptions={{ position: "top" }} />

                      <Button label={"Hotovo"} onClick={finish} className={"mr-1"}
                        disabled={loading}
                        tooltip={
                          hasUnfinishedRelatedChildTask
                            ? "Hlášení má nedokončené podúkoly."
                            : hasNekvalitaEmptyProcess
                              ? "Nekvalita nemá vyplněný postup."
                              : ""
                        }
                        tooltipOptions={{ position: "top" }} />
                    </>
                  )}
                </>
              )}
            </>
          )}

          <button className={`p-button block md:hidden ml-auto ${styles.btnSecondary}`}
            onClick={toggleMobileMenu}>
            <i className={"fa-solid fa-ellipsis-vertical"} />
          </button>

          <div
            className={`ml-auto ${isMobileMenuOpen ? styles.mobileMenuOpen : styles.mobileMenu}`}>
            <Button label={"Upravit"} onClick={edit} className={`mr-1 ${styles.btnSecondary}`} />

            <Button label={"Připomenout"} onClick={remind}
              className={`mr-1 ${styles.btnSecondary}`} />

            <Button label={"Exportovat"} onClick={handleExport}
              className={`mr-1 ${styles.btnSecondary}`} />

            <Button label={"Podúkol"} onClick={handleSubReportCreate}
              className={`mr-1 ${styles.btnSecondary}`} />

            <Button label={"Smazat"} onClick={removeConfirmation}
              className={`mr-1 ${styles.btnDelete}`}
              disabled={!isAdmin() || task.state !== "OPEN"} />
          </div>
        </div>
      </div>

      <div className={"col-12 md:col-4"}>
        <div className={`p-3 ${styles.report}`}>
          <div className={`flex ${styles.mainInfo}`}>
            <span className={"pr-3"}>{task.id}</span>
            {/* <strong>{task.machine ? task.machine.name : ""}</strong> */}
            <strong>{task.machine ? formatMachineName(task.machine, true) : ""}</strong>
            <span className={"ml-auto"}>{task.machine ? task.machine.place.name : ""}</span>
          </div>

          <h2 className={"my-2"}>{task.name}</h2>

          <DateRange startsAt={task.starts_at} endsAt={task.ends_at} finishedAt={task.finished_at} />

          <div className="grid">
            <div className="col pl-0">
              <div className={"mt-2 mb-1"}>
                <span className={styles.label}>Typ: </span>
                <span>{task.tag ? task.tag.name : ""}</span>
              </div>

              <div className={"my-1"}>
                <span className={styles.label}>Řeší provoz: </span>
                <span>{task.operation ? task.operation.name : ""}</span>
              </div>

              <div className={"my-1"}>
                <span className={styles.label}>Odpovědný tým: </span>
                <span>{task.team ? task.team.name : ""}</span>
              </div>

              <div className={"my-1"}>
                <span className={styles.label}>Odpovědná osoba: </span>
                <span>{task.user ? task.user.name : ""}</span>
              </div>

              <div className={"flex align-items-baseline pt-2"}>
                <span className={"mr-3"}>
                  <span className={styles.label}>Založil: </span>
                  {task.created_by ? task.created_by.name : ""}
                </span>
                <small className={styles.label}>
                  {task.created_at ? moment(task.created_at).format("D. M. YYYY | H:m") : ""}
                </small>
                <small className={"ml-auto"}>{task.order}</small>
              </div>
            </div>

            {task.tag && task.tag.key === "nekvalita" && (
              <div className="col pr-0">
                <div className={"mt-2 mb-1"}>
                  <span className={styles.label}>Zakázka nebo tavba: </span>
                  <span>{task.additional ? task.additional.order : ""}</span>
                </div>

                <div className={"my-1"}>
                  <span className={styles.label}>Výrobek nebo průměr čepu: </span>
                  <span>{task.additional ? task.additional.commodity : ""}</span>
                </div>

                <div className={"my-1"}>
                  <span className={styles.label}>Slitina nebo nástroj: </span>
                  <span>{task.additional ? task.additional.alloy : ""}</span>
                </div>

                <div className={"my-1"}>
                  <span className={styles.label}>Vada: </span>
                  <span>{task.additional && task.additional.task_defect ? task.additional.task_defect.name : ""}</span>
                </div>

                <div className={"my-1"}>
                  <span className={styles.label}>Množství: </span>
                  <span>{task.additional ? `${task.additional.amount}${task.additional.task_unit ? task.additional.task_unit.name : ""}` : ""}</span>
                </div>
              </div>
            )}
          </div>

          <div className={`p-2 mt-1 ${styles.description}`}>
            {task.description}
          </div>

          {task.tag && task.tag.key === "nekvalita" && (
            <div className="mt-3">
              <div className="flex justify-content-between align-items-center">
                <div>
                  <span className={styles.label}>Postup: </span>
                  {task.additional && task.additional.task_process ? task.additional.task_process.name : ""}
                </div>

                <div>
                  <span className={styles.label}>Technolog: </span>
                  {task.additional && task.additional.processed_by ? task.additional.processed_by.name : ""}
                </div>

                <small className={styles.label}>
                  {task.additional.process_date ? moment(task.additional.process_date).format("D. M. YYYY | H:m") : ""}
                </small>

                {!hasRole("USER") && (
                  <div>
                    <Button label="Upravit" className="p-button-text p-button-sm p-button-secondary"
                      onClick={onTaskProcessClick} />
                  </div>
                )}
              </div>

              <div className={`p-2 mt-1 ${styles.description}`}>
                {task.additional ? task.additional.process_description : ""}
              </div>
            </div>
          )}
        </div>

        <Accordion multiple={true} activeIndex={activeIndex}
          ontabChange={handleActiveIndexChange}
          expandIcon={"pi pi-chevron-down"} collapseIcon={"pi pi-chevron-up"}>
          <AccordionTab headerTemplate={relatedTasksHeaderTmpl}
            headerClassName={styles.accordionHeader}
            contentClassName={styles.accordionContent}>
            {task.related && task.related.length === 0 && !task.vzv && <i>Žádná související hlášení.</i>}
            {task.related && task.related.length > 0 && task.related.map((task) => (
              <div key={task.uuid} className={"py-1 underline"}>
                {task.type === "REPORT" ? (
                  <>
                    {task.is_child && <i className="fa-solid fa-turn-down" title="Podúkol" />}
                    {task.is_parent && <i className="fa-solid fa-turn-up" title="Rodičovský úkol" />}
                    {!task.is_child && !task.is_parent && <i className="fa-solid fa-equals" title="Související úkol" />}

                    <Link to={`/task/report/${task.uuid}/detail`} title={"Hlášení"}
                      className={"text-700 ml-1"}>{task.id} - {task.name}</Link>
                  </>
                ) : (
                  <>
                    <i className="fa-solid fa-equals" title="Související úkol" />
                    <Link to={`/task/prevention/${task.uuid}/subtasks`} title={"Pravidelná údržba"}
                      className={"text-700 ml-1"}>{task.id} - {task.name}</Link>
                  </>
                )}

              </div>
            ))}
            {task.vzv && (
              <div className={"py-1 underline"}>
                <i className="fa-solid fa-book" title="VZV deník" />
                <Link to={`/vzv/${task.vzv.uuid}/subtasks`} title={"VZV deník"}
                  className={"text-700 ml-1"}>{task.vzv.id} - {task.vzv.name}</Link>
              </div>
            )}

            <form onSubmit={handleRelatedTaskSubmit} className={"pt-4"}>
              <div className={"p-fluid"}>
                <div className={"field"}>
                  <AutoComplete
                    field={"name"}
                    value={relatedTask}
                    suggestions={relatedTaskSuggestions}
                    completeMethod={handleRelatedTaskSuggestions}
                    onChange={handleRelatedTaskChange}
                    itemTemplate={relatedTaskTmpl}
                    selectedItemTemplate={relatedTaskTmpl}
                  />
                  <small className={commonStyles.fieldHelp}>Zadejte číslo hlášení, na které
                    chcete odkazovat.</small>
                </div>
              </div>

              <div className={"text-right"}>
                <Button label={"Uložit odkaz"} disabled={!(relatedTask && relatedTask.uuid)} />
              </div>
            </form>
          </AccordionTab>

          <AccordionTab headerTemplate={attachmentsHeaderTmpl}
            headerClassName={styles.accordionHeader}
            contentClassName={styles.accordionContent}>
            {task.files && task.files.length === 0 && (
              <i>Žádné přílohy...</i>
            )}

            <div className={"flex flex-wrap"}>
              {task.files && task.files.map((file) => (
                <FileThumb key={file.uuid} file={file} isLarge={true} />
              ))}
            </div>

          </AccordionTab>

          <AccordionTab headerTemplate={warehouseHeaderTmpl}
            headerClassName={styles.accordionHeader}
            contentClassName={styles.accordionContent}>
            <WarehouseItemForm taskUuid={uuid} onChange={refresh} taskType={"report"} />
          </AccordionTab>
        </Accordion>
      </div >

      <div className={"col-12 md:col-4"}>
        <h3 className={"mt-0"}>Diskuze</h3>

        <form onSubmit={handleDiscussionSubmit}>
          <div className={"p-fluid"}>
            <div className={"field mb-1"}>
              <MentionsInput
                value={discMsg}
                onChange={handleDiscMsgChange}
                className={"mentions"}
                classNames={mentionsStyles}
              >
                <Mention
                  data={getDiscMentions}
                  markup={"(__display__)[userUuid:__id__]"}
                  className={"mention_item"}
                  classNames={mentionsStyles}
                />
              </MentionsInput>
            </div>
          </div>

          <div className={"flex"}>
            <FileUpload files={discFiles} setFiles={setDiscFiles} />

            <Button type={"submit"} className={"ml-auto"}
              disabled={!discMsg || isDiscLoading}>Odeslat</Button>
          </div>
        </form>

        <ScrollPanel style={{ width: "100%", height: "500px" }} className={"mt-3"}>
          {task.discussion && task.discussion.length === 0 && <i>Žádné příspěvky...</i>}

          {task.discussion && [...task.discussion].reverse().map((post) => (
            <div key={post.uuid} className={"mt-3"}>
              <div>
                <span className={"mr-2"}>{post.created_by.name}</span>
                <small className={styles.discussionDate}>
                  {moment(post.created_at).format("D. M. YYYY | H:mm")}
                </small>
              </div>

              <div className={styles.discussionText}>
                {getFormattedDiscContent(post)}
              </div>

              <div className={"flex flex-wrap"}>
                {post.files.map((file) => (
                  <FileThumb key={file.uuid} file={file} />
                ))}
              </div>
            </div>
          ))}
        </ScrollPanel>
      </div>

      <div className={"col-12 md:col-4"}>
        <TaskHistory history={task.history || []} taskType={"report"} />
      </div>

      <TopButton />
    </>
  )
}
