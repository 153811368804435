import { Dropdown } from "primereact/dropdown"
import { useTaskRepeated } from "../api/queries/useTaskRepeated"

export default function TaskRepeatedFilter(props) {
  const { data: taskRepeated = [] } = useTaskRepeated()

  return (
    <Dropdown options={taskRepeated} value={props.value} optionLabel={"name"}
      optionValue={"value"} placeholder={"Opakování"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
