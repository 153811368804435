import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import { Checkbox } from "primereact/checkbox"
import { useQueryClient } from "react-query"
import { queryKeys } from "../../../client"

export default function EnumTaskAmountForm() {
  const { uuid } = useParams()
  const back = () => window.history.back()
  const { isAdmin } = useContext(userContext)
  const queryClient = useQueryClient()

  const [name, setName] = useState("")
  const onNameChange = (e) => setName(e.target.value)
  const [active, setActive] = useState(true)
  const onActiveChange = (e) => setActive(e.target.checked)

  const create = () => {
    api
      .put("/task-amounts", { name, active })
      .then(back)

    queryClient.invalidateQueries(queryKeys.TASK_AMOUNTS, { refetchInactive: true })
  }

  const update = () => {
    api
      .post(`/task-amounts/${uuid}`, { uuid, name, active })
      .then(back)

    queryClient.invalidateQueries(queryKeys.TASK_AMOUNTS, { refetchInactive: true })
  }

  const remove = () => {
    api.delete(`/task-amounts/${uuid}`)
      .then(back)

    queryClient.invalidateQueries(queryKeys.TASK_AMOUNTS, { refetchInactive: true })
  }

  const removeConfirmation = (event) => confirmation(event.currentTarget, "Opravdu smazat?", remove)

  const onFormSubmit = (event) => {
    event.preventDefault()

    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/task-amounts/${uuid}`)
        .then(({ data }) => {
          const taskAmount = data.data

          setName(taskAmount.name)
          setActive(taskAmount.active)
        })
    }
  }, [uuid])

  return (
    <form className={"col-12 md:col-6"} onSubmit={onFormSubmit}>
      <div className={"p-fluid"}>
        <div className={"field"}>
          <label className={commonStyles.labelRequired}>Název:</label>
          <InputText required={true} value={name} onChange={onNameChange} />
        </div>

        <div className={"field-checkbox"}>
          <Checkbox checked={active} onChange={onActiveChange} inputId="active" />
          <label htmlFor="active">Aktivní</label>
        </div>
      </div>

      <div className={"flex"}>
        <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
          onClick={backConfirmation} />

        <div className={"ml-auto"}>
          <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
            onClick={removeConfirmation}
            disabled={!uuid || !isAdmin()} />

          <Button type={"submit"} label={"Uložit"} className={"m-1"}
            disabled={!isAdmin()} />
        </div>
      </div>
    </form>
  )
}
