const reportColumnsDefaultState = [
  {
    key: "id",
    label: "Číslo",
    visible: true,
    index: 0,
    disabled: true,
  },
  {
    key: "machine",
    label: "Stroj",
    visible: true,
    index: 1,
    disabled: false,
  },
  {
    key: "name",
    label: "Název",
    visible: true,
    index: 2,
    disabled: true,
  },
  {
    key: "state",
    label: "Stav",
    visible: true,
    index: 3,
    disabled: false,
  },
  {
    key: "createdBy",
    label: "Založil",
    visible: true,
    index: 4,
    disabled: false,
  },
  {
    key: "type",
    label: "Kategorie",
    visible: true,
    index: 5,
    disabled: false,
  },
  {
    key: "machineType",
    label: "Typ stroje",
    visible: true,
    index: 6,
    disabled: false,
  },
  {
    key: "tag",
    label: "Typ",
    visible: true,
    index: 7,
    disabled: false,
  },
  {
    key: "team",
    label: "Tým",
    visible: true,
    index: 8,
    disabled: false,
  },
  {
    key: "user",
    label: "Odpovědná osoba",
    visible: true,
    index: 9,
    disabled: false,
  },
  {
    key: "operation",
    label: "Řeší provoz",
    visible: true,
    index: 10,
    disabled: false,
  },
  {
    key: "startsAt",
    label: "Požadovaný termín od",
    visible: true,
    index: 11,
    disabled: false,
  },
  {
    key: "endsAt",
    label: "Požadovaný termín do",
    visible: true,
    index: 12,
    disabled: false,
  },
  {
    key: "createdAt",
    label: "Datum založení",
    visible: true,
    index: 13,
    disabled: false,
  },
  {
    key: "finishedAt",
    label: "Datum dokončení",
    visible: true,
    index: 14,
    disabled: false,
  },
]

export default reportColumnsDefaultState
