import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import {MultiSelect} from "primereact/multiselect"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import { useMachineTypes } from "../../../api/queries/useMachineTypes"
import { queryKeys } from "../../../client"
import { useQueryClient } from "react-query"

export default function EnumOperationForm() {
  const {uuid} = useParams()
  const back = () => window.history.back()
  const queryClient = useQueryClient()

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)

  const { data: machineTypes = [] } = useMachineTypes()
  const [machineType, setMachineType] = useState([])
  const handleMachineTypeChange = (e) => setMachineType(e.target.value || [])

  const create = () => api
    .put("/operations", {name, machine_types: machineType})
    .then(back)
  const update = () => api
    .post(`/operations/${uuid}`, {uuid, name, machine_types: machineType})
    .then(back)
  const remove = () => {
    queryClient.invalidateQueries(queryKeys.OPERATIONS, { refetchInactive: true })
    api.delete(`/operations/${uuid}`)
    .then(back)
  }
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.OPERATIONS, { refetchInactive: true })
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/operations/${uuid}`)
        .then(({data}) => {
          setName(data.data.name)
          if (data.data.machine_types) {
            setMachineType(data.data.machine_types.map(machineType => machineType.uuid))
          }
        })
    }
  }, [uuid])

  return (
    <userContext.Consumer>
      {({hasPerm, isAdmin}) => (
        <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
          <div className={"p-fluid"}>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Název:</label>
              <InputText required={true} value={name} onChange={handleNameChange}/>
            </div>

            <div className={"field"}>
              <label>Typ stroje:</label>
              <MultiSelect value={machineType} options={machineTypes}
                           optionValue={"uuid"} optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                           selectedItemsLabel={"{0} záznamů vybráno"}
                           onChange={handleMachineTypeChange} showClear={true}/>
              <small className={commonStyles.fieldHelp}>Vyplňte pouze v případě, že se provoz stará
                o určitý typ stroje (např. TZV spravuje jeřáby).</small>
            </div>
          </div>

          <div className={"flex"}>
            <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
                    onClick={backConfirmation}/>
            <div className={"ml-auto"}>
              <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
                      onClick={removeConfirmation}
                      disabled={!uuid || !isAdmin()}/>
              <Button type={"submit"} label={"Uložit"} className={"m-1"}
                      disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")}/>
            </div>
          </div>
        </form>
      )}
    </userContext.Consumer>
  )
}
