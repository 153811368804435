import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async () => {
  const { data } = await api.get(`/users`)
  return data.data
}

export const useUsers = () => {
  const { data, ...props } = useQuery(
    queryKeys.USERS,
    () => getData(),
  )
  return { data, ...props }
}
