import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async () => {
  const { data } = await api.get(`/subtask-causes`)
  return data.data
}

export const useSubTaskCauses = () => {
  const { data, ...props } = useQuery(
    queryKeys.SUB_TASK_CAUSES,
    () => getData(),
  )
  return { data, ...props }
}
