import { NavLink, useHistory, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { SelectButton } from "primereact/selectbutton"
import api from "../../api"
import query from "../../function/query"
import Tmpl from "../../function/Tmpl"
import TopButton from "../Task/TopButton/TopButton"
import { storage } from "../../storage"
import { useGFPShifts } from "../../api/queries/useGFPShifts"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import moment from "moment"
import DateFilter from "../../filter/DateFilter"
import { useMachines } from "../../api/queries/useMachines"

export default function GFPList() {
  const TWO_MINUTES = 120000
  const history = useHistory()
  const searchStr = useLocation().search
  const search = query.parse(searchStr)
  const [sortField, setSortField] = useState(search.sortField || "created")
  const [sortOrder, setSortOrder] = useState(search.sortOrder || -1)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const { data: GFPShiftOptions = [] } = useGFPShifts()
  const { data: machineOptions = [] } = useMachines()

  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const idTmpl = (row) => (
    <Tmpl title={"Číslo"}>
      <NavLink to={`/gfp/${row.uuid}/subtasks`}>{row.id}</NavLink>
    </Tmpl>
  )
  const [fId, setFId] = useState("")
  const handleFIdChange = (e) => setFId(e.target.value)
  const idFilter = <InputText value={fId} onChange={handleFIdChange} placeholder={"#"} />

  const statsTmpl = (row) => <Tmpl title={"Dokončeno podúkolů"}
    value={row.tasks_stats
      ? `${row.tasks_stats.OK}/${row.tasks_stats.total}`
      : ""} />

  const createdAtTmpl = (row) => (
    <Tmpl title={"Vytvořeno"} value={moment(row.created_at).format("DD. MM. YYYY")} />
  )
  const [fCreatedAt, setFCreatedAt] = useState(null)
  const handleFCreatedAtChange = (e) => setFCreatedAt(e.value)
  const createdAtFilter = (
    <DateFilter value={fCreatedAt} onChange={handleFCreatedAtChange} placeholder={"Vytvořeno"} />
  )

  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = (
    <Dropdown options={machineOptions.filter(m => m.type.gfs)} value={machine} optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={"Stroj"} onChange={handleMachineChange} filter={true} />
  )
  const machineTmpl = (row) => (
    <Tmpl title={"Stroj"}>
      <strong>{row.machine ? row.machine.name : ""}</strong>
    </Tmpl>
  )

  const shiftTmpl = (row) => {
    const shift = GFPShiftOptions.find((s) => s.value === row.shift)

    if (shift) {
      return <Tmpl title={"Směna"} value={shift.name} />
    }

    return ""
  }
  const [fShift, setFShift] = useState(null)
  const handleShiftChange = (e) => setFShift(e.value)
  const shiftFilter = <Dropdown options={GFPShiftOptions} optionLabel={"name"} optionValue={"value"}
    value={fShift} onChange={handleShiftChange} showClear={true} placeholder={"Směna"} />

  const tIsExtraordinary = (row) =>
    row.is_extraordinary ? <i className="pi pi-check" /> : <i className="pi pi-times" />

  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const toggleFilters = () => setIsFiltersVisible(!isFiltersVisible)

  const viewTypes = [
    { label: "Aktivní GFP", value: "active" },
    { label: 'Historie GFP', value: "history" },
  ]
  const [viewType, setViewType] = useState(search.view || "active")
  const changeViewType = (e) => e.value && setViewType(e.value)

  const header = (
    <div className={"flex align-items-center"}>
      <label className={"hidden sm:inline"}>Zobrazit:</label>
      <SelectButton value={viewType} options={viewTypes} onChange={changeViewType}
        className={"inline-flex mx-1"} />

      <Button className={"button-toggle-filters mx-1 p-button-raised p-button-text"}
        label={"Filtrovat"} onClick={toggleFilters} />
    </div>
  )

  useEffect(() => {
    const getData = () => {
      setIsLoading(true)

      let prms = {
        limit, offset, machine, sortField, sortOrder, view: viewType, id: fId, shift: fShift,
      }

      if (fCreatedAt) {
        prms.created_at = moment(fCreatedAt).format("YYYY-MM-DD")
      }

      const queryParamsStr = query.toString(prms)
      history.push({ search: queryParamsStr })

      api.get(`/gfs${queryParamsStr}`)
        .then(({ data, headers }) => {
          setTotal(parseInt(headers["x-total-count"], 10))
          setData(data.data)
        })
        .finally(() => setIsLoading(false))
    }

    const updateInterval = setInterval(getData, TWO_MINUTES)

    getData()

    return () => {
      clearInterval(updateInterval)
    }
  }, [limit, offset, machine, history, sortField, sortOrder, viewType, search.my_tasks, fShift, fId,
    fCreatedAt])

  return (
    <>
      <div className={"col-12"}>
        <NavLink to={"/gfp"} className={"p-button p-button-primary"}>Nové GFP</NavLink>
        <NavLink to={"/gfp/once"} className={"p-button p-button-primary ml-1"}>Mimořádné GFP</NavLink>
      </div>

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading}
          onSort={onSort} sortField={sortField} sortOrder={sortOrder}
          className={`p-datatable-sm p-datatable-collapsible ${isFiltersVisible ? "p-datatable-filters" : ""}`}
          header={header}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}
        >
          <Column
            key={"id"} columnKey={"id"}
            header={"#"} body={idTmpl}
            sortable={true} sortField={"id"}
            filter={true} filterElement={idFilter}
          />

          <Column
            key={"machine"} columnKey={"machine"}
            header={"Stroj"} body={machineTmpl}
            filter={true} filterElement={machineFilter}
          />

          <Column
            key={"stats"} columnKey={"stats"}
            header={"Dokončeno podúkolů"} body={statsTmpl}
          />

          <Column
            key={"created_at"} columnKey={"created_at"}
            header={"Vytvořeno"} body={createdAtTmpl}
            filter={true} filterElement={createdAtFilter}
          />

          <Column
            key={"shift"} columnKey={"shift"}
            header={"Směna"} body={shiftTmpl}
            filter={true} filterElement={shiftFilter}
          />

          <Column key="is_extraordinary" columnKey="is_extraordinary"
            header="Mimořádné" body={tIsExtraordinary} />
        </DataTable>
      </div>

      <TopButton />
    </>
  )
}
