import {confirmPopup} from "primereact/confirmpopup"

const confirmation = (target, message, accept) => {
  confirmPopup({
    target,
    icon: "pi pi-exclamation-triangle",
    message,
    accept,
    acceptLabel: "Ano",
    rejectLabel: "Ne",
  })
}

export default confirmation
