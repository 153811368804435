import { NavLink } from "react-router-dom"
import { Button } from "primereact/button"
import userContext from "../../context/userContext"

export default function TaskListControls({ exportHandler, showMassActions = false }) {
  return (
    <userContext.Consumer>
      {({ hasPerm, hasRole }) => (
        <div className={"col-12"}>
          <div className={"flex"}>
            {hasPerm("ROLE_PERM_PREVENTION_WRITE") && !hasRole("USER") && (
              <NavLink to={"/task/prevention"} className={"p-button mr-1"}>
                Nová pravidelná údržba
              </NavLink>
            )}


            {hasPerm("ROLE_PERM_REPORT_WRITE") && (
              <NavLink to={"/task/report"} className={"p-button mr-1"}>
                Nové hlášení
              </NavLink>
            )}

            {hasPerm("ROLE_PERM_PREVENTION_WRITE") && !hasRole("USER") && showMassActions && (
              <NavLink to={"/task/prevention/bulk"} className={"p-button p-button-secondary mr-1"}>
                Hromadné akce
              </NavLink>
            )}

            <Button className={"p-button-sm p-button-secondary ml-auto"}
              onClick={exportHandler}>Export</Button>
          </div>
        </div>
      )}
    </userContext.Consumer>
  )
}
