import { useContext } from "react"
import { NavLink } from "react-router-dom"
import userContext from "../../context/userContext"
import { useTaskTags } from "../../api/queries/useTaskTags"
import styles from "./styles.module.css"

export default function Homepage() {
  const { data: taskTags = [] } = useTaskTags()
  const { hasPerm } = useContext(userContext)

  const ehsTag = taskTags.find((tag) => tag.key === "ehs")
  const nekvalitaTag = taskTags.find((tag) => tag.key === "nekvalita")

  return (
    <>
      {hasPerm("ROLE_PERM_REPORT_READ") && (
        <div className={"col-12 md:col-3 text-center"}>
          <NavLink to={"/tasks/reports"}
            className={`block p-card p-component ${styles.bgBlue}`}>
            <div className={"p-card-body"}>
              <div className={"p-card-content"}>
                Hlášení
              </div>
            </div>
          </NavLink>
        </div>
      )}

      {hasPerm("ROLE_PERM_PREVENTION_READ") && (
        <div className={"col-12 md:col-3 text-center"}>
          <NavLink to={"/tasks/preventions"}
            className={`block p-card p-component ${styles.bgBlue}`}>
            <div className={"p-card-body"}>
              <div className={"p-card-content"}>
                Pravidelná údržba
              </div>
            </div>
          </NavLink>
        </div>
      )}

      {hasPerm("ROLE_PERM_MANAGEMENT_READ") && (
        <div className={"col-12 md:col-3 text-center"}>
          <NavLink to={"/tasks/management"}
            className={`block p-card p-component ${styles.bgBlue}`}>
            <div className={"p-card-body"}>
              <div className={"p-card-content"}>
                Management
              </div>
            </div>
          </NavLink>
        </div>
      )}

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={"/tasks?view=history"}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              Historie
            </div>
          </div>
        </NavLink>
      </div>

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={"/tasks/machines"} className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              Stroje
            </div>
          </div>
        </NavLink>
      </div>

      {hasPerm("ROLE_PERM_ENUMS_WRITE") && (
        <div className={"col-12 md:col-3 text-center"}>
          <NavLink to={"/enum/users"}
            className={`block p-card p-component ${styles.bgBlue}`}>
            <div className={"p-card-body"}>
              <div className={"p-card-content"}>
                Pracovníci
              </div>
            </div>
          </NavLink>
        </div>
      )}

      {hasPerm("ROLE_PERM_WAREHOUSE_READ") && (
        <div className={"col-12 md:col-3 text-center"}>
          <NavLink to={"/warehouses"}
            className={`block p-card p-component ${styles.bgBlue}`}>
            <div className={"p-card-body"}>
              <div className={"p-card-content"}>
                Sklad
              </div>
            </div>
          </NavLink>
        </div>
      )}

      {hasPerm("ROLE_PERM_ENUMS_WRITE") && (
        <div className={"col-12 md:col-3 text-center"}>
          <NavLink to={"/enum/warehouse/suppliers"}
            className={`block p-card p-component ${styles.bgBlue}`}>
            <div className={"p-card-body"}>
              <div className={"p-card-content"}>
                Dodavatelé
              </div>
            </div>
          </NavLink>
        </div>
      )}

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={`/tasks/reports?tag=${ehsTag ? ehsTag.uuid : ""}`}
          className={`block p-card p-component ${styles.bgYellow}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              EHS
            </div>
          </div>
        </NavLink>
      </div>

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={`/tasks/reports?tag=${nekvalitaTag ? nekvalitaTag.uuid : ""}`}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              Nekvalita
            </div>
          </div>
        </NavLink>
      </div>

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={"/gwps"}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              GWP
            </div>
          </div>
        </NavLink>
      </div>

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={`/gfps`}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              GFP
            </div>
          </div>
        </NavLink>
      </div>

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={`/vzvs`}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              VZV
            </div>
          </div>
        </NavLink>
      </div>
    </>
  )
}
