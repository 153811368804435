import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async (operation) => {
  const { data } = await api.get(`/users?operation=${operation}`)
  return data.data
}

export const useOperationsUsers = (operation) => {
  const { data, ...props } = useQuery(
    queryKeys.OPERATION_USERS(operation),
    () => getData(operation),
  )
  return { data, ...props }
}
