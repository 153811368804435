import styles from "./styles.module.css"

export default function FileThumb({ file, isLarge, deleteHandler }) {
  const fileEl = file.mimeType.indexOf("image") > -1
    ? (
      <img
        title={file.name}
        src={file.url || file.content}
        alt={"Příloha"}
        className={styles.fileThumbImg}
      />
    ) : (
      <i className={"fas fa-file fa-xl"} title={file.name} />
    )

  if (file.url) {
    return (
      <span className={isLarge ? styles.fileThumbLarge : styles.fileThumb}>
        {deleteHandler && (
          <span className={styles.deleteBtn} onClick={() => deleteHandler(file.uuid)}>&times;</span>
        )}

        <a href={file.url} target={"_blank"} rel={"noreferrer"}>{fileEl}</a>
      </span>
    )
  }

  return (
    <span className={isLarge ? styles.fileThumbLarge : styles.fileThumb}>
      {deleteHandler && (
        <span className={styles.deleteBtn} onClick={() => deleteHandler(file.uuid)}>&times;</span>
      )}

      {fileEl}
    </span>
  )
}
