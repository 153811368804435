import moment from "moment"
import {Divider} from "primereact/divider"
import styles from "./styles.module.css"

export default function TaskHistory({history, taskType}) {
  return (
    <>
      {taskType === "report"
        ? <h3 className={"mt-0"}>Historie</h3>
        : <Divider className={"mt-6"}>Historie</Divider>
      }

      <ul className={"pl-4"}>
        {[...history]
          .reverse()
          .map((item, index) => (
            <li className={"my-3"} key={`${index}-${item.date}`}>
              <span className={`${styles.historyItem} mr-2`}>{item.description}</span>
              <small className={styles.historyItemSmall}>
                {item.user ? `${item.user.name},` : ""} {moment(item.date).format("DD. MM. YYYY HH:mm:ss")}
              </small>
              {item.note && (
                <div className={`${styles.historyItem} pt-3`}>
                  <i className={"pi pi-comment mr-2"}/>
                  <i>{item.note}</i>
                </div>
              )}
            </li>
          ))}
      </ul>
    </>
  )
}
