import { QueryClient } from "react-query"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      // refetchOnMount: false,
    },
  },
})

export const queryKeys = {
  TASK_TAGS: ["task_tags"],
  TASK_STATES: ["task_states"],
  TASK_TYPES: ["task_types"],
  TASK_REPEATED: ["task_repeated"],
  TASK_SWITCH: (type, uuid, query) => ["task_switch", type, uuid, query],
  ROLES: ["roles"],
  PERMISSIONS: ["permissions"],
  TEAMS: ["teams"],
  MACHINE_TYPES: ["machine_types"],
  MACHINES: (operation) => ["machines", operation],
  MACHINES_ALL: () => ["machines_all"],
  MACHINES_IN_TASKLIST: () => ["machines_in_tasklist"],
  SUB_TASK_CAUSES: ["sub_task_causes"],
  OPERATIONS: ["operations"],
  PLACES: ["places"],
  OPERATION_USERS: (operation) => ["operation_users", operation],
  USERS: ["users"],
  USERS_FULLTEXT: (search) => ["users_fulltext", search],
  WAREHOUSE_CATEGORIES: ["warehouse_categories"],
  WAREHOUSE_POSITIONS: ["warehouse_positions"],
  WAREHOUSE_STATES: ["warehouse_states"],
  WAREHOUSE_PARTS: ["warehouse_parts"],
  WAREHOUSE_WAREHOUSES: ["warehouse_warehouses"],
  SUPPLIERS: ["suppliers"],
  GFP_SHIFTS: ["gfp", "shifts"],
  GFP_UNIQUE: ["gfp", "unique"],
  VZV_SHIFTS: ["vzv", "shifts"],
  TASK_DEFECTS: ["task-defects"],
  TASK_UNITS: ["task-units"],
  TASK_AMOUNTS: ["task-amounts"],
  TASK_PROCESSES: ["task-processes"],
}
