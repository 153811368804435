import {useState} from "react"
import {useImmer} from "use-immer"
import {AutoComplete} from "primereact/autocomplete"
import {Divider} from "primereact/divider"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import api from "../../api"
import commonStyles from "../common.module.css"

export default function WarehouseItemForm({taskUuid, onChange, taskType}) {
  const submit = (e) => {
    e.preventDefault()
    const data = selectedItems.map((item) => ({uuid: item.uuid, count: item.quantity}))
    api.post(`/tasks/${taskUuid}/warehouse`, data)
      .then(() => {
        onChange()
        setSelectedItems([])
      })
  }

  const [selectedItems, setSelectedItems] = useImmer([])
  const [fulltext, setFulltext] = useState("")
  const [suggestions, setSuggestions] = useState([])

  const searchItems = ({query}) => {
    api.get(`/warehouses?state=READY&fulltext=${query}`)
      .then((res) => setSuggestions(res.data.data))
  }

  const onFulltextChange = ({value}) => {
    if (typeof value === "string") {
      setFulltext(value)
    }
  }

  const addItem = ({value}) => {
    const exists = selectedItems.some((selectedItem) => selectedItem.uuid === value.uuid)

    if (!exists) {
      setSelectedItems((draft) => {
        draft.push({
          uuid: value.uuid,
          label: value.label,
          max: value.count,
          quantity: 1,
        })
      })
      setFulltext("")
    }
  }

  const removeItem = (uuid) => setSelectedItems((draft) =>
    draft.filter((selectedItem) => selectedItem.uuid !== uuid)
  )

  const changeItemQuantity = (uuid, quantity) => {
    setSelectedItems((draft) => {
      const selectedItem = draft.find((selectedItem) => selectedItem.uuid === uuid)
      quantity = parseInt(quantity, 10)

      if (selectedItem && !isNaN(quantity)) {
        selectedItem.quantity = quantity
      }
    })
  }

  return (
    <>
      {taskType === "prevention" && (
        <Divider className={"mt-6"}>Díly k vyskladnění</Divider>
      )}

      {selectedItems.length === 0 && (
        <div className={`${commonStyles.emptyMessage} font-italic mb-3`}>Žádné díly...</div>
      )}

      {selectedItems.map((item) => (
        <div className={"field"} key={item.uuid}>
          <div className={"p-inputgroup"}>
            <InputText disabled={true} value={item.label} className={"border-0"}/>
            <InputText value={item.quantity} type={"number"} min={1} max={item.max}
                       onChange={(e) => changeItemQuantity(item.uuid, e.target.value)}
                       placeholder={"Počet"}/>
            <Button type={"button"} icon={"pi pi-times"} className={"p-button-danger p-button-text"}
                    onClick={() => removeItem(item.uuid)}/>
          </div>
        </div>
      ))}

      <div>Přidat díl</div>

      <form onSubmit={submit}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <AutoComplete placeholder={"Vyhledat"} completeMethod={searchItems}
                          suggestions={suggestions} field={"label"} onSelect={addItem}
                          value={fulltext} onChange={onFulltextChange}/>
          </div>
        </div>

        <div className={"flex"}>
          <Button type={"submit"} label={"Vyskladnit"} className={"ml-auto"}
                  disabled={selectedItems.length === 0}/>
        </div>
      </form>
    </>
  )
}
