import { useContext, useEffect, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import moment from "moment"
import commonStyles from "../common.module.css"
import api from "../../api"
import userContext from "../../context/userContext"
import backConfirmation from "../../function/backConfirmation"
import { useWarehouseCategories } from "../../api/queries/useWarehouseCategories"
import { useWarehouseParts } from "../../api/queries/useWarehouseParts"
import { useWarehouseWarehouses } from "../../api/queries/useWarehouseWarehouses"
import { useWarehousePositions } from "../../api/queries/useWarehousePositions"
import { useSuppliers } from "../../api/queries/useSuppliers"
import { useMachines } from "../../api/queries/useMachines"
import confirmation from "../../function/confirmation"
import FileList from "../File/FileList"
import { useOperations } from "../../api/queries/useOperations"
import { usePlaces } from "../../api/queries/usePlaces"
import { useMachineTypes } from "../../api/queries/useMachineTypes"
import { Fieldset } from "primereact/fieldset"

export default function WarehouseForm() {
  const { uuid } = useParams()
  const back = () => window.history.back()
  const { user: userDetail, hasPerm, isAdmin } = useContext(userContext)

  const [label, setLabel] = useState("")
  const handleLabelChange = (e) => setLabel(e.target.value)
  const { data: warehouseCategories = [] } = useWarehouseCategories()
  const [category, setCategory] = useState("")
  const handleCategoryChange = (e) => setCategory(e.target.value)
  const { data: warehouseParts = [] } = useWarehouseParts()
  const [part, setPart] = useState("")
  const handlePartChange = (e) => setPart(e.target.value)
  const { data: warehouseWarehouses = [] } = useWarehouseWarehouses()
  const [warehouse, setWarehouse] = useState("")
  const handleWarehouseChange = (e) => setWarehouse(e.target.value)
  const { data: warehousePositions = [] } = useWarehousePositions()
  const [position, setPosition] = useState("")
  const handlePositionChange = (e) => setPosition(e.target.value)
  const { data: suppliers = [] } = useSuppliers()
  const [supplier, setSupplier] = useState("")
  const handleSupplierChange = (e) => setSupplier(e.target.value)

  // machines
  const { data: machineOptions = [] } = useMachines()
  const { data: operations = [] } = useOperations()
  const { data: places = [] } = usePlaces()
  const { data: machineTypes = [] } = useMachineTypes()

  const [machines, setMachines] = useState([])
  const handleMachinesChange = (e) => setMachines(e.target.value)

  const [MFOperation, setMFOperation] = useState(userDetail.operation ? userDetail.operation.uuid : null)
  const handleMFOperationChange = (e) => {
    setMFOperation(e.target.value)
    setMachines([])
    setMFPlace(null)
    setMFType(null)
  }
  const getMFOperationOptions = () => operations // operation is not filtered
  
  const [MFPlace, setMFPlace] = useState(null)
  const handleMFPlaceChange = (e) => {
    setMFPlace(e.target.value)
    setMachines([])
    setMFType(null)
  }
  const getMFPlaceOptions = () => MFOperation
  ? places.filter((place) => place.operation.uuid === MFOperation)
  : places
  
  const [MFType, setMFType] = useState(null)
  const handleMFTypeChange = (e) => {
    setMFType(e.target.value)
    setMachines([])
  }
  const getMFTypeOptions = () => {
    const _places = getMFPlaceOptions().map((place) => place.uuid)
    const _machines = MFPlace
      ? machineOptions.filter((machine) => machine.place.uuid === MFPlace)
      : machineOptions.filter((machine) => _places.indexOf(machine.place.uuid) > -1)
    const _types = _machines.map((machine) => machine.type.uuid)
    return machineTypes.filter((machineFilter) => _types.indexOf(machineFilter.uuid) > -1)
  }

  const getMachineOptions = () => {
    let _machines = [...machineOptions]

    if (MFOperation) {
      _machines = _machines.filter((machine) => machine.place.operation.uuid === MFOperation)
    }

    if (MFPlace) {
      _machines = _machines.filter((machine) => machine.place.uuid === MFPlace)
    }

    if (MFType) {
      _machines = _machines.filter((machine) => machine.type.uuid === MFType)
    }

    return _machines
  }

  const [note, setNote] = useState("")
  const handleNoteChange = (e) => setNote(e.target.value)
  const [count, setCount] = useState(1)
  const handleCountChange = (e) => setCount(parseInt(e.target.value, 10))

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [tasks, setTasks] = useState([])

  const create = () => api
    .put("/warehouses", {
      label, category, part, warehouse, position, supplier, machines, note, count, files,
      date: moment().format("YYYY-MM-DD"),
    })
    .then(back)

  const update = () => api
    .post(`/warehouses/${uuid}`, {
      uuid, label, category, part, warehouse, position, supplier, machines, note, count,
      date: moment().format("YYYY-MM-DD"), state: count > 0 ? "READY" : "NOT_IN_WAREHOUSE",
    })
    .then(back)

  const remove = () => api
    .delete(`/warehouses/${uuid}`)
    .then(back)
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)

  useEffect(() => {
    if (uuid) {
      api.get(`/warehouses/${uuid}`)
        .then(({ data }) => {
          setLabel(data.data.label)
          setCount(data.data.count)
          setCategory(data.data.category.uuid)
          setPart(data.data.part.uuid)
          setWarehouse(data.data.warehouse.uuid)
          setPosition(data.data.position.uuid)
          setSupplier(data.data.supplier ? data.data.supplier.uuid : "")
          setMachines(data.data.machines.map((machine) => machine.uuid))
          setNote(data.data.note)
          setFiles(data.data.files || [])
          setTasks(data.data.tasks)
        })
    }
  }, [uuid, refresher])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    uuid ? update() : create()
  }

  return (
    <>
      <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Označení:</label>
            <InputText required={true} value={label} placeholder={"Označení"}
              onChange={handleLabelChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Počet:</label>
            <InputText required={true} type={"number"} value={count} placeholder={"Počet"}
              min={uuid ? 0 : 1} onChange={handleCountChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Kategorie:</label>
            <Dropdown options={warehouseCategories} value={category} optionLabel={"name"}
              optionValue={"uuid"} placeholder={"Kategorie"} showClear={true}
              filter={true} required={true} onChange={handleCategoryChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Díl:</label>
            <Dropdown options={warehouseParts} value={part} optionLabel={"name"}
              optionValue={"uuid"} placeholder={"Díl"} showClear={true}
              filter={true} required={true} onChange={handlePartChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Sklad:</label>
            <Dropdown options={warehouseWarehouses} value={warehouse} optionLabel={"name"}
              optionValue={"uuid"} placeholder={"Sklad"} showClear={true}
              filter={true} required={true} onChange={handleWarehouseChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Pozice:</label>
            <Dropdown options={warehousePositions} value={position} optionLabel={"name"}
              optionValue={"uuid"} placeholder={"Pozice"} showClear={true}
              filter={true} required={true} onChange={handlePositionChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Dodavatel:</label>
            <Dropdown options={suppliers} value={supplier} optionLabel={"name"}
              optionValue={"uuid"} placeholder={"Dodavatel"} showClear={true}
              filter={true} required={true} onChange={handleSupplierChange} />
          </div>

          <Fieldset className={"p-0 mb-3"}>
            <div className={"formgrid grid"}>
              <div className={"field col pl-0"}>
                <label>Provoz:</label>
                <Dropdown options={getMFOperationOptions()} value={MFOperation}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFOperationChange} />
              </div>

              <div className={"field col"}>
                <label>Místo:</label>
                <Dropdown options={getMFPlaceOptions()} value={MFPlace}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFPlaceChange} />
              </div>

              <div className={"field col pr-0"}>
                <label>Typ stroje:</label>
                <Dropdown options={getMFTypeOptions()} value={MFType}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFTypeChange} />
              </div>
            </div>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Stroje:</label>
              <MultiSelect options={getMachineOptions()} value={machines} optionLabel={"name"}
                optionValue={"uuid"} placeholder={"Stroje"} showClear={true}
                filter={true} required={true} emptyMessage={"Žádné záznamy"}
                selectedItemsLabel={"{0} záznamů vybráno"}
                onChange={handleMachinesChange} />
            </div>
          </Fieldset>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Poznámka:</label>
            <InputTextarea required={true} value={note} onChange={handleNoteChange} />
          </div>
        </div>

        <div className={"flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={backConfirmation} />
          <div className={"ml-auto"}>
            <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
              onClick={removeConfirmation}
              disabled={!uuid || !isAdmin()} />
            <Button type={"submit"} label={"Uložit"} className={"m-1"}
              disabled={!hasPerm("ROLE_PERM_WAREHOUSE_WRITE")} />
          </div>
        </div>
      </form>

      <div className={"col-12 md:col-6"}>
        <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
          entityUrl={uuid ? `/warehouses/${uuid}` : ""}
          isDisabled={!hasPerm("ROLE_PERM_WAREHOUSE_WRITE")} />
        {uuid && (
          <>
            <Divider>Použito v</Divider>

            {tasks.length === 0 && <div className={"font-italic"}>Zatím nepoužito.</div>}

            <ul>
              {tasks.map((task) => (
                <li key={task.uuid}>
                  <NavLink to={`/task/${task.task.type.toLowerCase()}/${task.task.uuid}`}
                    className={"text-primary underline"}>
                    {task.task.id} - {task.task.name}
                  </NavLink>
                  <small className={"text-xs"}> (počet: {task.count})</small>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  )
}
