import { useRef, useState } from "react"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Divider } from "primereact/divider"
import { v4 as uuid } from "uuid"
import api from "../../api"
import commonStyles from "../common.module.css"
import styles from "./styles.module.css"
import confirmation from "../../function/confirmation"

export default function FileList({ files, onChange, entityUrl, isDisabled }) {
  const fileInputRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [newFiles, setNewFiles] = useState([])

  const onNameChange = (name, index) => {
    setNewFiles(newFiles.map((newFile, newFileIndex) => {
      if (newFileIndex === index) {
        newFile.name = name
      }

      return newFile
    }))
  }

  const onNoteChange = (note, index) => {
    setNewFiles(newFiles.map((newFile, newFileIndex) => {
      if (newFileIndex === index) {
        newFile.note = note
      }

      return newFile
    }))
  }

  const [editedFile, setEditedFile] = useState(null)

  const resetForm = () => {
    setNewFiles([])

    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  const onFileEdit = (file) => {
    setEditedFile(file)
    setNewFiles([])
    resetForm()
  }

  const onFileEditChange = (data) => {
    setEditedFile(Object.assign({}, editedFile, data))
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => resolve({
        uuid: uuid(),
        name: file.name,
        content: reader.result,
        mimeType: file.type,
        note: "",
      });

      reader.readAsDataURL(file);
    })
  }

  const onFileSelect = async (event) => {
    if (event.target.files.length > 0) {
      const eventFiles = [...event.target.files]
      const readFiles = await Promise.all(eventFiles.map((file) => readFile(file)))

      setNewFiles([...newFiles, ...readFiles])
    }
  }

  const addFile = () => {
    onChange([...files, ...newFiles])
    resetForm()
  }

  const addFileAsync = async () => {
    setIsLoading(true)

    for await (const file of newFiles) {
      await api.put(`${entityUrl}/file`, {
        name: file.name,
        note: file.note,
        content: file.content
      })
    }

    onChange()
    resetForm()
    setIsLoading(false)
  }

  const removeFile = (uuid) => {
    onChange(files.filter((file) => file.uuid !== uuid))
  }

  const removeFileAsync = async (uuid) => {
    await api.delete(`${entityUrl}/file/${uuid}`)

    onChange()
  }

  const editFileAsync = async () => {
    setIsLoading(true)

    await api.post(`${entityUrl}/file/${editedFile.uuid}`, {
      uuid: editedFile.uuid,
      name: editedFile.name,
      note: editedFile.note,
    })

    onChange()
    setEditedFile(null)
    resetForm()
    setIsLoading(false)
  }

  const removeFileConfirmation = (target, uuid) => {
    confirmation(
      target,
      "Opravdu smazat?",
      () => entityUrl ? removeFileAsync(uuid) : removeFile(uuid)
    )
  }

  const submit = (event) => {
    event.preventDefault()

    editedFile !== null
      ? editFileAsync()
      : entityUrl
        ? addFileAsync()
        : addFile()
  }

  return (
    <>
      <Divider className={"mt-0"}>Přílohy</Divider>

      {files.length === 0 && (
        <div className={`${commonStyles.emptyMessage} font-italic`}>Žádné přílohy</div>
      )}

      {files.map((file) => (
        <div key={file.uuid} className={`${styles.file} py-1 pl-2`}>
          <div className={"flex"}>
            {file.url ? (
              <a
                href={file.url}
                className={"text-primary underline"}
                target={"_blank"}
                rel={"noreferrer"}
              >{file.name}</a>
            ) : (
              <span className={"text-primary"}>{file.name}</span>
            )}

            <div className={"ml-auto"}>
              {entityUrl && (
                <Button
                  className={"p-button-text p-0"}
                  icon={"pi pi-pencil"}
                  onClick={() => onFileEdit(file)}
                />
              )}

              <Button
                className={"p-button-text p-button-danger p-0"}
                icon={"pi pi-times"}
                onClick={(e) => removeFileConfirmation(e.target, file.uuid)}
              />
            </div>
          </div>

          <div className={"text-"}>{file.note}</div>

          {file.mimeType && file.size && (
            <div className={"text-xs text-400"}>
              ({file.mimeType}, {(file.size / 1000).toFixed(2)} kB)
            </div>
          )}
        </div>
      ))}


      <form onSubmit={submit}>
        <div className={"p-fluid"}>
          {newFiles.length > 0 && (
            <Divider className={"font-normal"}>Nové přílohy</Divider>
          )}

          {newFiles.map((newFile, index) => (
            <div key={newFile.uuid}>
              <div className={"field"}>
                <label>Soubor {index + 1}:</label>
                <InputText
                  placeholder={"Název"}
                  value={newFile.name || ""}
                  onChange={(event) => onNameChange(event.target.value, index)}
                />
              </div>

              <div className={"field"}>
                <label>Popis souboru {index + 1}:</label>
                <InputText
                  placeholder={"Popis"}
                  value={newFile.note || ""}
                  onChange={(event) => onNoteChange(event.target.value, index)} />
              </div>
            </div>
          ))}

          {editedFile !== null && (
            <div>
              <Divider className={"font-normal"}>Aktualizovat přílohu</Divider>

              <div className={"field"}>
                <label>Soubor:</label>
                <InputText
                  placeholder={"Název"}
                  value={editedFile.name || ""}
                  onChange={(event) => onFileEditChange({ name: event.target.value })}
                />
              </div>

              <div className={"field"}>
                <label>Popis:</label>
                <InputText
                  placeholder={"Popis"}
                  value={editedFile.note || ""}
                  onChange={(event) => onFileEditChange({ note: event.target.value })}
                />
              </div>
            </div>
          )}
        </div>

        <div className={"flex mt-3"}>
          {editedFile === null && (
            <label className="p-button p-button-secondary p-button-outlined">
              Vybrat soubory
              <input
                ref={fileInputRef}
                type={"file"}
                onChange={onFileSelect}
                className={"hidden"}
                disabled={isDisabled}
                multiple
              />
            </label>
          )}

          {editedFile !== null ? (
            <Button
              type={"submit"}
              label={"Aktualizovat"}
              className={"ml-auto"}
              disabled={!editedFile.name}
            />
          ) : (
            <Button
              type={"submit"}
              label={"Přidat"}
              className={"ml-auto"}
              disabled={newFiles.length === 0 || isLoading || newFiles.some((file) => file.name === "")}
            />
          )}
        </div>
      </form>
    </>
  )
}
