import { NavLink, useHistory, useLocation } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { SelectButton } from "primereact/selectbutton"
import { MultiSelect } from "primereact/multiselect"
import { InputText } from "primereact/inputtext"
import { Tooltip } from "primereact/tooltip"
import moment from "moment"
import store from "store"
import api from "../../api"
import query from "../../function/query"
import userContext from "../../context/userContext"
import TaskTagFilter from "../../filter/TaskTagFilter"
import TeamFilter from "../../filter/TeamFilter"
import { useTaskStates } from "../../api/queries/useTaskStates"
import TaskStateFilter from "../../filter/TaskStateFilter"
import getTaskStateSeverity from "../../function/getTaskStateSeverity"
import Tmpl from "../../function/Tmpl"
import DateFilter from "../../filter/DateFilter"
import commonStyles from "../common.module.css"
import UserFilter from "../../filter/UserFilter"
import OperationFilter from "../../filter/OperationFilter"
import MachineTypeFilter from "../../filter/MachineTypeFilter"
import { useWindowWidth } from "../../hook/useWindowWidth"
import styles from "./styles.module.css"
import DateRange from "./DateRange/DateRange"
import Initials from "./Initials/Initials"
import TopButton from "./TopButton/TopButton"
import reportColumnsDefaultState from "./reportColumns"
import TaskListControls from "./TaskListControls"
import { storage } from "../../storage"
import { Dropdown } from "primereact/dropdown"
import { useDebounce } from "../../hook/useDebounce"

export default function TaskReportList() {
  const TWO_MINUTES = 120000
  const history = useHistory()
  const searchStr = useLocation().search
  const search = query.parse(searchStr)
  const [sortField, setSortField] = useState(search.sortField || "created")
  const [sortOrder, setSortOrder] = useState(search.sortOrder || -1)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }
  const user = useContext(userContext)

  const windowWidth = useWindowWidth()

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const getSubTasks = (row) => {
    return row.related.filter((relatedTask) => relatedTask.is_child)
  }
  const idTmpl = (row) => {
    const subtasks = getSubTasks(row) || []
    const subtasksCount = subtasks.length

    let label = ""
    let severity = ""

    if (subtasksCount === 1) {
      label = "Hlášení obsahuje 1 podúkol."
    } else if (subtasksCount < 5) {
      label = `Hlášení obsahuje ${subtasksCount} podúkoly.`
    } else {
      label = `Hlášení obsahuje ${subtasksCount} podúkolů.`
    }

    if (subtasks.every((st) => st.state === "OPEN")) {
      severity = "danger"
    } else if (subtasks.every((st) =>
      st.state === "FINISHED" ||
      st.state === "PARTIALY_FINISHED" ||
      st.state === "CLOSED"
    )) {
      severity = "success"
    } else {
      severity = "warning"
    }

    return (
      <>
        <Tmpl title={"Číslo"} value={row.id} />

        {subtasksCount > 0 && (
          <>
            <Badge value={subtasksCount} severity={severity} className={`ml-1 subtask-count-${row.id}`} />
            <Tooltip content={label} target={`.subtask-count-${row.id}`} />
          </>
        )}
      </>
    )
  }


  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      {user.hasPerm("ROLE_PERM_REPORT_READ")
        ? <NavLink to={`/task/report/${row.uuid}/detail`}>{row.name}</NavLink>
        : row.name
      }
    </Tmpl>
  )

  const [fulltext, setFulltext] = useState(search.fulltext || "")
  const handleFulltextChange = (e) => setFulltext(e.target.value)
  const fulltextDebounced = useDebounce(fulltext, 500)
  const nameFilter = <InputText value={fulltext} onChange={handleFulltextChange} placeholder="Fulltext" />

  const { data: taskStates = [] } = useTaskStates()
  const taskStateTmpl = (row) => {
    const taskState = taskStates.find(taskState => taskState.value === row.state)
    return (
      <Tmpl title={"Stav"}>
        <Badge value={taskState ? taskState.name : ""}
          severity={getTaskStateSeverity(taskState ? taskState.value : "")}
          className={"block white-space-nowrap overflow-hidden text-overflow-ellipsis"} />
      </Tmpl>
    )
  }
  const [taskState, setTaskState] = useState(search.state || null)
  const handleTaskStateChange = (e) => setTaskState(e.target.value)
  const taskStateFilter = <TaskStateFilter value={taskState} onChange={handleTaskStateChange} />

  const createdByTmpl = (row) => (
    <Tmpl title={"Založil"}>
      {row.created_by && (
        <span className={"word-break-word"}>{row.created_by.name}</span>
      )}
    </Tmpl>
  )
  const [createdBy, setCreatedBy] = useState(search.created_by || null)
  const handleCreatedByChange = (e) => setCreatedBy(e.target.value)
  const createdByFilter = <UserFilter value={createdBy} onChange={handleCreatedByChange}
    placeholder={"Založil"} />

  const [machinesInTaskList, setMachinesInTaskList] = useState([])
  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = (
    <Dropdown options={machinesInTaskList} value={machine}
      optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={"Stroj"} onChange={handleMachineChange}
      filter={true} />
  )
  const machineTmpl = (row) => (
    <Tmpl title={"Stroj"}>
      <strong>{row.machine ? row.machine.name : ""}</strong>
    </Tmpl>
  )

  const [machineType, setMachineType] = useState(search.machine_type || null)
  const handleMachineTypeChange = (e) => setMachineType(e.target.value)
  const machineTypeFilter = <MachineTypeFilter value={machineType}
    onChange={handleMachineTypeChange} />
  const machineTypeTmpl = (row) => <Tmpl title={"Typ stroje"}
    value={row.machine && row.machine.type ? row.machine.type.name : ""} />

  const [taskTag, setTaskTag] = useState(search.tag || null)
  const handleTaskTagChange = (e) => setTaskTag(e.target.value)
  const taskTagFilter = <TaskTagFilter value={taskTag} onChange={handleTaskTagChange} />
  const taskTagTmpl = (row) => (
    <Tmpl title={"Typ"} value={row.tag ? row.tag.name : ""} />
  )
  const taskTagAbbrTmpl = (row) => (
    <span className={styles.taskTagAbbr}>
      {row.tag ? row.tag.abbr : ""}
    </span>
  )

  const [team, setTeam] = useState(search.team || null)
  const handleTeamChange = (e) => setTeam(e.target.value)
  const teamFilter = <TeamFilter value={team} onChange={handleTeamChange} />
  const teamTmpl = (row) => <Tmpl title={"Tým"} value={row.team ? row.team.name : ""} />

  const [userOption, setUserOption] = useState(search.user || null)
  const handleUserOptionChange = (e) => setUserOption(e.target.value)
  const userFilter = <UserFilter value={userOption} onChange={handleUserOptionChange}
    placeholder={"Odpovědná osoba"} />
  const userTmpl = (row) => <Tmpl title={"Odpovědná osoba"} value={row.user ? row.user.name : ""} />

  const [operation, setOperation] = useState(search.operation || null)
  const handleOperationChange = (e) => setOperation(e.target.value)
  const operationFilter = <OperationFilter value={operation} onChange={handleOperationChange} />
  const operationTmpl = (row) => <Tmpl title={"Řeší provoz"}
    value={row.operation ? row.operation.name : ""} />

  const [startsAt, setStartsAt] = useState(search.starts_at
    ? moment(search.starts_at).toDate() : null)
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)
  const startsAtFilter = <DateFilter value={startsAt} onChange={handleStartsAtChange}
    placeholder={"Požadovaný termín od"} />
  const startsAtTmpl = (row) => (
    <Tmpl
      title={"Požadovaný termín od"}
      value={row.starts_at ? moment(row.starts_at).format("DD. MM. YYYY") : ""}
    />
  )

  const [endsAt, setEndsAt] = useState(search.ends_at
    ? moment(search.ends_at).toDate() : null)
  const handleEndsAtChange = (e) => setEndsAt(e.target.value)
  const endsAtFilter = <DateFilter value={endsAt} onChange={handleEndsAtChange}
    placeholder={"Požadovaný termín do"} />
  const endsAtTmpl = (row) => (
    <Tmpl
      title={"Požadovaný termín do"}
      value={row.ends_at ? moment(row.ends_at).format("DD. MM. YYYY") : ""}
    />
  )

  const [createdAtFrom, setCreatedAtFrom] = useState(search.created_at_from
    ? moment(search.created_at_from).toDate()
    : null
  )

  const [createdAtTo, setCreatedAtTo] = useState(search.created_at_to
    ? moment(search.created_at_to).toDate()
    : null
  )

  const handleCreatedAtFromChange = (e) => setCreatedAtFrom(e.target.value)
  const handleCreatedAtToChange = (e) => setCreatedAtTo(e.target.value)

  const createdAtFilter = (
    <div className="flex">
      <DateFilter value={createdAtFrom} onChange={handleCreatedAtFromChange} placeholder={"Od"} />
      <DateFilter value={createdAtTo} onChange={handleCreatedAtToChange} placeholder={"Do"} />
    </div>
  )

  const createdAtTmpl = (row) => (
    <Tmpl
      title={"Datum založení"}
      value={row.created_at ? moment(row.created_at).format("DD. MM. YYYY") : ""}
    />
  )

  const [finishedAtFrom, setFinishedAtFrom] = useState(search.finished_at_from
    ? moment(search.finished_at_from).toDate()
    : null
  )

  const [finishedAtTo, setFinishedAtTo] = useState(search.finished_at_to
    ? moment(search.finished_at_to).toDate()
    : null
  )

  const handleFinishedAtFromChange = (e) => setFinishedAtFrom(e.target.value)
  const handleFinishedAtToChange = (e) => setFinishedAtTo(e.target.value)

  const finishedAtFilter = (
    <div className="flex">
      <DateFilter value={finishedAtFrom} onChange={handleFinishedAtFromChange} placeholder={"Od"} />
      <DateFilter value={finishedAtTo} onChange={handleFinishedAtToChange} placeholder={"Do"} />
    </div>
  )

  const finishedAtTmpl = (row) => (
    <Tmpl title="Datum dokončení"
      value={row.finished_at ? moment(row.finished_at).format("DD. MM. YYYY") : ""} />
  )

  const isUrgent = (row) => (row.state === "OPEN" || row.state === "IN_PROGRESS") &&
    row.tag && row.tag.key === "akutni"

  const isWarn = (row) => (row.state === "OPEN" || row.state === "IN_PROGRESS") &&
    row.tag && row.tag.key === "varovani"

  const isTPM = (row) => row.tag && row.tag.key === "tpm"

  const isZavada = (row) => row.tag && row.tag.key === "zavada"

  const rowClass = (row) => ({
    [commonStyles.tableRowDanger]: isUrgent(row),
    [commonStyles.tableRowWarning]: isWarn(row),
    [commonStyles.tableRowTPM]: isTPM(row),
    [commonStyles.tableRowZavada]: isZavada(row),
  })

  const [selectedRow, setSelectedRow] = useState("")
  const onRowClick = (e) => setSelectedRow(
    selectedRow && selectedRow.uuid === e.data.uuid
      ? ""
      : e.data
  )

  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const toggleFilters = () => setIsFiltersVisible(!isFiltersVisible)

  const viewTypes = [
    { label: "Aktivní hlášení", value: "active" },
    { label: 'Historie hlášení', value: "history" },
  ]
  const [viewType, setViewType] = useState(search.view || "active")
  const changeViewType = (e) => e.value && setViewType(e.value)

  const columnsInitState = store.get("dora.taskReportList.columns") || []
  const missingColumns = reportColumnsDefaultState.filter(
    (defCol) => columnsInitState.find(
      (initCol) => initCol.key === defCol.key) === undefined)
  const [columnsState, setColumnsState] = useState([
    ...(columnsInitState || reportColumnsDefaultState),
    ...missingColumns
  ])
  const columnsVisible = columnsState
    .filter((column) => column.visible)
    .map((column) => column.key)

  useEffect(() => {
    store.set("dora.taskReportList.columns", columnsState)
  }, [columnsState])

  const onColReorder = (e) => {
    const orderedColumns = e.columns
    const newColumnsState = [...columnsState]

    orderedColumns.forEach((orderedColumn, index) => {
      const column = newColumnsState.find((c) => c.key === orderedColumn.props.columnKey)

      if (column) {
        column.index = index
      }
    })

    setColumnsState(newColumnsState)
  }

  const onColToggle = (e) => {
    const visibleColumnKeys = e.value

    const newColumnsState = [...columnsState]

    newColumnsState.forEach((column) => {
      column.visible = visibleColumnKeys.indexOf(column.key) > -1
    })

    setColumnsState(newColumnsState)
  }

  const header = (
    <div className={"flex align-items-center"}>
      <label className={"hidden sm:inline"}>Zobrazit:</label>
      <SelectButton value={viewType} options={viewTypes} onChange={changeViewType}
        className={"inline-flex mx-1"} />

      <Button className={"button-toggle-filters mx-1 p-button-raised p-button-text"}
        label={"Filtrovat"} onClick={toggleFilters} />

      <MultiSelect value={columnsVisible} options={columnsState}
        selectedItemsLabel={"{0} sloupců zobrazeno"} optionLabel={"label"}
        optionValue={"key"} className={"hidden md:inline-flex ml-auto"}
        onChange={onColToggle} optionDisabled={"disabled"} />
    </div>
  )

  const handleExport = () => {
    const params = Object.assign({}, search)
    params.type = "REPORT"
    delete params.limit
    delete params.offset

    api.get(`/tasks/export${query.toString(params)}`)
      .then(({ data }) => {
        window.open(data.data.url)
      })
  }

  useEffect(() => {
    const getData = () => {
      setIsLoading(true)

      let prms = {
        limit, offset, state: taskState, type: "REPORT", machine, tag: taskTag, team,
        user: userOption, operation, sortField, sortOrder, machine_type: machineType,
        created_by: createdBy,
        view: viewType,
        fulltext: fulltextDebounced,
      }

      if (startsAt) {
        prms.starts_at = moment(startsAt).format("YYYY-MM-DD")
      }

      if (endsAt) {
        prms.ends_at = moment(endsAt).format("YYYY-MM-DD")
      }

      if (createdAtFrom) {
        prms.created_at_from = moment(createdAtFrom).format("YYYY-MM-DD")
      }

      if (createdAtTo) {
        prms.created_at_to = moment(createdAtTo).format("YYYY-MM-DD")
      }

      if (finishedAtFrom) {
        prms.finished_at_from = moment(finishedAtFrom).format("YYYY-MM-DD")
      }

      if (finishedAtTo) {
        prms.finished_at_to = moment(finishedAtTo).format("YYYY-MM-DD")
      }

      if (search.my_tasks) {
        prms.my_tasks = search.my_tasks
      }

      const queryParamsStr = query.toString(prms)
      history.push({ search: queryParamsStr })

      //
      let storageParams = Object.assign({}, prms)
      // delete storageParams.limit
      // delete storageParams.offset
      storage.set("reportListQueryParams", query.toString(storageParams))
      //

      api.get(`/tasks${queryParamsStr}`)
        .then(({ data, headers }) => {
          setTotal(parseInt(headers["x-total-count"], 10))
          setData(data.data)
        })
        .finally(() => setIsLoading(false))

      api.get(`/machines-in-tasklist${query.toString(storageParams)}`)
        .then(({ data }) => setMachinesInTaskList(data.data))
    }

    const updateInterval = setInterval(getData, TWO_MINUTES)

    getData()

    return () => {
      clearInterval(updateInterval)
    }
  }, [limit, offset, taskState, machine, taskTag, team, history, sortField, sortOrder,
    startsAt, endsAt, userOption, operation, machineType, viewType, createdBy, search.my_tasks,
    createdAtFrom, createdAtTo, fulltextDebounced, finishedAtFrom, finishedAtTo])

  const _desktopColumns = {
    id: <Column
      key={"id"} columnKey={"id"}
      header={"#"} body={idTmpl}
      sortable={true} sortField={"id"} />,

    machine: <Column
      key={"machine"} columnKey={"machine"}
      header={"Stroj"} body={machineTmpl}
      sortable={true} sortField={"machine"}
      filter={true} filterElement={machineFilter} />,

    name: <Column
      key={"name"}
      columnKey={"name"}
      header={"Název"} body={nameTmpl}
      sortable={true} sortField={"name"}
      filter={true} filterElement={nameFilter} />,

    state: <Column
      key={"state"} columnKey={"state"}
      header={"Stav"} body={taskStateTmpl}
      sortable={true} sortField={"state"}
      filter={true} filterElement={taskStateFilter} />,

    createdBy: <Column
      key={"createdby"} columnKey={"createdBy"}
      header={"Založil"} body={createdByTmpl}
      sortable={true} sortField={"createdby"}
      filter={true} filterElement={createdByFilter}
      className={"p-column-hidden"} />,

    machineType: <Column
      key={"machineType"} columnKey={"machineType"}
      header={"Typ stroje"} body={machineTypeTmpl}
      sortable={true} sortField={"machinetype"}
      filter={true} filterElement={machineTypeFilter} />,

    tag: <Column
      key={"tag"} columnKey={"tag"}
      header={"Typ"} body={taskTagTmpl}
      sortable={true} sortField={"tag"}
      filter={true} filterElement={taskTagFilter}
      className={"p-column-hidden"} />,

    team: <Column
      key={"team"} columnKey={"team"}
      header={"Tým"} body={teamTmpl}
      sortable={true} sortField={"team"}
      filter={true} filterElement={teamFilter}
      className={"p-column-hidden"} />,

    user: <Column
      key={"user"} columnKey={"user"}
      header={"Odpovědná osoba"} body={userTmpl}
      sortable={true} sortField={"user"}
      filter={true} filterElement={userFilter}
      className={"p-column-hidden"} />,

    operation: <Column
      key={"operation"} columnKey={"operation"}
      header={"Řeší provoz"} body={operationTmpl}
      sortable={true} sortField={"operation"}
      filter={true} filterElement={operationFilter}
      className={"p-column-hidden"} />,

    startsAt: <Column
      key={"startsAt"} columnKey={"startsAt"}
      header={"Požadovaný termín od"} body={startsAtTmpl}
      sortable={true} sortField={"start"}
      filter={true} filterElement={startsAtFilter}
      className={"p-column-hidden"} />,

    endsAt: <Column
      key={"endsAt"} columnKey={"endsAt"}
      header={"Požadovaný termín do"} body={endsAtTmpl}
      sortable={true} sortField={"endsat"}
      filter={true} filterElement={endsAtFilter}
      className={"p-column-hidden"} />,

    createdAt: <Column
      key={"createdAt"} columnKey={"createdAt"}
      header={"Datum založení"} body={createdAtTmpl}
      sortable={true} sortField={"created"}
      filter={true} filterElement={createdAtFilter}
      className={"p-column-hidden"} />,

    finishedAt: <Column
      key={"finishedAt"} columnKey={"finishedAt"}
      header={"Datum dokončení"} body={finishedAtTmpl}
      sortable={true} sortField={"finished"}
      filter={true} filterElement={finishedAtFilter}
      className={"p-column-hidden"} />,
  }

  let desktopColumns = [...columnsState]
    .filter((column) => column.visible)
    .sort((column1, column2) => column1.index - column2.index)
    .map((column) => _desktopColumns[column.key])

  const mobileMessTemplate = (row) => (
    <>
      <div className={"flex align-items-center"}>
        <div className={styles.w20}>{idTmpl(row)}</div>
        <div className={styles.w20}>{machineTmpl(row)}</div>
        <div className={styles.w60}>{nameTmpl(row)}</div>
      </div>
      <div className={"flex align-items-center"}>
        <div className={styles.w20}>{taskTagAbbrTmpl(row)}</div>
        <div className={styles.w20}>
          {row.user && <Initials name={row.user.name} />}
        </div>
        <div className={styles.w40}>
          <DateRange startsAt={row.starts_at} endsAt={row.ends_at} finishedAt={row.finished_at} />
        </div>
        <div className={styles.w15}>{taskStateTmpl(row)}</div>
      </div>
    </>
  )

  const mobileColumns = [
    <Column
      key={"mess"} columnKey={"mess"}
      header={""} body={mobileMessTemplate}
      className={"p-column-common"} />,

    <Column
      key={"id"} columnKey={"id"}
      header={"#"} body={idTmpl}
      sortable={true} sortField={"id"}
      className={"p-column-hidden"} />,

    <Column
      key={"machine"} columnKey={"machine"}
      header={"Stroj"} body={machineTmpl}
      filter={true} filterElement={machineFilter}
      sortable={true} sortField={"machine"}
      className={"p-column-hidden"} />,

    <Column
      key={"name"} columnKey={"name"}
      header={"Název"} body={nameTmpl}
      sortable={true} sortField={"name"}
      filter={true} filterElement={nameFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"state"} columnKey={"state"}
      header={"Stav"} body={taskStateTmpl}
      filter={true} filterElement={taskStateFilter}
      sortable={true} sortField={"state"}
      className={"p-column-hidden"} />,

    <Column
      key={"createdby"} columnKey={"createdBy"}
      header={"Založil"} body={createdByTmpl}
      sortable={true} sortField={"createdby"}
      filter={true} filterElement={createdByFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"machineType"} columnKey={"machineType"}
      header={"Typ stroje"} body={machineTypeTmpl}
      sortable={true} sortField={"machinetype"}
      filter={true} filterElement={machineTypeFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"tag"} columnKey={"tag"}
      header={"Typ"} body={taskTagTmpl}
      filter={true} filterElement={taskTagFilter}
      sortable={true} sortField={"tag"}
      className={"p-column-hidden"} />,

    <Column
      key={"team"} columnKey={"team"}
      header={"Tým"} body={teamTmpl}
      filter={true} filterElement={teamFilter}
      sortable={true} sortField={"team"}
      className={"p-column-hidden"} />,

    <Column
      key={"user"} columnKey={"user"}
      header={"Odpovědná osoba"} body={userTmpl}
      sortable={true} sortField={"user"}
      filter={true} filterElement={userFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"operation"} columnKey={"operation"}
      header={"Řeší provoz"} body={operationTmpl}
      sortable={true} sortField={"operation"}
      filter={true} filterElement={operationFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"startsAt"} columnKey={"startsAt"}
      header={"Požadovaný termín od"} body={startsAtTmpl}
      sortable={true} sortField={"start"}
      filter={true} filterElement={startsAtFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"endsAt"} columnKey={"endsAt"}
      header={"Požadovaný termín do"} body={endsAtTmpl}
      sortable={true} sortField={"endsat"}
      filter={true} filterElement={endsAtFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"createdAt"} columnKey={"createdAt"}
      header={"Datum založení"} body={createdAtTmpl}
      sortable={true} sortField={"created"}
      filter={true} filterElement={createdAtFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"finishedAt"} columnKey={"finishedAt"}
      header={"Datum dokončení"} body={finishedAtTmpl}
      sortable={true} sortField={"finished"}
      filter={true} filterElement={finishedAtFilter}
      className={"p-column-hidden"} />,
  ]

  return (
    <>
      <TaskListControls exportHandler={handleExport} />

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField}
          sortOrder={sortOrder} rowClassName={rowClass}
          className={`p-datatable-sm p-datatable-collapsible p-datatable-reorderable ${isFiltersVisible ? "p-datatable-filters" : ""}`}
          selectionMode={"single"} selection={selectedRow} onRowClick={onRowClick}
          header={header}
          reorderableColumns={windowWidth >= 768} onColReorder={onColReorder}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          {windowWidth >= 768 ? desktopColumns : mobileColumns}
        </DataTable>
      </div>

      <TopButton />
    </>
  )
}
