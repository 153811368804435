import { useContext, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import api from "../../../api"
import userContext from "../../../context/userContext"
import backConfirmation from "../../../function/backConfirmation"
import commonStyles from "../../common.module.css"
import useParentUsers from "../../../hook/useParentUsers"

export default function TaskManagementFinishForm() {
  const { uuid, isPossible } = useParams()
  const history = useHistory()
  const back = () => window.history.back()
  const { user: userDetail } = useContext(userContext)

  const [time, setTime] = useState(0)
  const handleTimeChange = (e) => setTime(parseFloat(e.target.value))
  const [note, setNote] = useState("")
  const handleNoteChange = (e) => setNote(e.target.value)

  const users = useParentUsers(userDetail)
  const [responsibleUser, setResponsibleUser] = useState("")
  const handleResponsibleUserChange = (e) => setResponsibleUser(e.target.value)
  const userTemplate = (user) => <div>{user.name} ({user.operation.name}, {user.team.name})</div>

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const payload = {
      uuid,
      state: isPossible === "1" ? "FINISHED" : "PARTIALY_FINISHED",
      note,
    }

    if (time) {
      payload.time = time
    }

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    api.post(`/task-managements/${uuid}`, payload)
      .then(() => history.push("/tasks/management"))
  }

  const isFormFilled = () => {
    if (isPossible === "1") {
      return time && note
    }
    return note && responsibleUser
  }

  return (
    <userContext.Consumer>
      {({ hasPerm }) => (
        <>
          <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
            <div className={"p-fluid"}>

              <div className={"field"}>
                <label className={isPossible === "1" ? commonStyles.labelRequired : ""}>Reálně
                  strávený
                  čas:</label>
                <InputText value={time} onChange={handleTimeChange} type={"number"}
                  placeholder={"Reálně strávený čas"} required={true} step={0.25} min={0} />
                <small className={commonStyles.fieldHelp}>Počet hodin celkem strávených s tímto
                  hlášením. Můžete zadat i čísla s desetinnou částí (0,5 = půl hodiny).</small>
              </div>

              <div className={"field"}>
                <label className={commonStyles.labelRequired}>Poznámka:</label>
                <InputText value={note} onChange={handleNoteChange} placeholder={"Poznámka"}
                  required={true} />
              </div>

              <div className={"field"}>
                <label className={isPossible === "1" ? "" : commonStyles.labelRequired}>Odpovědná
                  osoba:</label>
                <Dropdown value={responsibleUser} options={users} optionValue={"uuid"}
                  itemTemplate={userTemplate} optionLabel={"name"}
                  emptyMessage={"Žádné záznamy"}
                  onChange={handleResponsibleUserChange} />
              </div>
            </div>

            <div className={"flex"}>
              <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
                onClick={uuid ? back : backConfirmation} />

              <div className={"ml-auto"}>
                <Button type={"submit"} label={"Uložit"} className={"m-1"}
                  disabled={!hasPerm("ROLE_PERM_MANAGEMENT_WRITE") || !isFormFilled()} />
              </div>
            </div>
          </form>
        </>
      )}
    </userContext.Consumer>
  )
}
