import { Panel } from "primereact/panel"
import { Badge } from "primereact/badge"
import { Divider } from "primereact/divider"
import styles from "./styles.module.css"

export const Colors = () => (
  <>
    <div className="col-12">
      <Divider align="left">
        Pravidelná údržba
      </Divider>
    </div>

    <Panel header={"Stav kontroly"} className="col-12 md:col-4">
      <div className="my-1">
        <Badge style={{ backgroundColor: "#0288D1" }} value="nový" />
        <i> nově vytvořená pravidelná údržba</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#f4b183" }} value="probíhá" />
        <i> pravidelná údržba, na které aktuálně někdo pracuje</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#00b050" }} value="hotovo" />
        <i> pravidelná údržba se všemi dokončenými podúkoly</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#d0cece" }} value="propadlý" />
        <i> pravidelná údržba, která nebyla dokočena ve stanoveném termínu</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#c5e0b4" }} value="nelze" />
        <i> pravidelná údržba s alespoň jedním podúkolem, který nešel dokončit</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#ff0000" }} value="uzavřený" />
        <i> pravidelná údržba, která se již dále neprovádí</i>
      </div>
    </Panel>

    <Panel header={"Stav podúkolu"} className="col-12 md:col-4">
      <div className="my-1">
        <Badge style={{ backgroundColor: "#0288D1" }} value="otevřeno" />
        <i> nedokončený podúkol</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#689F38" }} value="dokončeno" />
        <i> úspěšně dokončený podúkol</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#FBC02D", color: "#000" }} value="dokončeno (zkontrolováno, nalezena závada)" />
        <i> úspěšně dokončený podúkol s nalezenou závadou</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#D32F2F" }} value="nedokončeno" />
        <i> podúkol, který nemohl být splněn</i>
      </div>
    </Panel>

    <Panel header={"Stav hlášení"} className="col-12 md:col-4">
      <div className="my-3">
        <span style={{ backgroundColor: "#00b0f0", padding: 4 }}>
          <span className="text-white">0</span>
          /
          <span className="text-white">4</span>
        </span>
        <i> žádné související hlášení ještě nebylo dokončeno</i>
      </div>
      <div className="my-3">
        <span style={{ backgroundColor: "#00b050", padding: 4 }}>
          <span className="text-white">4</span>
          /
          <span className="text-white">4</span>
        </span>
        <i> všechna související hlášení jsou dokončena v termínu</i>
      </div>
      <div className="my-3">
        <span style={{ backgroundColor: "#ffc000", padding: 4 }}>
          <span className="text-white">2</span>
          /
          <span className="text-white">4</span>
        </span>
        <i> některá ze souvisejících hlášení ještě nejsou dokončena</i>
      </div>
      <div className="my-3">
        <span style={{ backgroundColor: "#FF0000", padding: 4 }}>
          <span className="text-white">2</span>
          /
          <span className="text-white">4</span>
        </span>
        <i> některé související hlášení je po termínu</i>
      </div>
    </Panel>

    <Panel header={"Požadované termíny"} className="col-12 md:col-4">
      <div className="my-2">
        <span style={{ backgroundColor: "#1769AA", color: "#FFF", borderRadius: 3, padding: 4 }}>
          1. 1. <span className={styles.gt}>&gt;</span> 10. 1.
        </span>
        <i> požadovaný termín pravidelné údržby je před aktuálním datem</i>
      </div>
      <div className="my-2">
        <span style={{ backgroundColor: "#FF9900", color: "#FFF", borderRadius: 3, padding: 4 }}>
          1. 1. <span className={styles.gt}>&gt;</span> 10. 1.
        </span>
        <i> aktuální datum je v rozmezí požadovaných termínů pravidelné údržby</i>
      </div>
      <div className="my-2">
        <span style={{ backgroundColor: "#FF0000", color: "#FFF", borderRadius: 3, padding: 4 }}>
          1. 1. <span className={styles.gt}>&gt;</span> 10. 1.
        </span>
        <i> aktuální datum je po požadovaném termínu pravidelné údržby</i>
      </div>
    </Panel>

    <div className="col-12">
      <Divider align="left">
        Hlášení / Management
      </Divider>
    </div>

    <Panel header={"#"} className="col-12 md:col-4">
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#00b050" }}></span>
        <i> všechny podúkoly dokončeny</i>
      </div>
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#FBC02D" }}></span>
        <i> některé podúkoly dokončeny</i>
      </div>
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#D32F2F" }}></span>
        <i> žádný podúkol není dokončen</i>
      </div>
    </Panel>

    <Panel header={"Stav"} className="col-12 md:col-4">
      <div className="my-1">
        <Badge style={{ backgroundColor: "#0288D1" }} value="nový" />
        <i> nově vytvořené hlášení</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#f4b183" }} value="probíbhá" />
        <i> hlášení, na které aktuálně někdo pracuje</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#00b050" }} value="hotovo" />
        <i> dokončené hlášení</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#9e9e9e" }} value="uzavřený" />
        <i> uzavřené hlášení</i>
      </div>
    </Panel>

    <Panel header={"Podbarvení řádků"} className="col-12 md:col-4">
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#f8b4cb" }}></span>
        <i> akutní hlášení</i>
      </div>
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#FFE4BC" }}></span>
        <i> hlášení typu varování</i>
      </div>
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#cecece" }}></span>
        <i> hlášení typu TPM</i>
      </div>
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#e9ffb0" }}></span>
        <i> hlášení typu závada</i>
      </div>
    </Panel>

    <Panel header={"Požadované termíny"} className="col-12 md:col-4">
      <div className="my-2">
        <span style={{ backgroundColor: "#1769AA", color: "#FFF", borderRadius: 3, padding: 4 }}>
          1. 1. <span className={styles.gt}>&gt;</span> 10. 1.
        </span>
        <i> požadovaný termín hlášení je před aktuálním datem</i>
      </div>
      <div className="my-2">
        <span style={{ backgroundColor: "#FF9900", color: "#FFF", borderRadius: 3, padding: 4 }}>
          1. 1. <span className={styles.gt}>&gt;</span> 10. 1.
        </span>
        <i> aktuální datum je v rozmezí požadovaných termínů hlášení</i>
      </div>
      <div className="my-2">
        <span style={{ backgroundColor: "#FF0000", color: "#FFF", borderRadius: 3, padding: 4 }}>
          1. 1. <span className={styles.gt}>&gt;</span> 10. 1.
        </span>
        <i> aktuální datum je po požadovaném termínu hlášení</i>
      </div>
    </Panel>

    <div className="col-12">
      <Divider align="left">
        Stroje
      </Divider>
    </div>

    <Panel header={"Podbarvení řádků"} className="col-12 md:col-4">
      <div className="my-1">
        <span className={styles.color} style={{ backgroundColor: "#f8b4cb" }}></span>
        <i> stroj není v provozu</i>
      </div>
    </Panel>

    <div className="col-12">
      <Divider align="left">
        GFP
      </Divider>
    </div>

    <Panel header={"Stav podúkolu"} className="col-12 md:col-4">
      <div className="my-1">
        <Badge style={{ backgroundColor: "#0288D1" }} value="otevřeno" />
        <i> nedokončený podúkol</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#689F38" }} value="ok" />
        <i> úspěšně splněný podúkol</i>
      </div>
      <div className="my-1">
        <Badge style={{ backgroundColor: "#D32F2F" }} value="nok" />
        <i> neúspěšně splněný podúkol</i>
      </div>
    </Panel>
  </>
)
