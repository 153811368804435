import { NavLink, useHistory, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { SelectButton } from "primereact/selectbutton"
import { MultiSelect } from "primereact/multiselect"
import { InputText } from "primereact/inputtext"
import moment from "moment"
import store from "store"
import api from "../../api"
import query from "../../function/query"
import MachineFilter from "../../filter/MachineFilter"
import TeamFilter from "../../filter/TeamFilter"
import { useTaskStates } from "../../api/queries/useTaskStates"
import TaskStateFilter from "../../filter/TaskStateFilter"
import Tmpl from "../../function/Tmpl"
import DateFilter from "../../filter/DateFilter"
import UserFilter from "../../filter/UserFilter"
import OperationFilter from "../../filter/OperationFilter"
import MachineTypeFilter from "../../filter/MachineTypeFilter"
import { useWindowWidth } from "../../hook/useWindowWidth"
import styles from "./styles.module.css"
import DateRange from "./DateRange/DateRange"
import Initials from "./Initials/Initials"
import TopButton from "./TopButton/TopButton"
import preventionColumnsDefaultState from "./preventionColumns"
import { useTaskRepeated } from "../../api/queries/useTaskRepeated"
import TaskRepeatedFilter from "../../filter/TaskRepeatedFilter"
import getPreventionStateSeverity from "../../function/getPreventionStateSeverity"
import TaskListControls from "./TaskListControls"
import { storage } from "../../storage"
import { NotFinishedInfo } from "./Prevention/NotFinishedInfo"
import { useDebounce } from "../../hook/useDebounce"

export default function TaskPreventionList() {
  const TWO_MINUTES = 120000
  const history = useHistory()
  const searchStr = useLocation().search
  const search = query.parse(searchStr)
  const [sortField, setSortField] = useState(search.sortField || "created")
  const [sortOrder, setSortOrder] = useState(search.sortOrder || -1)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }
  const windowWidth = useWindowWidth()

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const idTmpl = (row) => <Tmpl title={"Číslo"} value={row.id} />
  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      {/* {!row.is_active && <Badge severity={"danger"} className={"mr-1"} />} */}
      <NavLink to={`/task/prevention/${row.uuid}/subtasks`}>{row.name}</NavLink>
    </Tmpl>
  )

  const [fulltext, setFulltext] = useState(search.fulltext || "")
  const handleFulltextChange = (e) => setFulltext(e.target.value)
  const fulltextDebounced = useDebounce(fulltext, 500)
  const nameFilter = <InputText value={fulltext} onChange={handleFulltextChange} placeholder="Fulltext" />

  const statsTmpl = (row) => <Tmpl title={"Dokončeno podúkolů"}
    value={row.tasks_stats
      ? `${row.tasks_stats.FINISHED}/${row.tasks_stats.total}`
      : ""} />
  const { data: taskStates = [] } = useTaskStates()
  const taskStateTmpl = (row) => {
    if (!taskStates) {
      return ""
    }

    const taskState = taskStates.find(taskState => taskState.value === row.state)
    let taskStateName = taskState ? taskState.name : ""

    if (taskState) {

      if (taskState.value === "PARTIALY_FINISHED") {
        taskStateName = "částečně dokončeno"
      }

      return (
        <Tmpl title={"Stav kontroly"}>
          <div className="flex align-items-center">

            <Badge value={taskStateName}
              severity={"info"}
              className={`block white-space-nowrap overflow-hidden text-overflow-ellipsis ${getPreventionStateSeverity(taskState.value)}`} />
            {row.last_not_finished && <NotFinishedInfo />}
          </div>
        </Tmpl>
      )
    }

    return ""
  }

  const [taskState, setTaskState] = useState(search.state || null)
  const handleTaskStateChange = (e) => setTaskState(e.target.value)
  const taskStateFilter = <TaskStateFilter value={taskState} onChange={handleTaskStateChange} />

  const taskReportStateTmpl = (row) => {
    const relatedReports = row.related.filter((task) => task.type === "REPORT")
    const finishedReports = relatedReports.filter((task) => task.state === "FINISHED" || task.state === "CLOSED")
    const unfinishedReports = relatedReports.filter((task) => task.state !== "FINISHED")

    const now = moment()
    const someReportAfterDate = unfinishedReports.some((task) => task.ends_at && moment(task.ends_at).isBefore(now))
    let className = ""

    if (someReportAfterDate) {
      className = styles.reportStateRed
    } else {
      if (relatedReports.length === finishedReports.length) {
        className = styles.reportStateGreen
      } else if (relatedReports.length > 0 && finishedReports.length === 0) {
        className = styles.reportStateBlue
      } else {
        className = styles.reportStateOrange
      }
    }

    return (
      <div className={className}>
        <NavLink to={`/task/${row.uuid}/related/finished`}>
          {finishedReports.length}
        </NavLink>
        /
        <NavLink to={`/task/${row.uuid}/related/all`}>
          {relatedReports.length}
        </NavLink>
      </div>
    )
  }

  const createdByTmpl = (row) => (
    <Tmpl title={"Založil"}>
      {row.created_by && (
        <span className={"word-break-word"}>{row.created_by.name}</span>
      )}
    </Tmpl>
  )
  const [createdBy, setCreatedBy] = useState(search.created_by || null)
  const handleCreatedByChange = (e) => setCreatedBy(e.target.value)
  const createdByFilter = <UserFilter value={createdBy} onChange={handleCreatedByChange}
    placeholder={"Založil"} />

  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = <MachineFilter value={machine} onChange={handleMachineChange} />
  const machineTmpl = (row) => (
    <Tmpl title={"Stroj"}>
      <strong>{row.machine ? row.machine.name : ""}</strong>
    </Tmpl>
  )

  const [machineType, setMachineType] = useState(search.machine_type || null)
  const handleMachineTypeChange = (e) => setMachineType(e.target.value)
  const machineTypeFilter = <MachineTypeFilter value={machineType}
    onChange={handleMachineTypeChange} />
  const machineTypeTmpl = (row) => <Tmpl title={"Typ stroje"}
    value={row.machine && row.machine.type ? row.machine.type.name : ""} />

  const { data: taskRepeatedOptions = [] } = useTaskRepeated()
  const [taskRepeated, setTaskRepeated] = useState(search.repeated || null)
  const handleTaskRepeatedChange = (e) => setTaskRepeated(e.target.value)
  const taskRepeatedFilter = <TaskRepeatedFilter value={taskRepeated}
    onChange={handleTaskRepeatedChange} />
  const taskRepeatedTmpl = (row) => {
    const repeated = taskRepeatedOptions.find(repeated => repeated.value === row.repeated)
    return <Tmpl title={"Opakování"} value={repeated ? repeated.name : ""} />
  }

  const [team, setTeam] = useState(search.team || null)
  const handleTeamChange = (e) => setTeam(e.target.value)
  const teamFilter = <TeamFilter value={team} onChange={handleTeamChange} />
  const teamTmpl = (row) => <Tmpl title={"Tým"} value={row.team ? row.team.name : ""} />

  const [userOption, setUserOption] = useState(search.user || null)
  const handleUserOptionChange = (e) => setUserOption(e.target.value)
  const userFilter = <UserFilter value={userOption} onChange={handleUserOptionChange}
    placeholder={"Odpovědná osoba"} />
  const userTmpl = (row) => <Tmpl title={"Odpovědná osoba"} value={row.user ? row.user.name : ""} />

  const [operation, setOperation] = useState(search.operation || null)
  const handleOperationChange = (e) => setOperation(e.target.value)
  const operationFilter = <OperationFilter value={operation} onChange={handleOperationChange} />
  const operationTmpl = (row) => <Tmpl title={"Řeší provoz"}
    value={row.operation ? row.operation.name : ""} />

  const [startsAt, setStartsAt] = useState(search.starts_at
    ? moment(search.starts_at).toDate() : null)
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)
  const startsAtFilter = <DateFilter value={startsAt} onChange={handleStartsAtChange}
    placeholder={"Požadovaný termín od"} />
  const startsAtTmpl = (row) => (
    <Tmpl
      title={"Požadovaný termín od"}
      value={row.starts_at ? moment(row.starts_at).format("DD. MM. YYYY") : ""}
    />
  )

  const [endsAt, setEndsAt] = useState(search.ends_at
    ? moment(search.ends_at).toDate() : null)
  const handleEndsAtChange = (e) => setEndsAt(e.target.value)
  const endsAtFilter = <DateFilter value={endsAt} onChange={handleEndsAtChange}
    placeholder={"Požadovaný termín do"} />
  const endsAtTmpl = (row) => (
    <Tmpl
      title={"Požadovaný termín do"}
      value={row.ends_at ? moment(row.ends_at).format("DD. MM. YYYY") : ""}
    />
  )

  const [selectedRow, setSelectedRow] = useState("")
  const onRowClick = (e) => setSelectedRow(
    selectedRow && selectedRow.uuid === e.data.uuid
      ? ""
      : e.data
  )

  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const toggleFilters = () => setIsFiltersVisible(!isFiltersVisible)

  const viewTypes = [
    { label: "Aktivní pr. údržba", value: "active" },
    { label: 'Historie pr. údržby', value: "history" },
  ]
  const [viewType, setViewType] = useState(search.view || "active")
  const changeViewType = (e) => e.value && setViewType(e.value)

  const columnsInitState = store.get("dora.taskPreventionList.columns")
  const [columnsState, setColumnsState] = useState(columnsInitState || preventionColumnsDefaultState)
  const columnsVisible = columnsState
    .filter((column) => column.visible)
    .map((column) => column.key)

  useEffect(() => {
    store.set("dora.taskPreventionList.columns", columnsState)
  }, [columnsState])

  const onColReorder = (e) => {
    const orderedColumns = e.columns
    const newColumnsState = [...columnsState]

    orderedColumns.forEach((orderedColumn, index) => {
      const column = newColumnsState.find((c) => c.key === orderedColumn.props.columnKey)

      if (column) {
        column.index = index
      }
    })

    setColumnsState(newColumnsState)
  }

  const onColToggle = (e) => {
    const visibleColumnKeys = e.value

    const newColumnsState = [...columnsState]

    newColumnsState.forEach((column) => {
      column.visible = visibleColumnKeys.indexOf(column.key) > -1
    })

    setColumnsState(newColumnsState)
  }

  const header = (
    <div className={"flex align-items-center"}>
      <label className={"hidden sm:inline"}>Zobrazit:</label>
      <SelectButton value={viewType} options={viewTypes} onChange={changeViewType}
        className={"inline-flex mx-1"} />

      <Button className={"button-toggle-filters mx-1 p-button-raised p-button-text"}
        label={"Filtrovat"} onClick={toggleFilters} />

      <MultiSelect value={columnsVisible} options={columnsState}
        selectedItemsLabel={"{0} sloupců zobrazeno"} optionLabel={"label"}
        optionValue={"key"} className={"hidden md:inline-flex ml-auto"}
        onChange={onColToggle} optionDisabled={"disabled"} />
    </div>
  )

  const handleExport = () => {
    const params = Object.assign({}, search)
    params.type = "PREVENTION"
    delete params.limit
    delete params.offset

    api.get(`/tasks/export${query.toString(params)}`)
      .then(({ data }) => {
        window.open(data.data.url)
      })
  }

  useEffect(() => {
    const getData = () => {
      setIsLoading(true)

      let prms = {
        limit, offset, state: taskState, type: "PREVENTION", machine, repeated: taskRepeated,
        team, user: userOption, operation, sortField, sortOrder, machine_type: machineType,
        created_by: createdBy,
        view: viewType,
        fulltext: fulltextDebounced,
      }

      if (startsAt) {
        prms.starts_at = moment(startsAt).format("YYYY-MM-DD")
      }

      if (endsAt) {
        prms.ends_at = moment(endsAt).format("YYYY-MM-DD")
      }

      if (search.my_tasks) {
        prms.my_tasks = search.my_tasks
      }

      const queryParamsStr = query.toString(prms)
      history.push({ search: queryParamsStr })

      api.get(`/tasks${queryParamsStr}`)
        .then(({ data, headers }) => {
          setTotal(parseInt(headers["x-total-count"], 10))
          setData(data.data)
        })
        .finally(() => setIsLoading(false))
    }

    const updateInterval = setInterval(getData, TWO_MINUTES)

    getData()

    return () => {
      clearInterval(updateInterval)
    }
  }, [limit, offset, taskState, machine, taskRepeated, team, history, sortField, sortOrder,
    startsAt, endsAt, userOption, operation, machineType, viewType, createdBy, search.my_tasks,
    fulltextDebounced])

  const _desktopColumns = {
    id: <Column
      key={"id"} columnKey={"id"}
      header={"#"} body={idTmpl}
      sortable={true} sortField={"id"} />,

    machine: <Column
      key={"machine"} columnKey={"machine"}
      header={"Stroj"} body={machineTmpl}
      sortable={true} sortField={"machine"}
      filter={true} filterElement={machineFilter} />,

    name: <Column
      key={"name"} columnKey={"name"}
      header={"Název"} body={nameTmpl}
      sortable={true} sortField={"name"}
      filter={true} filterElement={nameFilter} />,

    stats: <Column
      key={"stats"} columnKey={"stats"}
      header={"Dokončeno podúkolů"} body={statsTmpl} />,

    state: <Column
      key={"state"} columnKey={"state"}
      header={"Stav kontroly"} body={taskStateTmpl}
      sortable={true} sortField={"state"}
      filter={true} filterElement={taskStateFilter} />,

    // :facepalm:
    reportState: <Column
      key={"report_state"} columnKey={"report_state"}
      header={"Stav hlášení"} body={taskReportStateTmpl}
      bodyClassName={styles.reportState} />,

    createdBy: <Column
      key={"createdby"} columnKey={"createdBy"}
      header={"Založil"} body={createdByTmpl}
      sortable={true} sortField={"createdby"}
      filter={true} filterElement={createdByFilter} />,

    machineType: <Column key={"machineType"} columnKey={"machineType"}
      header={"Typ stroje"} body={machineTypeTmpl}
      sortable={true} sortField={"machinetype"}
      filter={true} filterElement={machineTypeFilter} />,

    repeated: <Column
      key={"repeated"} columnKey={"repeated"}
      header={"Opakování"} body={taskRepeatedTmpl}
      filter={true} filterElement={taskRepeatedFilter} />,

    team: <Column
      key={"team"} columnKey={"team"}
      header={"Tým"} body={teamTmpl}
      sortable={true} sortField={"team"}
      filter={true} filterElement={teamFilter} />,

    user: <Column
      key={"user"} columnKey={"user"}
      header={"Odpovědná osoba"} body={userTmpl}
      sortable={true} sortField={"user"}
      filter={true} filterElement={userFilter} />,

    operation: <Column
      key={"operation"} columnKey={"operation"}
      header={"Řeší provoz"} body={operationTmpl}
      sortable={true} sortField={"operation"}
      filter={true} filterElement={operationFilter} />,

    startsAt: <Column
      key={"startsAt"} columnKey={"startsAt"}
      header={"Požadovaný termín od"} body={startsAtTmpl}
      sortable={true} sortField={"start"}
      filter={true} filterElement={startsAtFilter} />,

    endsAt: <Column
      key={"endsAt"} columnKey={"endsAt"}
      header={"Požadovaný termín do"} body={endsAtTmpl}
      sortable={true} sortField={"endsat"}
      filter={true} filterElement={endsAtFilter} />,
  }

  let desktopColumns = [...columnsState]
    .filter((column) => column.visible)
    .sort((column1, column2) => column1.index - column2.index)
    .map((column) => _desktopColumns[column.key])

  const mobileMessTemplate = (row) => (
    <>
      <div className={"flex align-items-center"}>
        <div className={styles.w20}>{idTmpl(row)}</div>
        <div className={styles.w20}>{machineTmpl(row)}</div>
        <div className={styles.w50}>{nameTmpl(row)}</div>
        <div className={`${styles.w10} text-right`}>{statsTmpl(row)}</div>
      </div>
      <div className={"flex align-items-center"}>
        <div className={styles.w20}>{taskRepeatedTmpl(row)}</div>
        <div className={styles.w20}>
          {row.user && <Initials name={row.user.name} />}
        </div>
        <div className={styles.w40}>
          <DateRange startsAt={row.starts_at} endsAt={row.ends_at} finishedAt={row.finished_at} />
        </div>
        <div className={styles.w15}>{taskStateTmpl(row)}</div>
      </div>
    </>
  )

  const mobileColumns = [
    <Column
      key={"mess"} columnKey={"mess"}
      header={""} body={mobileMessTemplate}
      className={"p-column-common"} />,

    <Column
      key={"id"} columnKey={"id"}
      header={"#"} body={idTmpl}
      sortable={true} sortField={"id"}
      className={"p-column-hidden"} />,

    <Column
      key={"machine"} columnKey={"machine"}
      header={"Stroj"} body={machineTmpl}
      filter={true} filterElement={machineFilter}
      sortable={true} sortField={"machine"}
      className={"p-column-hidden"} />,

    <Column
      key={"name"} columnKey={"name"}
      header={"Název"} body={nameTmpl}
      sortable={true} sortField={"name"}
      filter={true} filterElement={nameFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"state"} columnKey={"state"}
      header={"Stav"} body={taskStateTmpl}
      sortable={true} sortField={"state"}
      filter={true} filterElement={taskStateFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"createdby"} columnKey={"createdBy"}
      header={"Založil"} body={createdByTmpl}
      filter={true} filterElement={createdByFilter}
      sortable={true} sortField={"createdby"}
      className={"p-column-hidden"} />,

    <Column
      key={"machineType"} columnKey={"machineType"}
      header={"Typ stroje"} body={machineTypeTmpl}
      sortable={true} sortField={"machinetype"}
      filter={true} filterElement={machineTypeFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"repeated"} columnKey={"repeated"}
      header={"Opakování"} body={taskRepeatedTmpl}
      filter={true} filterElement={taskRepeatedFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"team"} columnKey={"team"}
      header={"Tým"} body={teamTmpl}
      filter={true} filterElement={teamFilter}
      sortable={true} sortField={"team"}
      className={"p-column-hidden"} />,

    <Column
      key={"user"} columnKey={"user"}
      header={"Odpovědná osoba"} body={userTmpl}
      sortable={true} sortField={"user"}
      filter={true} filterElement={userFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"operation"} columnKey={"operation"}
      header={"Řeší provoz"} body={operationTmpl}
      sortable={true} sortField={"operation"}
      filter={true} filterElement={operationFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"startsAt"} columnKey={"startsAt"}
      header={"Požadovaný termín od"} body={startsAtTmpl}
      sortable={true} sortField={"start"}
      filter={true} filterElement={startsAtFilter}
      className={"p-column-hidden"} />,

    <Column
      key={"endsAt"} columnKey={"endsAt"}
      header={"Požadovaný termín do"} body={endsAtTmpl}
      sortable={true} sortField={"endsat"}
      filter={true} filterElement={endsAtFilter}
      className={"p-column-hidden"}
    />,
  ]

  return (
    <>
      <TaskListControls exportHandler={handleExport} showMassActions={true} />

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField}
          sortOrder={sortOrder}
          className={`p-datatable-sm p-datatable-collapsible p-datatable-reorderable ${isFiltersVisible ? "p-datatable-filters" : ""}`}
          selectionMode={"single"} selection={selectedRow} onRowClick={onRowClick}
          header={header}
          reorderableColumns={windowWidth >= 768} onColReorder={onColReorder}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          {windowWidth >= 768 ? desktopColumns : mobileColumns}
        </DataTable>
      </div>

      <TopButton />
    </>
  )
}
