import { Dropdown } from "primereact/dropdown"
import { useTaskStates } from "../api/queries/useTaskStates"

export default function TaskStateFilter(props) {
  const { data: taskStates = [] } = useTaskStates()

  return (
    <Dropdown options={taskStates} value={props.value} optionLabel={"name"}
      optionValue={"value"} placeholder={"Stav"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
