import { Dropdown } from "primereact/dropdown"
import { usePlaces } from "../api/queries/usePlaces"

export default function PlaceFilter(props) {
  const { data: places = [] } = usePlaces()

  return (
    <Dropdown options={places} value={props.value} optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={"Místo"} onChange={props.onChange} filter={true} />
  )
}
