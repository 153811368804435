import { Dropdown } from "primereact/dropdown"
import { useMachineTypes } from "../api/queries/useMachineTypes"

export default function MachineTypeFilter(props) {
  const { data: machineTypes = [] } = useMachineTypes()

  return (
    <Dropdown options={machineTypes} value={props.value} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Typ stroje"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
