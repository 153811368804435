import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import commonStyles from "../common.module.css"
import { useContext, useState } from "react"
import userContext from "../../context/userContext"
import api from "../../api"

export const ChangePasswordForm = () => {
  const { logout } = useContext(userContext)
  const [oldPass, setOldPass] = useState("")
  const onOldPassChange = (e) => setOldPass(e.target.value)

  const [isOldPassVisible, setIsOldPassVisible] = useState(false)
  const onIsOldPassVisibleChange = () => setIsOldPassVisible(!isOldPassVisible)

  const [newPass, setNewPass] = useState("")
  const onNewPassChange = (e) => setNewPass(e.target.value)

  const [isNewPassVisible, setIsNewPassVisible] = useState(false)
  const onIsNewPassVisibleChange = () => setIsNewPassVisible(!isNewPassVisible)

  const [newPassConfirm, setNewPassConfirm] = useState("")
  const onNewPassConfirmChange = (e) => setNewPassConfirm(e.target.value)

  const [isNewPassConfirmVisible, setIsNewPassConfirmVisible] = useState(false)
  const onIsNewPassConfirmVisibleChange = () => setIsNewPassConfirmVisible(!isNewPassConfirmVisible)

  const [newPassMatch, setNewPassMatch] = useState(true)

  const onSubmit = (e) => {
    e.preventDefault()

    if (newPass === newPassConfirm) {
      setNewPassMatch(true)

      api.post("/users/change-password", {
        old_password: oldPass,
        new_password: newPass,
      }).then(() => {
        alert("Heslo změněno. Nyní se znovu přihlašte.")
        logout()
      })
    } else {
      setNewPassMatch(false)
    }
  }

  return (
    <form className={"col-12 md:col-6"} onSubmit={onSubmit}>
      <div className="p-fluid">

        <div className={"field"}>
          <label className={commonStyles.labelRequired}>Staré heslo:</label>
          <div className="p-inputgroup">
            <InputText required={true} type={isOldPassVisible ? "text" : "password"}
              value={oldPass} onChange={onOldPassChange} minLength={8} />
            <Button type={"button"} className={"p-button-secondary"}
              icon={isOldPassVisible ? "pi pi-eye-slash" : "pi pi-eye"}
              onClick={onIsOldPassVisibleChange} />
          </div>
        </div>

        <div className={"field"}>
          <label className={commonStyles.labelRequired}>Nové heslo:</label>
          <div className="p-inputgroup">
            <InputText required={true} type={isNewPassVisible ? "text" : "password"}
              value={newPass} onChange={onNewPassChange} minLength={8}
              className={newPassMatch ? "" : "p-invalid"} />
            <Button type={"button"} className={"p-button-secondary"}
              icon={isNewPassVisible ? "pi pi-eye-slash" : "pi pi-eye"}
              onClick={onIsNewPassVisibleChange} />
          </div>
          {!newPassMatch && <small className={commonStyles.fieldError}>Hesla se neshodují.</small>}
        </div>

        <div className={"field"}>
          <label className={commonStyles.labelRequired}>Nové heslo znovu:</label>
          <div className="p-inputgroup">
            <InputText required={true} type={isNewPassConfirmVisible ? "text" : "password"}
              value={newPassConfirm} onChange={onNewPassConfirmChange} minLength={8}
              className={newPassMatch ? "" : "p-invalid"} />
            <Button type={"button"} className={"p-button-secondary"}
              icon={isNewPassConfirmVisible ? "pi pi-eye-slash" : "pi pi-eye"}
              onClick={onIsNewPassConfirmVisibleChange} />
          </div>
          {!newPassMatch && <small className={commonStyles.fieldError}>Hesla se neshodují.</small>}
        </div>

      </div>

      <div className="flex">
        <Button type={"submit"} label={"Uložit"} className={"ml-auto"} />
      </div>
    </form>
  )
}
