import { useEffect, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import moment from "moment"
import api from "../../../api"
import query from "../../../function/query"
import Tmpl from "../../../function/Tmpl"
import commonStyles from "../../common.module.css"
import FulltextFilter from "../../../filter/FulltextFilter"
import { useTaskTags } from "../../../api/queries/useTaskTags"

export default function MachineList() {
  const history = useHistory()
  const search = query.parse(useLocation().search)
  const [sortField, setSortField] = useState(query.sortField)
  const [sortOrder, setSortOrder] = useState(query.sortOrder)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }

  const [data, setData] = useState([])
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [fulltext, setFulltext] = useState(search.fulltext || null)
  const handleFulltextChange = (e) => setFulltext(e.target.value)
  const fulltextFilter = <FulltextFilter value={fulltext} onChange={handleFulltextChange} />

  const isOperable = (row) => {
    const urgent = row.statistics.tags.find((tag) => tag.priority)

    if (urgent) {
      return urgent.active === 0
    }

    return true
  }
  const rowClass = (row) => ({ [commonStyles.tableRowDanger]: !isOperable(row) })

  const onPage = (event) => setOffset(event.first)

  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      <NavLink to={`/enum/machine/${row.uuid}`}>{row.name}</NavLink>
    </Tmpl>
  )

  const isOperableTmpl = (row) => (
    <Tmpl title={"Schopen provozu"} value={isOperable(row) ? "Ano" : "Ne"} />
  )

  const activeTaskTmpl = (row) => (
    <Tmpl title={"Aktivní úkoly"}>
      {row.statistics.active > 0 && (
        <span>{row.statistics.active}</span>
      )}
    </Tmpl>
  )

  const { data: taskTags = [] } = useTaskTags()

  const getTaskTagName = (key) => {
    const taskTag = taskTags.find((taskTag) => taskTag.key === key)

    if (taskTag) {
      return taskTag.name
    }

    return ""
  }

  const taskTagTmpl = (row, key) => {
    const taskTag = row.statistics.tags.find((tag) => tag.key === key)

    if (!taskTag) {
      return ""
    }

    return (
      <Tmpl title={taskTag.name}>
        {taskTag.active > 0 && (
          <NavLink to={`/tasks/reports?machine=${row.uuid}&tag=${taskTag.uuid}`}>
            {taskTag.active}
          </NavLink>
        )}
      </Tmpl>
    )
  }

  const todayPreventionTmpl = (row) => (
    <Tmpl title={"Pravidelná údržba dnes"}>
      {row.statistics.today_prevention > 0 && (
        <NavLink
          to={`/tasks/preventions?machine=${row.uuid}&type=PREVENTION&starts_at=${moment().format("YYYY-MM-DD")}`}>
          {row.statistics.today_prevention}
        </NavLink>
      )}
    </Tmpl>
  )

  const notFinishedTmpl = (row) => (
    <Tmpl title={"Po termínu"}>
      {row.statistics.not_finished > 0 && (
        <NavLink to={`/tasks/preventions?machine=${row.uuid}&state=NOT_FINISHED&view=history`}>
          {row.statistics.not_finished}
        </NavLink>
      )}
    </Tmpl>
  )

  const finishedTmpl = (row) => (
    <Tmpl title={"Dokončeno"}>
      {row.statistics.finished > 0 && (
        <NavLink to={`/tasks/preventions?machine=${row.uuid}&state=FINISHED&view=history`}>
          {row.statistics.finished}
        </NavLink>
      )}
    </Tmpl>
  )

  const [selectedRow, setSelectedRow] = useState("")
  const onRowClick = (e) => setSelectedRow(
    selectedRow && selectedRow.uuid === e.data.uuid
      ? ""
      : e.data
  )

  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const toggleFilters = () => setIsFiltersVisible(!isFiltersVisible)

  const header = (
    <Button className={"button-toggle-filters m-1 p-button-raised p-button-text"}
      label={"Filtrovat"} onClick={toggleFilters} />
  )

  useEffect(() => {
    setIsLoading(true)
    const queryParamsStr = query.toString({
      limit: 20, offset, fulltext, sortField, sortOrder,
    })
    history.push({ search: queryParamsStr })

    api.get(`/machines${queryParamsStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setData(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [offset, history, sortField, sortOrder, fulltext])

  return (
    <div className={"col-12"}>
      <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
        rows={20} first={offset} totalRecords={total} onPage={onPage}
        loading={isLoading} onSort={onSort} sortField={sortField} sortOrder={sortOrder}
        rowClassName={rowClass}
        className={`p-datatable-sm p-datatable-collapsible ${isFiltersVisible ? "p-datatable-filters" : ""}`}
        selectionMode={"single"} selection={selectedRow} onRowClick={onRowClick}
        dataKey={"uuid"} header={header}>
        <Column header={"Název"} body={nameTmpl} sortable={true} sortField={"name"} filter={true}
          filterElement={fulltextFilter} />
        <Column header={"Schopen provozu"} body={isOperableTmpl} className={"p-column-hidden"} />
        <Column header={"Aktivní úkoly"} body={activeTaskTmpl} className={"p-column-hidden"} />
        <Column header={getTaskTagName("akutni")} body={(row) => taskTagTmpl(row, "akutni")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("varovani")} body={(row) => taskTagTmpl(row, "varovani")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("zapis")} body={(row) => taskTagTmpl(row, "zapis")}
          className={"p-column-hidden"} />
        <Column header={"Pravidelná údržba dnes"} body={todayPreventionTmpl}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("tpm")} body={(row) => taskTagTmpl(row, "tpm")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("pozadavky")} body={(row) => taskTagTmpl(row, "pozadavky")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("projekty")} body={(row) => taskTagTmpl(row, "projekty")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("nekvalita")} body={(row) => taskTagTmpl(row, "nekvalita")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("ehs")} body={(row) => taskTagTmpl(row, "ehs")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("czd-leto")} body={(row) => taskTagTmpl(row, "czd-leto")}
          className={"p-column-hidden"} />
        <Column header={getTaskTagName("czd-zima")} body={(row) => taskTagTmpl(row, "czd-zima")}
          className={"p-column-hidden"} />
        <Column header={"Po termínu"} body={notFinishedTmpl} className={"p-column-hidden"} />
        <Column header={"Dokončeno"} body={finishedTmpl} className={"p-column-hidden"} />
      </DataTable>
    </div>
  )
}
