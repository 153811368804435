import {useState} from "react"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import {Card} from "primereact/card"
import commonStyles from "../common.module.css"
import userContext from "../../context/userContext"

export default function Auth() {
  const [user, setUser] = useState("")
  const handleUserChange = (e) => setUser(e.target.value)
  const [pass, setPass] = useState("")
  const handlePassChange = (e) => setPass(e.target.value)
  const handleSubmit = (e, login) => {
    e.preventDefault()
    login({user, pass})
  }

  return (
    <userContext.Consumer>
      {({login}) => (
        <form className={"col-12 p-fluid"} onSubmit={(e) => handleSubmit(e, login)}>
          <Card title={"Přihlásit"} className={"col-12 md:col-4 md:col-offset-4"}>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Jméno:</label>
              <InputText required={true} value={user} onChange={handleUserChange}/>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Heslo:</label>
              <InputText type={"password"} required={true} value={pass}
                         onChange={handlePassChange}/>
            </div>

            <Button label={"Přihlásit"}/>
          </Card>
        </form>
      )}
    </userContext.Consumer>
  )
}
