import React, {useEffect, useState} from "react"
import {Bar} from "@nivo/bar"
import {Calendar} from "primereact/calendar"
import {ScrollPanel} from "primereact/scrollpanel"
import moment from "moment"
import api from "../../../api"
import styles from "./styles.module.css"

export default function ReportTasks() {
  const now = moment().toDate()
  const [from, setFrom] = useState(now)
  const handleFromChange = (e) => setFrom(e.target.value)
  const [to, setTo] = useState(now)
  const handleToChange = (e) => setTo(e.target.value)
  const [data, setData] = useState(null)

  const baseGraphProps = {
    width: 400,
    height: 200,
    margin: {
      top: 0,
      right: 0,
      bottom: 25,
      left: 0,
    },
    tooltip: () => ""
  }

  const getPreventionsProps = () => Object.assign({}, baseGraphProps, {
    data: data.preventions.state.map((item) => ({
      id: item.value, value: item.total,
    })),
  })

  const getSubtasksProps = () => Object.assign({}, baseGraphProps, {
    data: [{id: "dokončeno", value: data.subtasks.finished}, {
      id: "nedokončeno",
      value: data.subtasks.not_finished
    }, {id: "neznámé", value: data.subtasks.unknown},],
  })

  const getUnfinishedSubtasksProps = () => Object.assign({}, baseGraphProps, {
    data: data.subtasks.cause_unfinished.map((item) => ({
      id: item.value, value: item.total,
    })),
  })

  useEffect(() => {
    const f = moment(from).format("YYYY-MM-DD")
    const t = moment(to).format("YYYY-MM-DD")

    api.get(`/report/tasks?from=${f}&to=${t}`)
      .then(({data}) => setData(data.data))
  }, [from, to])

  return (
    <>
      <div className={"col-12 md:col-3"}>
        <label>Od: </label>
        <Calendar value={from} dateFormat={"dd. mm. yy"} onChange={handleFromChange}
                  showButtonBar={true} placeholder={"Od"}/>
      </div>
      <div className={"col-12 md:col-3"}>
        <label>Do: </label>
        <Calendar value={to} dateFormat={"dd. mm. yy"} onChange={handleToChange}
                  showButtonBar={true} placeholder={"Do"}/>
      </div>

      {data && (
        <div className={"grid"}>
          <div className={"col-12 md:col-6"}>
            <table className={styles.table}>
              <thead>
              <tr>
                <th colSpan={2}>Stav pravidelné údržby</th>
              </tr>
              </thead>
              <tbody>
              {data.preventions.state.map((item) => (
                <tr key={item.key}>
                  <th>{item.value}</th>
                  <td>{item.total}</td>
                </tr>
              ))}
              <tr>
                <th>celkem</th>
                <td>{data.preventions.total}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className={"col-12 md:col-6 text-center"}>
            <ScrollPanel style={{width: "100%", height: 225}}>
              <Bar {...getPreventionsProps()}/>
            </ScrollPanel>
          </div>

          <div className={"col-12 md:col-6"}>
            <table className={styles.table}>
              <thead>
              <tr>
                <th colSpan={2}>Stav úkolů pravidelné údržby</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>dokončeno</th>
                <td>{data.subtasks.finished}</td>
              </tr>
              <tr>
                <th>nedokončeno</th>
                <td>{data.subtasks.not_finished}</td>
              </tr>
              <tr>
                <th>neznámé</th>
                <td>{data.subtasks.unknown}</td>
              </tr>
              <tr>
                <th>celkem</th>
                <td>{data.subtasks.total}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className={"col-12 md:col-6 text-center"}>
            <ScrollPanel style={{width: "100%", height: 225}}>
              <Bar {...getSubtasksProps()}/>
            </ScrollPanel>
          </div>

          <div className={"col-12 md:col-6"}>
            <table className={styles.table}>
              <thead>
              <tr>
                <th colSpan={2}>Důvod nedokončení úkolů pravidelné údržby</th>
              </tr>
              </thead>
              <tbody>
              {data.subtasks.cause_unfinished.map((item) => (
                <tr key={item.key}>
                  <th>{item.value}</th>
                  <td>{item.total}</td>
                </tr>
              ))}
              <tr>
                <th>celkem</th>
                <td>{data.subtasks.not_finished}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className={"col-12 md:col-6 text-center"}>
            <ScrollPanel style={{width: "100%", height: 225}}>
              <Bar {...getUnfinishedSubtasksProps()}/>
            </ScrollPanel>
          </div>
        </div>
      )}
    </>
  )
}
