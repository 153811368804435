import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async () => {
  const { data } = await api.get(`/machines?all=1`)
  return data.data
}

export const useAllMachines = () => {
  const { data, ...props } = useQuery(
    queryKeys.MACHINES_ALL(),
    () => getData(),
  )
  return { data, ...props }
}
