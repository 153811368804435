import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async () => {
  const { data } = await api.get(`/warehouse-positions`)
  return data.data
}

export const useWarehousePositions = () => {
  const { data, ...props } = useQuery(
    queryKeys.WAREHOUSE_POSITIONS,
    () => getData(),
  )
  return { data, ...props }
}
