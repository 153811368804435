import styles from "../component/Task/styles.module.css"

const getPreventionStateSeverity = (state) => {
  if (state === "PARTIALY_FINISHED") {
    return styles.bgPartiallyGreen
  }

  if (state === "IN_PROGRESS") {
    return styles.bgProgressRed
  }

  if (state === "FINISHED") {
    return styles.bgSuccessGreen
  }

  if (state === "NOT_FINISHED") {
    return styles.bgFailedGray
  }

  if (state === "CLOSED") {
    return styles.bgFailedRed
  }

  return ""
}

export default getPreventionStateSeverity
