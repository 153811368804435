import { useContext } from "react"
import { NavLink } from "react-router-dom"
import styles from "./styles.module.css"
import logoText from "./logotext.svg"
import GSLogo from "./gslogo.svg"
import { APP_VERSION } from "../Changelog/Changelog"
import userContext from "../../context/userContext"

export default function Footer() {
  const user = useContext(userContext)

  return (
    <div className={`flex align-items-center px-2 ${styles.footer}`}>
      <img src={logoText} alt={"Logo"} height={20} />
      {user.user.uuid && (
        <NavLink to={"/changelog"} className={"text-white ml-3 underline"} title={"Přehled změn"}>
          {APP_VERSION}
        </NavLink>
      )}
      <img src={GSLogo} alt={"GoodSailors logo"} height={20} className={"ml-auto"} />
    </div>
  )
}
