import { Dropdown } from "primereact/dropdown"
import { useTaskTags } from "../api/queries/useTaskTags"

export default function TaskTagFilter(props) {
  const { data: taskTags = [] } = useTaskTags()

  return (
    <Dropdown options={taskTags} value={props.value} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Typ"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
