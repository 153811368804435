import { Dropdown } from "primereact/dropdown"
import { useTaskTypes } from "../api/queries/useTaskTypes"

export default function TaskTypeFilter(props) {
  const { data: taskTypes = [] } = useTaskTypes()

  return (
    <Dropdown options={taskTypes} value={props.value} optionLabel={"name"}
      optionValue={"value"} placeholder={"Kategorie"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
