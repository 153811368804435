import { Dropdown } from "primereact/dropdown"
import { useUsers } from "../api/queries/useUsers"

export default function UserFilter(props) {
  const { data: users = [] } = useUsers()

  return (
    <Dropdown options={users} value={props.value} optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={props.placeholder} onChange={props.onChange}
      filter={true} />
  )
}
