import { useCallback, useEffect, useState } from "react"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { ProgressSpinner } from "primereact/progressspinner"
import api from "./api"
import userContext from "./context/userContext"
import Navbar from "./component/Navbar/Navbar"
import Breadcrumb from "./component/Breadcrumb/Breadcrumb"
import Auth from "./component/Auth/Auth"
import Homepage from "./component/Homepage/Homepage"
import TaskReportList from "./component/Task/TaskReportList"
import EnumMachineList from "./component/Enum/Machine/EnumMachineList"
import TaskPreventionForm from "./component/Task/Prevention/TaskPreventionForm"
import TaskPreventionSubTaskForm from "./component/Task/Prevention/TaskPreventionSubTaskForm"
import TaskReportForm from "./component/Task/Report/TaskReportForm"
import TaskReportFinishForm from "./component/Task/Report/TaskReportFinishForm"
import TaskMachineList from "./component/Task/Machine/TaskMachineList"
import EnumMachineForm from "./component/Enum/Machine/EnumMachineForm"
import EnumMachineTypeList from "./component/Enum/MachineType/EnumMachineTypeList"
import EnumMachineTypeForm from "./component/Enum/MachineType/EnumMachineTypeForm"
import EnumUserList from "./component/Enum/Users/EnumUserList"
import EnumUserForm from "./component/Enum/Users/EnumUserForm"
import EnumTeamList from "./component/Enum/Team/EnumTeamList"
import EnumTeamForm from "./component/Enum/Team/EnumTeamForm"
import EnumOperationList from "./component/Enum/Operation/EnumOperationList"
import EnumOperationForm from "./component/Enum/Operation/EnumOperationForm"
import EnumPlaceList from "./component/Enum/Place/EnumPlaceList"
import EnumPlaceForm from "./component/Enum/Place/EnumPlaceForm"
import EnumWarehouseSupplierList
  from "./component/Enum/Warehouse/Supplier/EnumWarehouseSupplierList"
import EnumWarehouseSupplierForm
  from "./component/Enum/Warehouse/Supplier/EnumWarehouseSupplierForm"
import EnumTaskTagList from "./component/Enum/TaskTag/EnumTaskTagList"
import EnumTaskTagForm from "./component/Enum/TaskTag/EnumTaskTagForm"
import EnumWarehouseWarehouseList
  from "./component/Enum/Warehouse/Warehouse/EnumWarehouseWarehouseList"
import EnumWarehouseWarehouseForm
  from "./component/Enum/Warehouse/Warehouse/EnumWarehouseWarehouseForm"
import EnumWarehouseCategoryList
  from "./component/Enum/Warehouse/Category/EnumWarehouseCategoryList"
import EnumWarehouseCategoryForm
  from "./component/Enum/Warehouse/Category/EnumWarehouseCategoryForm"
import EnumWarehousePositionList
  from "./component/Enum/Warehouse/Position/EnumWarehousePositionList"
import EnumWarehousePositionForm
  from "./component/Enum/Warehouse/Position/EnumWarehousePositionForm"
import EnumWarehousePartList from "./component/Enum/Warehouse/Part/EnumWarehousePartList"
import EnumWarehousePartForm from "./component/Enum/Warehouse/Part/EnumWarehousePartForm"
import WarehouseList from "./component/Warehouse/WarehouseList"
import WarehouseForm from "./component/Warehouse/WarehouseForm"
import NotificationList from "./component/Notification/NotificationList"
import NotificationProvider from "./component/Notification/NotificationProvider"
import Footer from "./component/Footer/Footer"
import TaskReportDetail from "./component/Task/Report/Detail/TaskReportDetail"
import TaskReportRemind from "./component/Task/Report/Remind/Remind"
import TaskManagementList from "./component/Task/Management/TaskManagementList"
import TaskManagementForm from "./component/Task/Management/TaskManagementForm"
import TaskManagementFinishForm from "./component/Task/Management/TaskManagementFinishForm"
import TaskManagementDetail from "./component/Task/Management/Detail/TaskManagementDetail"
import TaskManagementRemind from "./component/Task/Management/Remind/Remind"
import ReportTasks from "./component/Report/ReportTasks/ReportTasks"
import Changelog from "./component/Changelog/Changelog"
import TaskRelated from "./component/Task/Related"
import TaskPreventionList from "./component/Task/TaskPreventionList"
import TaskPreventionBulk from "./component/Task/Prevention/Bulk"
import TaskPreventionBulkSubtaskForm from "./component/Task/Prevention/Bulk/TaskPreventionBulkSubtaskForm"
import TaskPreventionBulkStateForm from "./component/Task/Prevention/Bulk/TaskPreventionBulkStateForm"
import GWPList from "./component/GWP/GWPList"
import GWPForm from "./component/GWP/GWPForm"
import GFPList from "./component/GFP/GFPList"
import GFPForm from "./component/GFP/GFPForm"
import GFPSubTaskForm from "./component/GFP/GFPSubTaskForm"
import { Colors } from "./component/Colors"
import { ChangePasswordForm } from "./component/Profile/ChangePassword"
import { useQueryClient } from "react-query"
import VZVSubTaskForm from "./component/VZV/VZVSubTaskForm"
import VZVForm from "./component/VZV/VZVForm"
import VZVList from "./component/VZV/VZVList"
import EnumTaskDefectList from "./component/Enum/TaskDefect/TaskDefectList"
import EnumTaskDefectForm from "./component/Enum/TaskDefect/TaskDefectForm"
import EnumTaskAmountList from "./component/Enum/TaskAmount/TaskAmountList"
import EnumTaskAmountForm from "./component/Enum/TaskAmount/TaskAmountForm"
import EnumTaskProcessList from "./component/Enum/TaskProcess/TaskProcessList"
import EnumTaskProcessForm from "./component/Enum/TaskProcess/TaskProcessForm"
import EnumTaskUnitList from "./component/Enum/TaskUnit/TaskUnitList"
import EnumTaskUnitForm from "./component/Enum/TaskUnit/TaskUnitForm"
import { TaskReportProcessForm } from "./component/Task/Report/Detail/TaskReportProcessForm"
import GFPOnceForm from "./component/GFP/GFPOnceForm"

export default function App() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { search, pathname } = useLocation()
  const [user, setUser] = useState({})
  const [isAuthenticatedEndpointCalled, setIsAuthenticatedEndpointCalled] = useState(false)

  const login = ({ user, pass }) => api
    .post("/login", { user, pass })
    .then(({ data }) => {
      api.setToken(data.data.token)
      setUser(data.data)

      let split = search.split("backlink=")

      if (split.length === 2) {
        window.location.href = decodeURIComponent(split[1])
      } else {
        history.goBack()
      }
    })

  const logout = useCallback(async () => {
    api.removeToken()
    setUser({})
    history.push("/login")
    await api.get("/logout")
  }, [history])

  const hasRole = (name) => user.role === name

  const hasPerm = (name) => user.permissions && user.permissions.indexOf(name) > -1

  const isAdmin = () => hasRole("ADMIN") || hasRole("SUPERADMIN")

  useEffect(() => {
    const queryCache = queryClient.getQueryCache()
    const mutationCache = queryClient.getMutationCache()

    const onError = (response) => {
      if (
        response.config.url !== "/is-authenticated" &&
        // response.config.url !== "/login" &&
        response.config.url !== "/logout" &&
        response &&
        response.data &&
        response.data.error &&
        response.data.error.description
      ) {
        alert(response.data.error.description)
      }
    }

    api.interceptors.response.use((response) => {
      onError(response)
      return response
    }, (error) => {
      if (
        error.response.status === 401 &&
        error.response.config.url !== "/login" &&
        error.response.config.url !== "/logout"
      ) {
        logout()
      } else {
        onError(error.response)
      }
      return Promise.reject(error)
    })

    queryCache.config.onError = onError
    mutationCache.config.onError = onError
  }, [logout, queryClient])

  useEffect(() => {
    api.get("/is-authenticated")
      .then(({ data }) => setUser(data.data))
      // .catch(() => history.push(`/login${search || ""}`))
      .finally(() => setIsAuthenticatedEndpointCalled(true))
  }, [])

  useEffect(() => {
    if (user.uuid && pathname === "/login") {
      history.push("/")
    }
  }, [pathname, history, user.uuid])

  if (!isAuthenticatedEndpointCalled) {
    return (
      <ProgressSpinner style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translateX(-50%) translateY(-50%)",
      }} />
    )
  }

  return (
    <userContext.Provider value={{ user, login, logout, hasRole, hasPerm, isAdmin }}>
      <div className={"grid"}>
        {user.uuid && (
          <>
            <NotificationProvider>
              <NotificationList />
              <Navbar />
            </NotificationProvider>
            <Breadcrumb />
          </>
        )}

        <Switch>
          {/* misc */}
          <Route path={"/login"} exact={true}>
            <Auth />
          </Route>
          <Route path={"/changelog"} exact={true}>
            <Changelog />
          </Route>
          <Route path={"/profile/change-password"} exact={true}>
            <ChangePasswordForm />
          </Route>

          {/* tasks */}
          <Route path={"/tasks/reports"} exact={true}>
            <TaskReportList />
          </Route>
          <Route path={"/tasks/preventions"} exact={true}>
            <TaskPreventionList />
          </Route>
          <Route path={"/tasks/machines"} exact={true}>
            <TaskMachineList />
          </Route>
          <Route path={"/task/prevention/bulk"} exact={true}>
            <TaskPreventionBulk />
          </Route>
          <Route path={"/task/prevention/bulk/subtasks"} exact={true}>
            <TaskPreventionBulkSubtaskForm />
          </Route>
          <Route path={"/task/prevention/bulk/states"} exact={true}>
            <TaskPreventionBulkStateForm />
          </Route>
          <Route path={"/task/prevention/:uuid/subtasks"} exact={true}>
            <TaskPreventionSubTaskForm />
          </Route>
          <Route path={"/task/prevention/:uuid"} exact={true}>
            <TaskPreventionForm />
          </Route>
          <Route path={"/task/prevention"} exact={true}>
            <TaskPreventionForm />
          </Route>
          <Route path={"/task/report/:uuid/finish/:isPossible"} exact={true}>
            <TaskReportFinishForm />
          </Route>
          <Route path={"/task/report/:uuid/detail"} exact={true}>
            <TaskReportDetail />
          </Route>
          <Route path={"/task/report/:uuid/remind"} exact={true}>
            <TaskReportRemind />
          </Route>
          <Route path={"/task/report/:uuid/process"} exact={true}>
            <TaskReportProcessForm />
          </Route>
          <Route path={"/task/report/:uuid"} exact={true}>
            <TaskReportForm />
          </Route>
          <Route path={"/task/report/management/:managementUuid"} exact={true}>
            <TaskReportForm />
          </Route>
          <Route path={"/task/report/subtask/:parentUuid"} exact={true}>
            <TaskReportForm />
          </Route>
          <Route path={"/task/report"} exact={true}>
            <TaskReportForm />
          </Route>
          <Route path={"/task/:uuid/related/:type"}>
            <TaskRelated />
          </Route>

          {/* management tasks */}
          <Route path={"/task/management/:uuid/finish/:isPossible"} exact={true}>
            <TaskManagementFinishForm />
          </Route>
          <Route path={"/task/management/:uuid/detail"} exact={true}>
            <TaskManagementDetail />
          </Route>
          <Route path={"/task/management/:uuid/remind"} exact={true}>
            <TaskManagementRemind />
          </Route>
          <Route path={"/task/management/:uuid"} exact={true}>
            <TaskManagementForm />
          </Route>
          <Route path={"/task/management"} exact={true}>
            <TaskManagementForm />
          </Route>
          <Route path={"/tasks/management"} exact={true}>
            <TaskManagementList />
          </Route>

          {/* GWP */}
          <Route path={"/gwp/:uuid"} exact={true}>
            <GWPForm />
          </Route>
          <Route path={"/gwp"} exact={true}>
            <GWPForm />
          </Route>
          <Route path={"/gwps"} exact={true}>
            <GWPList />
          </Route>

          {/* GFP */}
          <Route path="/gfp/once" exact={true}>
            <GFPOnceForm />
          </Route>
          <Route path={"/gfp/:uuid/subtasks"} exact={true}>
            <GFPSubTaskForm />
          </Route>
          <Route path={"/gfp/:uuid"} exact={true}>
            <GFPForm />
          </Route>
          <Route path={"/gfp"} exact={true}>
            <GFPForm />
          </Route>
          <Route path={"/gfps"} exact={true}>
            <GFPList />
          </Route>

          {/* VZV */}
          <Route path={"/vzv/:uuid/subtasks"} exact={true}>
            <VZVSubTaskForm />
          </Route>
          <Route path={"/vzv/:uuid"} exact={true}>
            <VZVForm />
          </Route>
          <Route path={"/vzv"} exact={true}>
            <VZVForm />
          </Route>
          <Route path={"/vzvs"} exact={true}>
            <VZVList />
          </Route>

          {/* enum machines */}
          <Route path={"/enum/machines"} exact={true}>
            <EnumMachineList />
          </Route>
          <Route path={"/enum/machine/:uuid"} exact={true}>
            <EnumMachineForm />
          </Route>
          <Route path={"/enum/machine"} exact={true}>
            <EnumMachineForm />
          </Route>

          {/* enum machine types */}
          <Route path={"/enum/machine-types"} exact={true}>
            <EnumMachineTypeList />
          </Route>
          <Route path={"/enum/machine-type/:uuid"} exact={true}>
            <EnumMachineTypeForm />
          </Route>
          <Route path={"/enum/machine-type"} exact={true}>
            <EnumMachineTypeForm />
          </Route>

          {/* enum users */}
          <Route path={"/enum/users"} exact={true}>
            <EnumUserList />
          </Route>
          <Route path={"/enum/user/:uuid"} exact={true}>
            <EnumUserForm />
          </Route>
          <Route path={"/enum/user"} exact={true}>
            <EnumUserForm />
          </Route>

          {/* enum teams */}
          <Route path={"/enum/teams"} exact={true}>
            <EnumTeamList />
          </Route>
          <Route path={"/enum/team/:uuid"} exact={true}>
            <EnumTeamForm />
          </Route>
          <Route path={"/enum/team"} exact={true}>
            <EnumTeamForm />
          </Route>

          {/* enum operations */}
          <Route path={"/enum/operations"} exact={true}>
            <EnumOperationList />
          </Route>
          <Route path={"/enum/operation/:uuid"} exact={true}>
            <EnumOperationForm />
          </Route>
          <Route path={"/enum/operation"} exact={true}>
            <EnumOperationForm />
          </Route>

          {/* enum places */}
          <Route path={"/enum/places"} exact={true}>
            <EnumPlaceList />
          </Route>
          <Route path={"/enum/place/:uuid"} exact={true}>
            <EnumPlaceForm />
          </Route>
          <Route path={"/enum/place"} exact={true}>
            <EnumPlaceForm />
          </Route>

          {/* enum suppliers */}
          <Route path={"/enum/warehouse/suppliers"} exact={true}>
            <EnumWarehouseSupplierList />
          </Route>
          <Route path={"/enum/warehouse/supplier/:uuid"} exact={true}>
            <EnumWarehouseSupplierForm />
          </Route>
          <Route path={"/enum/warehouse/supplier"} exact={true}>
            <EnumWarehouseSupplierForm />
          </Route>

          {/* enum task tags */}
          <Route path={"/enum/task-tags"} exact={true}>
            <EnumTaskTagList />
          </Route>
          <Route path={"/enum/task-tag/:uuid"} exact={true}>
            <EnumTaskTagForm />
          </Route>
          <Route path={"/enum/task-tag"} exact={true}>
            <EnumTaskTagForm />
          </Route>

          {/* enum warehouse warehouse */}
          <Route path={"/enum/warehouse/warehouses"}>
            <EnumWarehouseWarehouseList />
          </Route>
          <Route path={"/enum/warehouse/warehouse/:uuid"}>
            <EnumWarehouseWarehouseForm />
          </Route>
          <Route path={"/enum/warehouse/warehouse"}>
            <EnumWarehouseWarehouseForm />
          </Route>

          {/* enum warehouse category */}
          <Route path={"/enum/warehouse/categories"}>
            <EnumWarehouseCategoryList />
          </Route>
          <Route path={"/enum/warehouse/category/:uuid"}>
            <EnumWarehouseCategoryForm />
          </Route>
          <Route path={"/enum/warehouse/category"}>
            <EnumWarehouseCategoryForm />
          </Route>

          {/* enum warehouse position */}
          <Route path={"/enum/warehouse/positions"}>
            <EnumWarehousePositionList />
          </Route>
          <Route path={"/enum/warehouse/position/:uuid"}>
            <EnumWarehousePositionForm />
          </Route>
          <Route path={"/enum/warehouse/position"}>
            <EnumWarehousePositionForm />
          </Route>

          {/* enum warehouse part */}
          <Route path={"/enum/warehouse/parts"}>
            <EnumWarehousePartList />
          </Route>
          <Route path={"/enum/warehouse/part/:uuid"}>
            <EnumWarehousePartForm />
          </Route>
          <Route path={"/enum/warehouse/part"}>
            <EnumWarehousePartForm />
          </Route>

          {/* enum task defect */}
          <Route path={"/enum/task-defects"}>
            <EnumTaskDefectList />
          </Route>
          <Route path={"/enum/task-defect/:uuid"}>
            <EnumTaskDefectForm />
          </Route>
          <Route path={"/enum/task-defect"}>
            <EnumTaskDefectForm />
          </Route>

          {/* enum task amount */}
          <Route path={"/enum/task-amounts"}>
            <EnumTaskAmountList />
          </Route>
          <Route path={"/enum/task-amount/:uuid"}>
            <EnumTaskAmountForm />
          </Route>
          <Route path={"/enum/task-amount"}>
            <EnumTaskAmountForm />
          </Route>

          {/* enum task process */}
          <Route path={"/enum/task-processes"}>
            <EnumTaskProcessList />
          </Route>
          <Route path={"/enum/task-process/:uuid"}>
            <EnumTaskProcessForm />
          </Route>
          <Route path={"/enum/task-process"}>
            <EnumTaskProcessForm />
          </Route>

          {/* enum task process */}
          <Route path={"/enum/task-units"}>
            <EnumTaskUnitList />
          </Route>
          <Route path={"/enum/task-unit/:uuid"}>
            <EnumTaskUnitForm />
          </Route>
          <Route path={"/enum/task-unit"}>
            <EnumTaskUnitForm />
          </Route>

          {/* warehouse */}
          <Route path={"/warehouses"} exact={true}>
            <WarehouseList />
          </Route>
          <Route path={"/warehouse/:uuid"} exact={true}>
            <WarehouseForm />
          </Route>
          <Route path={"/warehouse"} exact={true}>
            <WarehouseForm />
          </Route>

          {/* reports */}
          <Route path={"/report/tasks"} exact={true}>
            <ReportTasks />
          </Route>

          <Route path={"/colors"} exact={true}>
            <Colors />
          </Route>

          <Route path={"/"} exact={true}>
            <Homepage />
          </Route>
        </Switch>
      </div>

      <Footer />
    </userContext.Provider>
  )
}
