import { Dropdown } from "primereact/dropdown"
import { useTeams } from "../api/queries/useTeams"

export default function TeamFilter(props) {
  const { data: teams = [] } = useTeams()

  return (
    <Dropdown options={teams} value={props.value} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Tým"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
