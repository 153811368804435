import {useEffect, useState} from "react"
import {useImmer} from "use-immer"
import notificationContext from "../../context/notificationContext"
import api from "../../api"

const thirtySeconds = 30000

export default function NotificationProvider({children}) {
  const [notifications, setNotifications] = useImmer([])
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const read = (uuid) => api
    .patch(`/notifications/${uuid}/read`)
    .then(refresh)

  const readAll = () => api
    .patch(`/notifications/allread`)
    .then(refresh)

  useEffect(() => {
    const getNotifications = () => api
      .get("/notifications")
      .then(({data}) => setNotifications(data.data))

    const intervalId = setInterval(getNotifications, thirtySeconds)

    getNotifications()

    return () => clearInterval(intervalId)
  }, [setNotifications, refresher])

  return (
    <notificationContext.Provider value={{notifications, toggle, isVisible, read, readAll}}>
      {children}
    </notificationContext.Provider>
  )
}
