import { NavLink } from "react-router-dom"
import styles from "../../../Homepage/styles.module.css"

export default function TaskPreventionBulk() {
  return (
    <>
      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={"/task/prevention/bulk/subtasks"}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              Změna podúkolů
            </div>
          </div>
        </NavLink>
      </div>

      <div className={"col-12 md:col-3 text-center"}>
        <NavLink to={"/task/prevention/bulk/states"}
          className={`block p-card p-component ${styles.bgBlue}`}>
          <div className={"p-card-body"}>
            <div className={"p-card-content"}>
              Změna stavu
            </div>
          </div>
        </NavLink>
      </div>
    </>
  )
}