import { useEffect, useState } from "react"
import api from "../api"

export default function useParentUsers(user, operation) {
  const [users, setUsers] = useState([])

  let params = `?operation=${operation || (user.operation ? user.operation.uuid : "")}`

  if (user.role === "USER") {
    params += `&role=ADMIN`
  }

  useEffect(() => {
    api.get(`/users${params}`)
      .then(({ data }) => setUsers(data.data))
  }, [params])

  return users
}
