const regUUID = "[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}"
const CRUMB = {
  HP: {label: "Domovská stránka", url: "/"},
  CHANGE_PASSWORD: {label: "Změnit heslo", url: "/profile/change-password"},
  CHANGELOG: {label: "Přehled změn", url: "/changelog"},
  COLORS: {label: "Legenda barev", url: "/colors"},
  TASK: {
    LIST: {label: "Úkoly", url: "/tasks"}, // TODO
    MACHINE: {
      LIST: {label: "Stroje", url: "/tasks/machines"}
    },
    PREVENTION: {
      LIST: {label: "Přehled pravidelné údržby", url: "/tasks/preventions"},
      BULK: {label: "Hromadné akce", url: "/task/prevention/bulk"},
      BULK_SUBTASKS: {label: "Změna podúkolů", url: "/task/prevention/bulk/subtasks"},
      BULK_STATES: {label: "Změna stavu", url: "/task/prevention/bulk/states"},
      NEW: {label: "Pravidelná údržba", url: "/task/prevention"},
      DETAIL: {label: "Pravidelná údržba", url: "/task/prevention/{uuid}"},
      DETAIL_TASKS: {label: "Podúkoly", url: "/task/prevention/{uuid}/subtasks"},
      RELATED_ALL: {label: "Související hlášení", url: "/task/{uuid}/related/all"},
      RELATED_FINISHED: {label: "Související hlášení", url: "/task/{uuid}/related/finished"},
    },
    REPORT: {
      LIST: {label: "Přehled hlášení", url: "/tasks/reports"},
      NEW: {label: "Hlášení", url: "/task/report"},
      DETAIL: {label: "Hlášení", url: "/task/report/{uuid}/detail"},
      FORM: {label: "Formulář", url: "/task/report/{uuid}"},
      DETAIL_FINISH: {label: "Dokončit", url: "/task/report/{uuid}/finish/1"},
      DETAIL_NOT_FINISH: {label: "Nelze dokončit", url: "/task/report/{uuid}/finish/0"},
    },
    MANAGEMENT: {
      LIST: {label: "Management", url: "/tasks/management"},
      NEW: {label: "Hlášení", url: "/task/management"},
      DETAIL: {label: "Hlášení", url: "/task/management/{uuid}/detail"},
      FORM: {label: "Formulář", url: "/task/management/{uuid}"},
      DETAIL_FINISH: {label: "Dokončit", url: "/task/management/{uuid}/finish/1"},
      DETAIL_NOT_FINISH: {label: "Nelze dokončit", url: "/task/management/{uuid}/finish/0"},
    }
  },
  GFP: {
    LIST: {label: "GFP", url: "/gfps"},
    NEW: {label: "Nové GFP", url: "/gfp"},
    ONCE: {label: "Mimořádné GFP", url: "/gfp/once"},
    DETAIL: {label: "Detail", url: "/gfp/{uuid}"},
    DETAIL_TASKS: {label: "Podúkoly", url: "/gfp/{uuid}/subtasks"}
  },
  VZV: {
    LIST: {label: "VZV", url: "/vzvs"},
    NEW: {label: "Nové VZV", url: "/vzv"},
    DETAIL: {label: "Detail", url: "/vzv/{uuid}"},
    DETAIL_TASKS: {label: "Podúkoly", url: "/vzv/{uuid}/subtasks"}
  },
  WAREHOUSE: {
    LIST: {label: "Sklad", url: "/warehouses"},
    NEW: {label: "Naskladnit díl", url: "/warehouse"},
    DETAIL: {label: "Naskladněný díl", url: "/warehouse/{uuid}"},
  },
  GWP: {
    LIST: {label:  "GWP", url: "/gwps"},
    NEW: {label: "Nové GWP", url: "/gwp"},
    DETAIL: {label: "Detail GWP", url: "/gwp/{uuid}"},
  },
  ENUM: {
    LIST: {label: "Nastavení", url: "/enums"}, // TODO
    MACHINE: {
      LIST: {label: "Stroje", url: "/enum/machines"},
      NEW: {label: "Stroj", url: "/enum/machine"},
      DETAIL: {label: "Stroj", url: "/enum/machine/{uuid}"},
    },
    MACHINE_TYPE: {
      LIST: {label: "Typy strojů", url: "/enum/machine-types"},
      NEW: {label: "Typ stroje", url: "/enum/machine-type"},
      DETAIL: {label: "Typ stroje", url: "/enum/machine-type/{uuid}"},
    },
    USER: {
      LIST: {label: "Pracovníci", url: "/enum/users"},
      NEW: {label: "Pracovník", url: "/enum/user"},
      DETAIL: {label: "Pracovník", url: "/enum/user/{uuid}"},
    },
    TEAM: {
      LIST: {label: "Týmy", url: "/task/prevention"},
      NEW: {label: "Tým", url: "/enum/team"},
      DETAIL: {label: "Tým", url: "/enum/team/{uuid}"},
    },
    OPERATION: {
      LIST: {label: "Provozy", url: "/enum/operations"},
      NEW: {label: "Provoz", url: "/enum/operation"},
      DETAIL: {label: "Provoz", url: "/task/operation/{uuid}"},
    },
    PLACE: {
      LIST: {label: "Místa", url: "/enum/places"},
      NEW: {label: "Místo", url: "/enum/place"},
      DETAIL: {label: "Místo", url: "/enum/place/{uuid}"},
    },
    TASK_TAG: {
      LIST: {label: "Typy úkolů", url: "/enum/task-tags"},
      NEW: {label: "Typ úkolu", url: "/enum/task-tag"},
      DETAIL: {label: "Typ úkolu", url: "/enum/task-tag/{uuid}"},
    },
    WAREHOUSE: {
      LIST: {label: "Sklad", url: "/enum/warehouse"}, // TODO
      SUPPLIER: {
        LIST: {label: "Dodavatelé", url: "/enum/warehouse/suppliers"},
        NEW: {label: "Dodavatel", url: "/enum/warehouse/supplier"},
        DETAIL: {label: "Dodavatel", url: "/enum/warehouse/supplier/{uuid}"},
      },
      WAREHOUSE: {
        LIST: {label: "Seznam skladů", url: "/enum/warehouse/warehouses"},
        NEW: {label: "Sklad", url: "/enum/warehouse/warehouse"},
        DETAIL: {label: "Sklad", url: "/enum/warehouse/warehouse/{uuid}"},
      },
      CATEGORY: {
        LIST: {label: "Kategorie", url: "/enum/warehouse/categories"},
        NEW: {label: "Kategorie", url: "/enum/warehouse/category"},
        DETAIL: {label: "Kategorie", url: "/enum/warehouse/category/{uuid}"},
      },
      POSITION: {
        LIST: {label: "Umístění", url: "/enum/warehouse/positions"},
        NEW: {label: "Umístění", url: "/enum/warehouse/position"},
        DETAIL: {label: "Umístění", url: "/enum/warehouse/position/{uuid}"},
      },
      PART: {
        LIST: {label: "Díly", url: "/enum/warehouse/parts"},
        NEW: {label: "Díl", url: "/enum/warehouse/part"},
        DETAIL: {label: "Díl", url: "/enum/warehouse/part/{uuid}"},
      },
    },
    TASK_DEFECT: {
      LIST: {label: "Vady", url: "/enum/task-defects"},
      NEW: {label: "Vada", url: "/enum/task-defect"},
      DETAIL: {label: "Vada", url: "/enum/task-defect/{uuid}"},
    },
    TASK_AMOUNT: {
      LIST: {label: "Množství", url: "/enum/task-amounts"},
      NEW: {label: "Množství", url: "/enum/task-amount"},
      DETAIL: {label: "Množství", url: "/enum/task-amount/{uuid}"},
    },
    TASK_PROCESS: {
      LIST: {label: "Postupy", url: "/enum/task-processes"},
      NEW: {label: "Postup", url: "/enum/task-process"},
      DETAIL: {label: "Postup", url: "/enum/task-process/{uuid}"},
    },
    TASK_UNIT: {
      LIST: {label: "Jednotky", url: "/enum/task-units"},
      NEW: {label: "Jednotka", url: "/enum/task-unit"},
      DETAIL: {label: "Jednotka", url: "/enum/task-unit/{uuid}"},
    },
  },
  MANAGEMENT: {
    REPORT: {
      LIST: {label: "Report", url: "/report"}, // TODO
      PREVENTION: {label: "Pravidelná údržba", url: "/report/tasks"}
    }
  }
}

const urls = {
  [`/`]: [CRUMB.HP],
  [`/profile/change-password`]: [CRUMB.CHANGE_PASSWORD],
  [`/changelog`]: [CRUMB.CHANGELOG],
  [`/colors`]: [CRUMB.COLORS],
  [`/tasks/preventions`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST],
  [`/tasks/reports`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST],
  [`/tasks/machines`]: [CRUMB.TASK.LIST, CRUMB.TASK.MACHINE.LIST],
  [`/task/prevention`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.NEW],
  [`/task/prevention/bulk`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.BULK],
  [`/task/prevention/bulk/subtasks`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.BULK, CRUMB.TASK.PREVENTION.BULK_SUBTASKS],
  [`/task/prevention/bulk/states`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.BULK, CRUMB.TASK.PREVENTION.BULK_STATES],
  [`/task/prevention/${regUUID}`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.DETAIL],
  [`/task/prevention/${regUUID}/subtasks`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.DETAIL_TASKS],
  [`/task/report`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.NEW],
  [`/task/report/subtask/${regUUID}`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.NEW],
  [`/task/report/${regUUID}/detail`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.DETAIL],
  [`/task/report/${regUUID}`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.DETAIL, CRUMB.TASK.REPORT.FORM],
  [`/task/report/management/${regUUID}`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.FORM],
  [`/task/report/${regUUID}/finish/0`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.DETAIL, CRUMB.TASK.REPORT.DETAIL_NOT_FINISH],
  [`/task/report/${regUUID}/finish/1`]: [CRUMB.TASK.LIST, CRUMB.TASK.REPORT.LIST, CRUMB.TASK.REPORT.DETAIL, CRUMB.TASK.REPORT.DETAIL_FINISH],
  [`/task/${regUUID}/related/finished`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.DETAIL, CRUMB.TASK.PREVENTION.RELATED_FINISHED],
  [`/task/${regUUID}/related/all`]: [CRUMB.TASK.LIST, CRUMB.TASK.PREVENTION.LIST, CRUMB.TASK.PREVENTION.DETAIL, CRUMB.TASK.PREVENTION.RELATED_ALL],
  [`/tasks/management`]: [CRUMB.TASK.MANAGEMENT.LIST],
  [`/task/management`]: [CRUMB.TASK.MANAGEMENT.LIST, CRUMB.TASK.MANAGEMENT.NEW],
  [`/task/management/${regUUID}/detail`]: [CRUMB.TASK.MANAGEMENT.LIST, CRUMB.TASK.MANAGEMENT.DETAIL],
  [`/task/management/${regUUID}`]: [CRUMB.TASK.MANAGEMENT.LIST, CRUMB.TASK.MANAGEMENT.DETAIL, CRUMB.TASK.MANAGEMENT.FORM],
  [`/task/management/${regUUID}/finish/0`]: [CRUMB.TASK.MANAGEMENT.LIST, CRUMB.TASK.MANAGEMENT.DETAIL, CRUMB.TASK.MANAGEMENT.DETAIL_NOT_FINISH],
  [`/task/management/${regUUID}/finish/1`]: [CRUMB.TASK.MANAGEMENT.LIST, CRUMB.TASK.MANAGEMENT.DETAIL, CRUMB.TASK.MANAGEMENT.DETAIL_FINISH],
  [`/gfps`]: [CRUMB.GFP.LIST],
  [`/gfp`]: [CRUMB.GFP.LIST, CRUMB.GFP.NEW],
  [`/gfp/once`]: [CRUMB.GFP.LIST, CRUMB.GFP.ONCE],
  [`/gfp/${regUUID}`]: [CRUMB.GFP.LIST, CRUMB.GFP.DETAIL],
  [`/gfp/${regUUID}/subtasks`]: [CRUMB.GFP.LIST, CRUMB.GFP.DETAIL, CRUMB.GFP.DETAIL_TASKS],
  [`/vzvs`]: [CRUMB.VZV.LIST],
  [`/vzv`]: [CRUMB.VZV.LIST, CRUMB.VZV.NEW],
  [`/vzv/${regUUID}`]: [CRUMB.VZV.LIST, CRUMB.VZV.DETAIL],
  [`/vzv/${regUUID}/subtasks`]: [CRUMB.VZV.LIST, CRUMB.VZV.DETAIL, CRUMB.VZV.DETAIL_TASKS],
  [`/warehouses`]: [CRUMB.WAREHOUSE.LIST],
  [`/warehouse`]: [CRUMB.WAREHOUSE.LIST, CRUMB.WAREHOUSE.NEW],
  [`/warehouse/${regUUID}`]: [CRUMB.WAREHOUSE.LIST, CRUMB.WAREHOUSE.DETAIL],
  [`/gwps`]:[CRUMB.GWP.LIST],
  [`/gwp`]:[CRUMB.GWP.LIST, CRUMB.GWP.NEW],
  [`/gwp/${regUUID}`]:[CRUMB.GWP.LIST, CRUMB.GWP.DETAIL],
  [`/enum/machines`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.MACHINE.LIST],
  [`/enum/machine`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.MACHINE.LIST, CRUMB.ENUM.MACHINE.NEW],
  [`/enum/machine/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.MACHINE.LIST, CRUMB.ENUM.MACHINE.DETAIL],
  [`/enum/machine-types`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.MACHINE_TYPE.LIST],
  [`/enum/machine-type`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.MACHINE_TYPE.LIST, CRUMB.ENUM.MACHINE_TYPE.NEW],
  [`/enum/machine-type/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.MACHINE_TYPE.LIST, CRUMB.ENUM.MACHINE_TYPE.DETAIL],
  [`/enum/users`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.USER.LIST],
  [`/enum/user`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.USER.LIST, CRUMB.ENUM.USER.NEW],
  [`/enum/user/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.USER.LIST, CRUMB.ENUM.USER.DETAIL],
  [`/enum/teams`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TEAM.LIST],
  [`/enum/team`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TEAM.LIST, CRUMB.ENUM.TEAM.NEW],
  [`/enum/team/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TEAM.LIST, CRUMB.ENUM.TEAM.DETAIL],
  [`/enum/operations`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.OPERATION.LIST],
  [`/enum/operation`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.OPERATION.LIST, CRUMB.ENUM.OPERATION.NEW],
  [`/enum/operation/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.OPERATION.LIST, CRUMB.ENUM.OPERATION.DETAIL],
  [`/enum/places`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.PLACE.LIST],
  [`/enum/place`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.PLACE.LIST, CRUMB.ENUM.PLACE.NEW],
  [`/enum/place/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.PLACE.LIST, CRUMB.ENUM.PLACE.DETAIL],
  [`/enum/task-tags`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_TAG.LIST],
  [`/enum/task-tag`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_TAG.LIST, CRUMB.ENUM.TASK_TAG.NEW],
  [`/enum/task-tag/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_TAG.LIST, CRUMB.ENUM.TASK_TAG.DETAIL],
  [`/enum/warehouse/suppliers`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.SUPPLIER.LIST],
  [`/enum/warehouse/supplier`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.SUPPLIER.LIST, CRUMB.ENUM.WAREHOUSE.SUPPLIER.NEW],
  [`/enum/warehouse/supplier/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.SUPPLIER.LIST, CRUMB.ENUM.WAREHOUSE.SUPPLIER.DETAIL],
  [`/enum/warehouse/warehouses`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.WAREHOUSE.LIST],
  [`/enum/warehouse/warehouse`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.WAREHOUSE.NEW],
  [`/enum/warehouse/warehouse/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.WAREHOUSE.DETAIL],
  [`/enum/warehouse/categories`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.CATEGORY.LIST],
  [`/enum/warehouse/category`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.CATEGORY.LIST, CRUMB.ENUM.WAREHOUSE.CATEGORY.NEW],
  [`/enum/warehouse/category/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.CATEGORY.LIST, CRUMB.ENUM.WAREHOUSE.CATEGORY.DETAIL],
  [`/enum/warehouse/positions`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.POSITION.LIST],
  [`/enum/warehouse/position`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.POSITION.LIST, CRUMB.ENUM.WAREHOUSE.POSITION.NEW],
  [`/enum/warehouse/position/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.POSITION.LIST, CRUMB.ENUM.WAREHOUSE.POSITION.DETAIL],
  [`/enum/warehouse/parts`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.PART.LIST],
  [`/enum/warehouse/part`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.PART.LIST, CRUMB.ENUM.WAREHOUSE.PART.NEW],
  [`/enum/warehouse/part/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.WAREHOUSE.LIST, CRUMB.ENUM.WAREHOUSE.PART.LIST, CRUMB.ENUM.WAREHOUSE.PART.DETAIL],
  [`/report/tasks`]: [CRUMB.MANAGEMENT.REPORT.LIST, CRUMB.MANAGEMENT.REPORT.PREVENTION],
  [`/enum/task-defects`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_DEFECT.LIST],
  [`/enum/task-defect`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_DEFECT.LIST, CRUMB.ENUM.TASK_DEFECT.NEW],
  [`/enum/task-defect/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_DEFECT.LIST, CRUMB.ENUM.TASK_DEFECT.DETAIL],
  [`/enum/task-amounts`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_AMOUNT.LIST],
  [`/enum/task-amount`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_AMOUNT.LIST, CRUMB.ENUM.TASK_AMOUNT.NEW],
  [`/enum/task-amount/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_AMOUNT.LIST, CRUMB.ENUM.TASK_AMOUNT.DETAIL],
  [`/enum/task-processes`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_PROCESS.LIST],
  [`/enum/task-process`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_PROCESS.LIST, CRUMB.ENUM.TASK_PROCESS.NEW],
  [`/enum/task-process/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_PROCESS.LIST, CRUMB.ENUM.TASK_PROCESS.DETAIL],
  [`/enum/task-units`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_UNIT.LIST],
  [`/enum/task-unit`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_UNIT.LIST, CRUMB.ENUM.TASK_UNIT.NEW],
  [`/enum/task-unit/${regUUID}`]: [CRUMB.ENUM.LIST, CRUMB.ENUM.TASK_UNIT.LIST, CRUMB.ENUM.TASK_UNIT.DETAIL],
}

export {
  urls,
  regUUID,
}
