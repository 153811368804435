import { Tooltip } from "primereact/tooltip"
import { v4 as uuid } from "uuid"

export const NotFinishedInfo = () => {
  const id = uuid()

  return (
    <>
      <Tooltip content={"Předchozí pravidelná údržba nebyla řádně dokončena."}
        target={`.nfi-${id}`} position="top" event="both" />

      <i className={`pi pi-exclamation-triangle text-pink-500 mx-1 nfi-${id}`} />
    </>
  )
}
