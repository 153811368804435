import { NavLink } from "react-router-dom"

export const formatMachineName = (machine, showMachineLink = false) => {
  let machineInfo = []

  if (machine.type) {
    machineInfo.push(machine.type.name)
  }

  if (machine.place) {
    machineInfo.push(machine.place.name)

    if (machine.place.operation) {
      machineInfo.push(machine.place.operation.name)
    }
  }

  if (machine.parent) {
    machineInfo.push(machine.parent.name)
  }

  const machineInfoStr = (machineInfo.length > 0 ? ` (${machineInfo.join(", ")})` : "")

  if (showMachineLink) {
    return (
      <>
        <NavLink to={`/enum/machine/${machine.uuid}`} className="text-700">
          {machine.name}
        </NavLink> {machineInfoStr}
      </>
    )
  }

  return machine.name + machineInfoStr
}
