import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, HashRouter } from "react-router-dom"
import { addLocale, locale } from "primereact/api"
import { QueryClientProvider } from "react-query"
// import { ReactQueryDevtools } from "react-query/devtools"
import App from "./App"
import { __DEV__, __TEST__ } from "./environment"
import { queryClient } from "./client"

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "core-js/es/symbol"
import "core-js/es/object/assign"
import "core-js/es/string/ends-with"
import "core-js/es/array/from"
import "core-js/es/set"

import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeflex/primeflex.css"
import "primeicons/primeicons.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "./global.css"
import "./datatable-responsive.global.css"
import "./datatable-collapsible.global.css"
import "./datatable-reorderable.global.css"
import "@fortawesome/fontawesome-free/css/all.css"

addLocale("cs", {
  firstDayOfWeek: 1,
  dayNames: ["neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota"],
  dayNamesShort: ["ne", "po", "út", "st", "čt", "pá", "so"],
  dayNamesMin: ["ne", "po", "út", "st", "čt", "pá", "so"],
  monthNames: ["leden", "únor", "březen", "duben", "květen", "červen", "červenec", "srpen", "září",
    "říjen", "listopad", "prosinec"],
  monthNamesShort: ["led", "úno", "bře", "dub", "kvě", "čvn", "čvc", "srp", "zář", "říj", "lis",
    "pro"],
  today: "dnes",
  clear: "vymazat"
})
locale("cs")

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools /> */}
      
      {__DEV__ || __TEST__ ? (
        <HashRouter>
          <App />
        </HashRouter>
      ) : (
        <BrowserRouter>
          <App />
        </BrowserRouter>
      )}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
