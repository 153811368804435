import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { InputNumber } from 'primereact/inputnumber';
import { Button } from "primereact/button"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import backConfirmation from "../../../function/backConfirmation"
import { queryKeys } from "../../../client"
import { useQueryClient } from "react-query"


export default function EnumTaskTagForm() {
  const { uuid } = useParams()
  const back = () => window.history.back()
  const queryClient = useQueryClient()

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)

  const [abbr, setAbbr] = useState("")
  const handleAbbrChange = (e) => setAbbr(e.target.value)

  const [position, setPosition] = useState("")
  const handlePositionChange = (e) => setPosition(e.value)

  const create = () => api
    .put("/task-tags", { name, abbr, position })
    .then(back)

  const update = () => api
    .post(`/task-tags/${uuid}`, { uuid, name, abbr, position })
    .then(back)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.TASK_TAGS, { refetchInactive: true })
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/task-tags/${uuid}`)
        .then(({ data }) => {
          setName(data.data.name)
          setAbbr(data.data.abbr)
          setPosition(data.data.position)
        })
    }
  }, [uuid])

  return (
    <userContext.Consumer>
      {({ hasPerm }) => (
        <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
          <div className={"p-fluid"}>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Název:</label>
              <InputText required={true} value={name} onChange={handleNameChange} />
            </div>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Zkratka:</label>
              <InputText required={true} value={abbr} onChange={handleAbbrChange} minLength={1}
                maxLength={3} />
            </div>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Pozice:</label>
              <InputNumber required={true} value={position} onValueChange={handlePositionChange} min={1} max={999} useGrouping={false} />
            </div>
          </div>

          <div className={"flex"}>
            <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
              onClick={backConfirmation} />
            <div className={"ml-auto"}>
              <Button type={"submit"} label={"Uložit"} className={"m-1"}
                disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")} />
            </div>
          </div>
        </form>
      )}
    </userContext.Consumer>
  )
}
