import { useEffect, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import api from "../../../api"
import query from "../../../function/query"
import MachineFilter from "../../../filter/MachineFilter"
import MachineTypeFilter from "../../../filter/MachineTypeFilter"
import PlaceFilter from "../../../filter/PlaceFilter"
import OperationFilter from "../../../filter/OperationFilter"
import Tmpl from "../../../function/Tmpl"
import { storage } from "../../../storage"
import { InputSwitch } from "primereact/inputswitch"

export default function EnumMachineList() {
  const history = useHistory()
  const search = query.parse(useLocation().search)
  const [sortField, setSortField] = useState(search.sortField)
  const [sortOrder, setSortOrder] = useState(search.sortOrder)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }

  const [showDeleted, setShowDeleted] = useState(!!search.showDisabled || false)
  const handleShowDeletedChange = (e) => setShowDeleted(e.value)

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [machine, setMachine] = useState(search.parent || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = <MachineFilter value={machine} onChange={handleMachineChange} />

  const [machineType, setMachineType] = useState(search.type || null)
  const handleMachineTypeChange = (e) => setMachineType(e.target.value)
  const machineTypeFilter = <MachineTypeFilter value={machineType}
    onChange={handleMachineTypeChange} />

  const [place, setPlace] = useState(search.place || null)
  const handlePlaceChange = (e) => setPlace(e.target.value)
  const placeFilter = <PlaceFilter value={place} onChange={handlePlaceChange} />
  const placeTmpl = (row) => <Tmpl title={"Místo"} value={row.place.name} />

  const [operation, setOperation] = useState(search.operation || null)
  const handleOperationChange = (e) => setOperation(e.target.value)
  const operationFilter = <OperationFilter value={operation} onChange={handleOperationChange} />
  const operationTmpl = (row) => <Tmpl title={"Provoz"} value={row.place.operation.name} />

  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      {!row.active && <i className={"fas fa-trash-can mr-1 text-pink-500"} title={"Smazaný"} />}
      <NavLink to={`/enum/machine/${row.uuid}`}>{row.name}</NavLink>
    </Tmpl>
  )

  const gwpTmpl = (row) => (
    <Tmpl title={"GWP"}>
      {row.gwp.map((gwp) => gwp.name).join(", ")}
    </Tmpl>
  )

  const typeTmpl = (row) => <Tmpl title={"Typ"} value={row.type.name} />
  const parentTmpl = (row) => <Tmpl title={"Nadřazený stroj"}
    value={row.parent ? row.parent.name : ""} />

  const rentedTmpl = (row) => row.rented
    ? <i className="fa-solid fa-check" />
    : <i className="fa-solid fa-times" />

  const multiOperationTmpl = (row) => row.multioperation
    ? <i className="fa-solid fa-check" />
    : <i className="fa-solid fa-times" />

  useEffect(() => {
    setIsLoading(true)

    let params = {
      limit, offset, parent: machine,
      type: machineType, place, operation,
      sortField, sortOrder,
    }

    if (showDeleted) {
      params.showDisabled = 1
    }

    const queryParamsStr = query.toString(params)

    history.push({ search: queryParamsStr })

    api.get(`/machines${queryParamsStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setData(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [limit, offset, machine, machineType, place, operation, history, sortField, sortOrder,
    showDeleted])

  return (
    <>
      <div className={"col-12"}>
        <div className={"flex align-items-center"}>
          <NavLink to={"/enum/machine"} className={"p-button m-1"}>Nový</NavLink>

          <InputSwitch checked={showDeleted} onChange={handleShowDeletedChange}
            className={"ml-auto mr-1"} inputId={"showDeleted"} />
          <label htmlFor={"showDeleted"}>Zobrazit smazané</label>
        </div>
      </div>

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField} sortOrder={sortOrder}
          className={"p-datatable-sm"}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          <Column header={"Název"} body={nameTmpl} sortable={true} sortField={"name"} />
          <Column header={"GWP"} body={gwpTmpl} />
          <Column header={"Typ"} body={typeTmpl} filter={true} filterElement={machineTypeFilter}
            sortable={true} sortField={"type"} />
          <Column header={"Provoz"} body={operationTmpl} filter={true}
            filterElement={operationFilter} sortable={true} sortField={"operation"} />
          <Column header={"Místo"} body={placeTmpl} filter={true} filterElement={placeFilter}
            sortable={true} sortField={"place"} />
          <Column header={"Nadřazený stroj"} body={parentTmpl} filter={true}
            filterElement={machineFilter} />
          <Column header={"Vypůjčeno"} body={rentedTmpl} />
          <Column header={"Multiprovozní"} body={multiOperationTmpl} />
        </DataTable>
      </div>
    </>
  )
}
