import { Dropdown } from "primereact/dropdown"
import { useMachines } from "../api/queries/useMachines"

export default function MachineFilter(props) {
  const { data: machines = [] } = useMachines()

  return (
    <Dropdown options={machines} value={props.value} optionLabel={"name"} optionValue={"uuid"}
      showClear={true} placeholder={"Stroj"} onChange={props.onChange} filter={true} />
  )
}
