import { useEffect, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
// import { Badge } from "primereact/badge"
// import moment from "moment"
import api from "../../api"
import query from "../../function/query"
import Tmpl from "../../function/Tmpl"
import { storage } from "../../storage"
import { InputText } from "primereact/inputtext"
import MachineFilter from "../../filter/MachineFilter"
// import { Dropdown } from "primereact/dropdown"

export default function GWPList() {
  const history = useHistory()
  const search = query.parse(useLocation().search)
  const [sortField, setSortField] = useState(query.sortField || "createdAt")
  const [sortOrder, setSortOrder] = useState(query.sortOrder || -1)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      <NavLink to={`/gwp/${row.uuid}`}>{row.name}</NavLink>
    </Tmpl>
  )

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const fName = (
    <InputText value={name} onChange={handleNameChange} placeholder={"Fulltext"} />
  )

  const descriptionTmpl = (row) => (
    <Tmpl title={"Popis"}>
      {row.description}
    </Tmpl>
  )

  const machinesTmpl = (row) => (
    <Tmpl title={"Stroje"}>
      {row.machines && row.machines.map((machine) => machine.name).join(", ")}
    </Tmpl>
  )

  const [machine, setMachine] = useState(search.parent || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const fMachine = <MachineFilter value={machine} onChange={handleMachineChange} />

  // const isActiveTmpl = (row) => (
  //   <Tmpl title={"Aktiní"}>
  //     <Badge severity={row.is_active ? "success" : "danger"} />
  //   </Tmpl>
  // )
  // const [isActive, setIsActive] = useState(-1)
  // const handleIsActiveChange = (e) => setIsActive(e.value)
  // const activeOptions = [
  //   { value: -1, label: "Všechny" },
  //   { value: 0, label: "Zavřené" },
  //   { value: 1, label: "Aktivní" },
  // ]
  // const fActive = (
  //   <Dropdown value={isActive} options={activeOptions} optionValue={"value"} optionLabel={"label"}
  //     onChange={handleIsActiveChange} />
  // )

  // const createdAtTmpl = (row) => (
  //   <Tmpl title={"Vytvořeno"}>
  //     {moment(row.created_at).format("DD. MM. YYYY HH:mm")}
  //   </Tmpl>
  // )

  // const closedAtTmpl = (row) => (
  //   <Tmpl title={"Zavřeno"}>
  //     {row.closed_at
  //       ? moment(row.closed_at).format("DD. MM. YYYY HH:mm")
  //       : ""}
  //   </Tmpl>
  // )

  useEffect(() => {
    setIsLoading(true)
    let params = {
      limit, offset, sortField, sortOrder, fulltext: name, machine,
      active: 1,
    }

    // if (isActive !== -1) {
    //   params.active = isActive
    // }

    const queryParamsStr = query.toString(params)
    history.push({ search: queryParamsStr })

    api.get(`/gwps${queryParamsStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setData(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [limit, offset, history, sortField, sortOrder, name, machine])

  return (
    <>
      <div className={"col-12"}>
        <NavLink to={"/gwp"} className={"p-button m-1"}>Nový</NavLink>
      </div>

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField} sortOrder={sortOrder}
          className={"p-datatable-sm"}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          <Column header={"Název"} body={nameTmpl} sortable={true} sortField={"name"}
            filter={true} filterElement={fName} />
          <Column header={"Popis"} body={descriptionTmpl} />
          <Column header={"Stroje"} body={machinesTmpl}
            filter={true} filterElement={fMachine} />
          {/* <Column header={"Aktivní"} body={isActiveTmpl} sortable={true} sortField={"createdAt"}
            filter={true} filterElement={fActive} />
          <Column header={"Vytvořeno"} body={createdAtTmpl} />
          <Column header={"Zavřeno"} body={closedAtTmpl} /> */}
        </DataTable>
      </div>
    </>
  )
}
