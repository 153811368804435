import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async (type, uuid, query) => {
  // GET /tasks/{uuid}/switch?query
  // GET /task-managements/{uuid}/switch?query 
  const { data } = await api.get(`/${type}/${uuid}/switch${query}`)
  return data.data
}

export const useTaskSwitch = (type, uuid, query) => {
  const { data, ...props } = useQuery(
    queryKeys.TASK_SWITCH(type, uuid, query),
    () => getData(type, uuid, query),
    { enabled: !!uuid }
  )
  return { data, ...props }
}
