import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import commonStyles from "../common.module.css"
import api from "../../api"
import userContext from "../../context/userContext"
import confirmation from "../../function/confirmation"
import { useMachines } from "../../api/queries/useMachines"
import FileList from "../File/FileList"

export default function GWPForm() {
  const { uuid } = useParams()
  const { isAdmin } = useContext(userContext)
  const back = () => window.history.back()
  const { data: machineOptions = [] } = useMachines()

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)

  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)

  const [machines, setMachines] = useState([])
  const handleMachinesChange = (e) => setMachines(e.value)

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [gwp, setGwp] = useState({})

  const create = () => {
    const newFiles = files.map((file) => {
      delete file.uuid
      return file
    })

    api
      .put("/gwps", { name, description, machines, files: newFiles })
      .then(back)
  }

  const disable = () => {
    api.post(`/gwps/${uuid}`, { uuid, name, description, machines, is_active: false })
      .then(back)
      .finally(() => setIsLoading(false))
  }
  const disableConfirmation = (e) => confirmation(e.currentTarget, "Opravdu zrušit?", disable)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [isLoading, setIsLoading] = useState(false)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/gwps/${uuid}`)
        .then(({ data }) => {
          setGwp(data.data)
          setName(data.data.name)
          setDescription(data.data.description)
          setMachines(data.data.machines.map((machine) => machine.uuid))
          setFiles(data.data.files)
        })
    }
  }, [uuid, refresher])

  return (
    <>
      <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Název:</label>
            <InputText required={true} value={name} onChange={handleNameChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Popis:</label>
            <InputText required={true} value={description} onChange={handleDescriptionChange} />
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Stroje:</label>
            <MultiSelect options={machineOptions} value={machines} optionLabel={"name"}
              optionValue={"uuid"} placeholder={"Stroje"} showClear={true}
              filter={true} required={true} emptyMessage={"Žádné záznamy"}
              selectedItemsLabel={"{0} záznamů vybráno"}
              onChange={handleMachinesChange} />
          </div>
        </div>

        <div className={"flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={back} />

          <div className={"ml-auto"}>
            {uuid && gwp.is_active && (
              <Button type={"button"} label={"Zrušit"} className={"p-button-danger m-1"}
                onClick={disableConfirmation} disabled={!uuid || !isAdmin()} />
            )}

            {!uuid && (
              <Button type={"submit"} label={"Uložit"} className={"m-1"} disabled={isLoading} />
            )}
          </div>
        </div>
      </form>

      <div className={"col-12 md:col-6"}>
        <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
          entityUrl={uuid ? `/gwps/${uuid}` : ""}
          isDisabled={false} />
      </div>
    </>
  )
}
