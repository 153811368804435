import { useContext, useState } from "react"
import { Redirect } from "react-router-dom"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import commonStyles from "../common.module.css"
import api from "../../api"
import userContext from "../../context/userContext"
import { useMachines } from "../../api/queries/useMachines"
import { Fieldset } from "primereact/fieldset"
import { useOperations } from "../../api/queries/useOperations"
import { usePlaces } from "../../api/queries/usePlaces"
import { useMachineTypes } from "../../api/queries/useMachineTypes"
import { useGFPUnique } from "../../api/queries/useGFPUnique"
import { useHistory } from "react-router-dom"

export default function GFPOnceForm() {
  const history = useHistory()
  const back = () => window.history.back()
  const { user: userDetail, hasRole } = useContext(userContext)
  const [isLoading, setIsLoading] = useState(false)

  // machine filters
  const { data: machines = [] } = useMachines()
  const { data: operations = [] } = useOperations()
  const { data: places = [] } = usePlaces()
  const { data: machineTypes = [] } = useMachineTypes()

  const [machine, setMachine] = useState(null)
  const handleMachineChange = (e) => setMachine(e.target.value || null)

  const { data: gfpUniqueOptions = [] } = useGFPUnique(machine)
  const [gfp, setGfp] = useState(null)
  const handleGfpChange = (e) => setGfp(e.target.value || null)

  const [MFOperation, setMFOperation] = useState(userDetail.operation ? userDetail.operation.uuid : null)
  const handleMFOperationChange = (e) => {
    setMFOperation(e.target.value)
    setMachine(null)
    setMFPlace(null)
    setMFType(null)
  }
  const getMFOperationOptions = () => operations // operation is not filtered

  const [MFPlace, setMFPlace] = useState(null)
  const handleMFPlaceChange = (e) => {
    setMFPlace(e.target.value)
    setMachine(null)
    setMFType(null)
  }
  const getMFPlaceOptions = () => MFOperation
    ? places.filter((place) => place.operation.uuid === MFOperation)
    : places

  const [MFType, setMFType] = useState(null)
  const handleMFTypeChange = (e) => {
    setMFType(e.target.value)
    setMachine(null)
  }
  const getMFTypeOptions = () => {
    const _places = getMFPlaceOptions().map((place) => place.uuid)
    const _machines = MFPlace
      ? machines.filter((machine) => machine.place.uuid === MFPlace)
      : machines.filter((machine) => _places.indexOf(machine.place.uuid) > -1)
    const _types = _machines.map((machine) => machine.type.uuid)
    return machineTypes
      .filter((machineFilter) => _types.indexOf(machineFilter.uuid) > -1)
      .filter((machineType) => machineType.gfs)
  }

  const getMachineOptions = () => {
    let _machines = [...machines]

    if (MFOperation) {
      _machines = _machines.filter((machine) => machine.place.operation.uuid === MFOperation)
    }

    if (MFPlace) {
      _machines = _machines.filter((machine) => machine.place.uuid === MFPlace)
    }

    if (MFType) {
      _machines = _machines.filter((machine) => machine.type.uuid === MFType)
    }

    _machines = _machines.filter((machine) => machine.type.gfs)

    return _machines
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const payload = {
      is_extraordinary: true,
      operation: gfp.operation.uuid,
      name: gfp.name,
      description: gfp.description,
      starts_at: gfp.starts_at,
      shift: gfp.shift,
      machine: gfp.machine.uuid,
      tasks: gfp.tasks,
      files: gfp.files || [],
    }

    api.put("/gfs", payload)
      .then(() => {
        history.push("/gfps")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (hasRole("USER")) {
    return <Redirect to={"/gfps"} />
  }

  return (
    <>
      <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
        <div className={"p-fluid"}>
          <Fieldset className={"p-0 mb-3"}>
            <div className={"formgrid grid"}>
              <div className={"field col pl-0"}>
                <label>Provoz:</label>
                <Dropdown options={getMFOperationOptions()} value={MFOperation}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFOperationChange} />
              </div>

              <div className={"field col"}>
                <label>Místo:</label>
                <Dropdown options={getMFPlaceOptions()} value={MFPlace}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFPlaceChange} />
              </div>

              <div className={"field col pr-0"}>
                <label>Typ stroje:</label>
                <Dropdown options={getMFTypeOptions()} value={MFType}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFTypeChange} />
              </div>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Stroj:</label>
              <Dropdown required={true} value={machine} options={getMachineOptions()} optionValue={"uuid"}
                optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                filter={true} showClear={true} filterBy={"name"}
                onChange={handleMachineChange} />
            </div>
          </Fieldset>

          <div className="field">
            <label className={commonStyles.labelRequired}>GFP:</label>
            <Dropdown required={true} value={gfp} options={gfpUniqueOptions}
              // optionValue={"uuid"} 
              optionLabel={"name"} emptyMessage={"Žádné záznamy"}
              filter={true} showClear={true} filterBy={"name"}
              onChange={handleGfpChange} />
          </div>
        </div>

        <div className={"flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={back} />
          <Button type={"submit"} label={"Uložit"} className={"m-1"} disabled={isLoading || !machine || !gfp} />
        </div>
      </form>
    </>
  )
}
