import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async (operation) => {
  const params = operation ? `?operation=${operation}` : ""
  const { data } = await api.get(`/machines${params}`)
  return data.data
}

export const useMachines = (operation = "") => {
  const { data, ...props } = useQuery(
    queryKeys.MACHINES(operation),
    () => getData(operation),
  )
  return { data, ...props }
}
