import { useEffect, useState } from "react"
import { useParams, NavLink } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Badge } from "primereact/badge"
import api from "../../../api"
import { useTaskStates } from "../../../api/queries/useTaskStates"
import getTaskStateSeverity from "../../../function/getTaskStateSeverity"

export default function TaskRelated() {
  const { uuid, type } = useParams()
  const { data: taskStates = [] } = useTaskStates()
  const [task, setTask] = useState(null)

  const tState = (row) => {
    const taskState = taskStates.find(taskState => taskState.value === row.state)
    return (
      <Badge value={taskState ? taskState.name : ""}
        severity={getTaskStateSeverity(taskState ? taskState.value : "")} />
    )
  }

  const tName = (row) => {
    return (
      <NavLink to={`/task/report/${row.uuid}/detail`} className={"underline"}>
        {row.name}
      </NavLink>
    )
  }

  const tDesc = (row) => {
    return row.description
  }

  const header = (
    <h3>Seznam {type === "all" ? "všech" : "dokončených"} souvisejích hlášení</h3>
  )

  const relatedTasks = task ? task.related
    .filter((relatedTask) => relatedTask.type === "REPORT")
    .filter((relatedTask) => {
      if (type === "all") {
        return true
      } else {
        return relatedTask.state === "FINISHED" || relatedTask.state === "CLOSED"
      }
    }) : []

  useEffect(() => {
    api.get(`/tasks/${uuid}`)
      .then(({ data }) => setTask(data.data))
  }, [uuid])

  return task && (
    <div className={"col-12"}>
      <DataTable emptyMessage={"Žádné záznamy"} value={relatedTasks}
        className={"p-datatable-sm"} stripedRows={true} header={header}>
        <Column key={"state"} header={"Stav"} body={tState} />
        <Column key={"name"} header={"Název"} body={tName} />
        <Column key={"desc"} header={"Popis"} body={tDesc} />
      </DataTable>
    </div>
  )
}
