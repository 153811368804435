import {createContext} from "react"

const notificationContext = createContext({
  notifications: {},
  isVisible: false,
  toggle: () => {},
  read: () => {},
  readAll: () => {},
})

export default notificationContext
