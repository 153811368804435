import {confirmPopup} from "primereact/confirmpopup"

const backConfirmation = (e) => {
  confirmPopup({
    target: e.currentTarget,
    icon: "pi pi-exclamation-triangle",
    message: "Změny nebudou uloženy. Chcete odejít?",
    accept: () => window.history.back(),
    acceptLabel: "Ano",
    rejectLabel: "Ne",
  })
}

export default backConfirmation
