import { useEffect, useRef, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputSwitch } from "primereact/inputswitch"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"
import api from "../../../api"
import query from "../../../function/query"
import Tmpl from "../../../function/Tmpl"
import { useRoles } from "../../../api/queries/useRoles"
import styles from "./styles.module.css"
import TeamFilter from "../../../filter/TeamFilter"
import OperationFilter from "../../../filter/OperationFilter"
import { storage } from "../../../storage"

export default function EnumUserList() {
  const history = useHistory()
  const search = query.parse(useLocation().search)
  const [sortField, setSortField] = useState(query.sortField)
  const [sortOrder, setSortOrder] = useState(query.sortOrder)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }
  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const copyEmailsToastRef = useRef()

  const [showDeleted, setShowDeleted] = useState(!!search.showDisabled || false)
  const handleShowDeletedChange = (e) => setShowDeleted(e.value)

  const { data: roles = [] } = useRoles()
  const [role, setRole] = useState(search.role || "")
  const handleRoleChange = (e) => setRole(e.target.value)
  const roleFilter = <Dropdown options={roles} value={role} optionLabel={"name"}
    optionValue={"value"} placeholder={"Role"} showClear={true} filter={true}
    onChange={handleRoleChange} />

  const [team, setTeam] = useState(search.team || "")
  const handleTeamChange = (e) => setTeam(e.target.value)
  const teamFilter = <TeamFilter value={team} onChange={handleTeamChange} />

  const [operation, setOperation] = useState(search.operation || "")
  const handleOperationChange = (e) => setOperation(e.target.value)
  const operationFilter = <OperationFilter value={operation} onChange={handleOperationChange} />

  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const nameTmpl = (row) => (
    <Tmpl title={"Jméno"}>
      {!row.active && <i className={"fas fa-trash-can mr-1 text-pink-500"} title={"Smazaný"} />}
      <NavLink to={`/enum/user/${row.uuid}`}>{row.name}</NavLink>
    </Tmpl>
  )

  const usernameTmpl = (row) => (
    <Tmpl title={"Přihlašovací jméno"}>
      <span className={styles.breakAll}>{row.username}</span>
    </Tmpl>
  )

  const operationTmpl = (row) => <Tmpl title={"Provoz"} value={row.operation.name} />

  const teamTmpl = (row) => <Tmpl title={"Tým"} value={row.team.name} />

  const roleTmpl = (row) => {
    const role = roles.find((role) => role.value === row.role)
    return (
      <Tmpl title={"Role"}>
        <span>{role ? role.name : ""}</span> <small className={"text-xs"}>({row.role})</small>
      </Tmpl>
    )
  }

  const emailTmpl = (row) => (
    <Tmpl title={"E-mail"}>
      {row.email && <a href={`mailto:${row.email}`} className={styles.breakAll}>{row.email}</a>}
    </Tmpl>
  )

  const phoneTmpl = (row) => (
    <Tmpl title={"Telefon"}>
      {row.phone && <a href={`tel:${row.phone}`} className={styles.breakAll}>{row.phone}</a>}
    </Tmpl>
  )

  const onEmailsCopy = () => {
    const params = query.toString({
      role, team, operation, limit: 999999
    })

    api.get(`/users${params}`)
      .then(({ data }) => {
        const { data: users } = data
        const emails = users
          .filter((user) => !!user.email)
          .map((user) => user.email)
          .join("; ")

        window.navigator.clipboard.writeText(emails)

        copyEmailsToastRef.current.show({ severity: 'info', summary: 'E-maily byly zkopírovány do schránky.'});
      })
  }

  useEffect(() => {
    setIsLoading(true)

    const params = {
      limit, offset, sortField, sortOrder, role, team, operation
    }

    if (showDeleted) {
      params.showDisabled = 1
    }

    const queryParamsStr = query.toString(params)

    history.push({ search: queryParamsStr })

    api.get(`/users${queryParamsStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setData(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [limit, offset, showDeleted, history, sortOrder, sortField, role, team, operation])

  return (
    <>
      <div className={"col-12"}>
        <div className={"flex align-items-center"}>
          <NavLink to={"/enum/user"} className={"p-button m-1"}>Nový</NavLink>

          <Button label="Zkopírovat e-maily" onClick={onEmailsCopy} icon="pi pi-copy"
            className="p-button-secondary" />

          <Toast ref={copyEmailsToastRef} />

          <InputSwitch checked={showDeleted} onChange={handleShowDeletedChange}
            className={"ml-auto mr-1"} inputId={"showDeleted"} />
          <label htmlFor={"showDeleted"}>Zobrazit smazané</label>
        </div>
      </div>

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField} sortOrder={sortOrder}
          className={"p-datatable-sm"}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          <Column header={"Jméno"} body={nameTmpl} sortable={true} sortField={"name"} />
          <Column header={"Přihlašovací jméno"} body={usernameTmpl} />
          <Column header={"Provoz"} body={operationTmpl} sortable={true} sortField={"operation"}
            filter={true} filterElement={operationFilter} />
          <Column header={"Tým"} body={teamTmpl} sortable={true} sortField={"team"}
            filter={true} filterElement={teamFilter} />
          <Column header={"Role"} body={roleTmpl} sortable={true} sortField={"role"}
            filter={true} filterElement={roleFilter} />
          <Column header={"E-mail"} body={emailTmpl} />
          <Column header={"Telefon"} body={phoneTmpl} />
        </DataTable>
      </div>
    </>
  )
}
