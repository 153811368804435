import { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Dropdown } from "primereact/dropdown"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import api from "../../../../api"
import { useSubTaskCauses } from "../../../../api/queries/useSubTaskCauses"
import query from "../../../../function/query"
import moment from "moment"
import DateFilter from "../../../../filter/DateFilter"
import MachineFilter from "../../../../filter/MachineFilter"

export default function TaskPreventionBulkStateForm() {
  const history = useHistory()
  const searchStr = useLocation().search
  const search = query.parse(searchStr)

  const [isLoading, setIsLoading] = useState(false)
  const [tasks, setTasks] = useState([])

  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const onPage = (event) => setOffset(event.first)

  const [selectedRows, setSelectedRows] = useState([])
  const handleSelectionChange = (e) => setSelectedRows(e.value)

  const { data: subTaskCauses = [] } = useSubTaskCauses()
  const [subTaskCause, setSubTaskCause] = useState("")
  const handleSubTaskCauseChange = (e) => setSubTaskCause(e.target.value)
  const subTaskCausesFiltered = subTaskCauses
    .filter((subTaskCause) => !subTaskCause.disabled)
  const tSubTaskCauseItem = (option) => {
    return `${option.name} - ${option.description}`
  }
  const tSubTaskCauseValue = (option, props) => {
    if (option) {
      return `${option.name} - ${option.description}`
    }

    return <span>{props.placeholder || ""}</span>
  }

  const [note, setNote] = useState("")
  const handleNoteChange = (e) => setNote(e.target.value)

  const tMachine = (row) => row.machine ? row.machine.name : ""
  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = <MachineFilter value={machine} onChange={handleMachineChange} />

  const tStartsAt = (row) => row.starts_at ? moment(row.starts_at).format("DD. MM. YYYY") : ""
  const [startsAt, setStartsAt] = useState(search.starts_at
    ? moment(search.starts_at).toDate() : null)
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)
  const startsAtFilter = <DateFilter value={startsAt} onChange={handleStartsAtChange}
    placeholder={"Požadovaný termín od"} />

  const tEndsAt = (row) => row.ends_at ? moment(row.ends_at).format("DD. MM. YYYY") : ""
  const [endsAt, setEndsAt] = useState(search.ends_at
    ? moment(search.ends_at).toDate() : null)
  const handleEndsAtChange = (e) => setEndsAt(e.target.value)
  const endsAtFilter = <DateFilter value={endsAt} onChange={handleEndsAtChange}
    placeholder={"Požadovaný termín do"} />

  useEffect(() => {
    setIsLoading(true)

    let payload = {
      type: "PREVENTION",
      view: "history",
      state: "NOT_FINISHED",
      order: "created_desc",
      limit: 20,
      offset,
      machine,
    }

    if (startsAt) {
      payload.starts_at = moment(startsAt).format("YYYY-MM-DD")
    }

    if (endsAt) {
      payload.ends_at = moment(endsAt).format("YYYY-MM-DD")
    }

    const payloadStr = query.toString(payload)
    history.replace({ search: payloadStr })

    api.get(`/tasks${payloadStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setTasks(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [offset, machine, startsAt, endsAt, history])

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const updateSubtask = async (taskUUID, subTaskUUID) => {
      await api.post(`/tasks/${taskUUID}/sub/${subTaskUUID}`, {
        uuid: subTaskUUID,
        cause_of_unfinished: subTaskCause,
        state_description: note,
      })
    }

    const getTask = async (taskUUID) => {
      const { data } = await api.get(`/tasks/${taskUUID}`)

      for (let i = 0; i < data.data.tasks.length; i++) {
        const subTask = data.data.tasks[i]
        if (subTask.state === "NOT_FINISHED" && subTask.cause_of_unfinished === "OTHERS") {
          await updateSubtask(taskUUID, subTask.uuid)
        }
      }
    }

    for (let i = 0; i < selectedRows.length; i++) {
      await getTask(selectedRows[i].uuid)
    }

    alert("Podúkoly byly aktualizovány")

    history.push("/tasks/preventions")
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={"col-12"}>
        <h3>Pravidelná údržba</h3>

        <DataTable emptyMessage={"Žádné záznamy"} value={tasks} loading={isLoading}
          className={"p-datatable-sm"}
          selectionMode={"checkbox"} selection={selectedRows} onSelectionChange={handleSelectionChange}
          paginator={true} lazy={true} rows={20} first={offset} totalRecords={total} onPage={onPage}
        >
          <Column columnKey={"multiple"} selectionMode={"multiple"} headerStyle={{ width: '3em' }} />
          <Column columnKey={"#"} header={"#"} field={"id"} />
          <Column columnKey={"name"} header={"Název"} field={"name"} />
          <Column columnKey={"description"} header={"Popis"} field={"description"} />
          <Column columnKey={"machine"} header={"Stroj"} field={"machine"} body={tMachine}
            filter={true} filterElement={machineFilter} />
          <Column columnKey={"startsAt"} header={"Požadovaný termín od"} body={tStartsAt}
            filter={true} filterElement={startsAtFilter} />
          <Column columnKey={"endsAt"} header={"Požadovaný termín do"} body={tEndsAt}
            filter={true} filterElement={endsAtFilter} />
        </DataTable>
      </div>

      <div className={"col-12"} />

      <div className={"col-12 md:col-6 p-fluid"}>
        <h3>Důvod nedokončení</h3>
        <Dropdown options={subTaskCausesFiltered} value={subTaskCause}
          onChange={handleSubTaskCauseChange} optionLabel={"name"}
          optionValue={"value"} placeholder={"Kategorie"}
          itemTemplate={tSubTaskCauseItem}
          valueTemplate={tSubTaskCauseValue} />
      </div>

      <div className={"col-12 md:col-6 p-fluid"}>
        <h3>Poznámka</h3>
        <InputText value={note} onChange={handleNoteChange} />
      </div>

      <div className={"col-12 pt-5"}>
        <Button label={"Uložit a aktualizovat podúkoly vybraných údržeb"}
          className={"p-button-success"} icon={"pi pi-save"}
          disabled={isLoading || selectedRows.length === 0 || !subTaskCause} />
      </div>
    </form>
  )
}
