import { useContext, useEffect, useRef, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Badge } from "primereact/badge"
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider"
import { useImmer } from "use-immer"
import api from "../../api"
import userContext from "../../context/userContext"
import commonStyles from "../common.module.css"
import FileUpload from "../File/FileUpload"
import moment from "moment"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useTaskTags } from "../../api/queries/useTaskTags"
import { Dropdown } from "primereact/dropdown"
import { NavLink } from "react-router-dom"
import { Toast } from "primereact/toast"

export default function VZVSubTaskForm() {
  const { hasRole, isAdmin, user } = useContext(userContext)
  const { uuid } = useParams()
  const history = useHistory()
  const back = () => history.goBack()
  const backToForm = () => history.push(`/vzv/${uuid}`)
  const statusToastRef = useRef()

  const { data: allTaskTagOptions = [] } = useTaskTags()
  const taskTagOptions = allTaskTagOptions.filter((tag) => tag.key === "varovani" || tag.key === "akutni")

  const [refresher, setRefresher] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [isGWPConfirmed, setIsGWPConfirmed] = useState(false)
  const [isGWPDialogVisible, setIsGWPDialogVisible] = useState(false)
  const onGWPDialogHide = () => {
    setIsGWPConfirmed(true)
    setIsGWPDialogVisible(false)
  }

  const onVZVRecordCreate = async () => {
    let state = subTasks.every(subtask => subtask.state === "OK") ? "OK" : "NOK"

    await api.put(`/vzv/${uuid}/user`, {
      user: user.uuid,
      state,
    })

    setRefresher(!refresher)
  }

  const [subTaskOpenReportName, setSubTaskOpenReportName] = useState("")
  const [subTaskOpenReportDesc, setSubTaskOpenReportDesc] = useState("")
  const [subTaskOpenReportTag, setSubTaskOpenReportTag] = useState("")
  const [subTaskOpenReportFiles, setSubTaskOpenReportFiles] = useState([])
  const handleSubTaskOpenReportNameChange = (e) => setSubTaskOpenReportName(e.target.value)
  const handleSubTaskOpenReportDescChange = (e) => setSubTaskOpenReportDesc(e.target.value)
  const handleSubTaskOpenReportTagChange = (e) => setSubTaskOpenReportTag(e.value)

  const [task, setTask] = useState({
    machine: { name: "" },
  })

  const [subTasks, setSubTasks] = useImmer([])

  const handleSubTaskChange = (subTask, key, value) => {
    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)

      if (key === "value") {
        value = parseFloat(value)
      }

      draftSubTask[key] = value
    })
  }

  const setSubTaskFiles = (subTask, files) => {
    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)
      draftSubTask.files = [...files]
    })
  }

  const [subTaskOpen, setSubTaskOpen] = useState(null)
  const openSubTask = (subTask) => setSubTaskOpen(subTask)

  const updateSubtask = async (subTask, state) => {
    setIsLoading(true)

    await api.post(`/vzv/${uuid}`, { uuid, state: "IN_PROGRESS" })

    const params = {
      uuid: subTask.uuid, state, state_description: subTask.state_description,
      files: subTask.files, problem_description: subTask.problem_description,
    }

    if (subTask.value) {
      params.value = subTask.value
    }

    await api.post(`/vzv/${uuid}/sub/${subTask.uuid}`, params)

    if (statusToastRef.current) {
      statusToastRef.current.show({ severity: 'info', summary: 'Podúkol aktualizován.' });
    }

    // new files
    const files = subTask.files.filter((file) => !file.createdBy)

    for (let i = 0; i < files.length; i++) {
      const file = Object.assign({}, files[i])
      delete file.uuid
      await api.put(`/vzv/${uuid}/sub/${subTask.uuid}/file`, file)
    }

    setRefresher(!refresher)
    setIsLoading(false)
  }

  const updateSubtaskNOK = () => {
    updateSubtask(subTaskOpen, "NOK")
    setSubTaskOpen(null)
    setSubTaskOpenReportName("")
    setSubTaskOpenReportDesc("")
    setSubTaskOpenReportTag("")
    setSubTaskOpenReportFiles([])
  }

  const createReport = async () => {
    const files = subTaskOpenReportFiles.map((file) => {
      const newfile = Object.assign({}, file)
      delete newfile.uuid
      return newfile
    })

    await api.put("/tasks", {
      type: "REPORT",
      operation: user.operation.uuid,
      machine: task.machine.uuid,
      starts_at: moment().toISOString(),
      name: subTaskOpenReportName,
      description: subTaskOpenReportDesc,
      files,
      tag: subTaskOpenReportTag,
      vzvItem: subTaskOpen.uuid,
    })

    if (statusToastRef.current) {
      statusToastRef.current.show({ severity: 'info', summary: 'Hlášení vytvořeno.' });
    }

    updateSubtaskNOK()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/vzv/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)
          setSubTasks((draft) => {
            draft.splice(0, draft.length)
            data.data.tasks.forEach((subTask) => draft.push(subTask))
          })
        })
    }
  }, [uuid, refresher, setSubTasks])

  useEffect(() => {
    if (task.machine.gwp && task.machine.gwp.length > 0 && !isGWPConfirmed) {
      setIsGWPDialogVisible(true)
    }
  }, [task, isGWPConfirmed])

  const getSubTaskBadgeValue = (subTask) => {
    if (subTask.state === "UNKNOWN") {
      return "otevřeno"
    }

    return subTask.state.toLowerCase()
  }

  const getSubTaskStateSeverity = (subTask) => {
    if (subTask.state === "OK") {
      return "success"
    }

    if (subTask.state === "NOK") {
      return "danger"
    }

    return "info"
  }

  const tState = (row) => {
    if (row.state === "OK") {
      return <i className="pi pi-check text-green-400" />
    }

    return <i className="pi pi-times text-pink-500" />
  }

  const tCreatedAt = (row) => {
    return moment(row.created_at).format("DD. MM. YYYY HH:mm:ss")
  }

  const tUser = (row) => `${row.user.name} (${row.user.email})`

  return (
    <>
      <Toast ref={statusToastRef} />

      <div className={"col-12 md:col-6"}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <label>Číslo:</label>
            <InputText value={task.id || ""} disabled={true} />
          </div>

          <div className={"field"}>
            <label>Stroj:</label>
            <InputText value={task.machine.name || ""} disabled={true} />
          </div>

          {subTasks.map((subTask, i) => (
            <div key={subTask.uuid}>
              <Divider>
                <Badge className={"mr-1"}
                  value={getSubTaskBadgeValue(subTask)}
                  severity={getSubTaskStateSeverity(subTask)}
                />
                <label>Podúkol č. {i + 1}: <i className={"my-2"}>{subTask.description}</i></label>
              </Divider>

              {subTask.last_nok && (
                <div className="mb-3 text-pink-500 flex align-items-center">
                  <i className="fa-solid fa-triangle-exclamation mr-2" />
                  <span>
                    Na předchozí směně byl úkol dokončen stavem NOK s poznámkou:{" "}
                    <i>{subTask.last_nok.state_description || "bez poznámky"}</i>
                  </span>
                </div>
              )}

              {subTask.value_description && (
                <div className={"field"}>
                  <label className={commonStyles.labelRequired}>
                    {subTask.value_description} ({subTask.value_unit})
                  </label>
                  <InputText placeholder={subTask.value_description} type={"number"}
                    value={subTask.value || ""}
                    onChange={(e) => handleSubTaskChange(subTask, "value", e.target.value)} />
                </div>
              )}

              <div className={"field"}>
                <label>Poznámka:</label>
                <div className={"p-inputgroup"}>
                  <InputText value={subTask.state_description || ""}
                    placeholder={`Poznámka k podúkolu č. ${i + 1}`}
                    onChange={(e) => handleSubTaskChange(subTask, "state_description", e.target.value)} />

                  {!subTaskOpen && (
                    <>
                      <Button type={"button"}
                        className={"p-button-success"} icon={"pi pi-check"}
                        onClick={() => updateSubtask(subTask, "OK")}
                        disabled={(subTask.value_description && !subTask.value) || isLoading} />

                      <Button type={"button"}
                        className={"p-button-danger"} icon={"pi pi-times"}
                        onClick={() => openSubTask(subTask)}
                        disabled={isLoading} />
                    </>
                  )}
                </div>

                {(subTaskOpen && subTaskOpen.uuid === subTask.uuid) && (
                  <>
                    <Divider>Hlášení</Divider>

                    <div className="p-fluid">
                      <div className={"field"}>
                        <label className={commonStyles.labelRequired}>Název:</label>
                        <InputText value={subTaskOpenReportName} placeholder={"Název"}
                          onChange={handleSubTaskOpenReportNameChange} maxLength={30} />
                      </div>
                      <div className={"field"}>
                        <label className={commonStyles.labelRequired}>Popis:</label>
                        <InputText value={subTaskOpenReportDesc} placeholder={"Popis"}
                          onChange={handleSubTaskOpenReportDescChange} />
                      </div>
                      <div className={"field"}>
                        <label className={commonStyles.labelRequired}>Typ:</label>
                        <Dropdown required={true} options={taskTagOptions}
                          optionValue={"uuid"} optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                          value={subTaskOpenReportTag} onChange={handleSubTaskOpenReportTagChange}
                          placeholder="Typ"
                        />
                      </div>
                      <div className={"field"}>
                        <FileUpload files={subTaskOpenReportFiles}
                          setFiles={setSubTaskOpenReportFiles}
                          showClear={true} />
                      </div>
                    </div>

                    <div className="flex gap-3">
                      <Button type={"button"}
                        className="mr-3 p-button-danger"
                        label="Zavřít"
                        onClick={() => setSubTaskOpen(null)} />
                      <Button type={"button"}
                        className={"p-button-warning"}
                        label={"Vytvořit hlášení"}
                        disabled={!subTaskOpenReportName || !subTaskOpenReportDesc || !subTaskOpenReportTag}
                        onClick={() => createReport()} />
                    </div>
                  </>
                )}

                <div className={"field mt-3"}>
                  {subTask.files.length > 0 && (
                    <label>Přílohy k dokončení podúkolu:</label>
                  )}

                  <FileUpload files={subTask.files}
                    setFiles={(files) => setSubTaskFiles(subTask, files)}
                    disabled={false} showClear={subTask.state === "UNKNOWN"} />
                </div>

                <div className="field mt-3">
                  {subTask.tasks && subTask.tasks.length > 0 && (
                    <>
                      <label className="text-500">Existující hlášení:</label>
                      <ul className="mt-0">
                        {subTask.tasks.map((subTaskReport) => (
                          <li>
                            <NavLink to={`/task/report/${subTaskReport.uuid}/detail`} className={"text-primary"} target="_blank">
                              #{subTaskReport.id} - {subTaskReport.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={"flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={back} />

          {(isAdmin() || hasRole("SUPERVISOR")) && (
            <Button type={"button"} label={"Přehled"} className={"p-button-warning m-1"}
              onClick={backToForm} />
          )}

          <Button type={"button"} label={"Vytvořit záznam do deníku"} className={"m-1 ml-auto"}
            onClick={onVZVRecordCreate} disabled={subTasks.some((subTask) => subTask.state === "UNKNOWN")} />
        </div>

        <Dialog visible={isGWPDialogVisible} header={"Na tomto stroji je aktivni GWP."}
          closable={false} draggable={false}
          footer={
            <Button label="Beru na vědomí" onClick={onGWPDialogHide} />
          }>
          {task.machine.gwp && task.machine.gwp.map((item) => (
            <div><strong>{item.name}</strong> - {item.description}</div>
          ))}
        </Dialog>
      </div>

      <div className="col-12 md:col-6">
        <div className="field">
          <label>VZV deník:</label>
          <DataTable
            value={task.users || []}
            className="p-datatable-sm"
            emptyMessage="Žádné záznamy"
          >
            <Column field="state" header={"Stav"} body={tState} />
            <Column field="crated_at" header={"Vytvořeno"} body={tCreatedAt} />
            <Column field="user" header={"Uživatel"} body={tUser} />
          </DataTable>
        </div>
      </div>
    </>
  )
}
