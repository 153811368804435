import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Checkbox } from "primereact/checkbox"
import { Button } from "primereact/button"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import { queryKeys } from "../../../client"
import { useQueryClient } from "react-query"

export default function EnumMachineTypeForm() {
  const { uuid } = useParams()
  const back = () => window.history.back()
  const queryClient = useQueryClient()
  const { hasPerm, isAdmin } = useContext(userContext)

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)

  const [GFP, setGFP] = useState(false)
  const handleGFPChange = (e) => setGFP(e.checked)

  const create = () => api
    .put("/machine-types", { name, gfs: GFP })
    .then(back)

  const update = () => api
    .post(`/machine-types/${uuid}`, { uuid, name, gfs: GFP })
    .then(back)

  const remove = () => {
    queryClient.invalidateQueries(queryKeys.MACHINE_TYPES, { refetchInactive: true })
    api.delete(`/machine-types/${uuid}`)
      .then(back)
  }

  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.MACHINE_TYPES, { refetchInactive: true })
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/machine-types/${uuid}`)
        .then(({ data }) => {
          setName(data.data.name)
          setGFP(data.data.gfs)
        })
    }
  }, [uuid])

  return (
    <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
      <div className={"p-fluid"}>
        <div className={"field"}>
          <label className={commonStyles.labelRequired}>Název:</label>
          <InputText required={true} value={name} onChange={handleNameChange} />
        </div>

        <div className={"field-checkbox"}>
          <Checkbox checked={GFP} onChange={handleGFPChange} inputId={"gfp"} />
          <label htmlFor={"gfp"}>GFP</label>
        </div>
      </div>

      <div className={"flex"}>
        <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
          onClick={backConfirmation} />
        <div className={"ml-auto"}>
          <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
            onClick={removeConfirmation}
            disabled={!uuid || !isAdmin()} />
          <Button type={"submit"} label={"Uložit"} className={"m-1"}
            disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")} />
        </div>
      </div>
    </form>
  )
}
