import React from "react"
import moment from "moment"
import styles from "./styles.module.css"

export default function DateRange({startsAt, endsAt, finishedAt}) {
  if (!startsAt && !endsAt) {
    return ""
  }

  const format = (date) => date.format("D. M.")
  const getStyle = () => {
    // starts at only
    if (mStartsAt.isValid() && !mEndsAt.isValid()) {
      if (mNow.isBefore(mStartsAt)) {
        return styles.dateRangeBefore
      }

      return styles.dateRangeIn
    }

    // end at only
    if (!mStartsAt.isValid() && mEndsAt.isValid()) {
      if (mNow.isBefore(mEndsAt)) {
        return styles.dateRangeIn
      }

      return styles.dateRangeAfter
    }

    // start at and ends at
    if (mNow.isBetween(mStartsAt, mEndsAt)) {
      return styles.dateRangeIn
    } else if (mNow.isBefore(mStartsAt)) {
      return styles.dateRangeBefore
    }

    return styles.dateRangeAfter
  }
  const mNow = finishedAt ? moment(finishedAt) : moment()
  const mStartsAt = moment(startsAt)
  const mEndsAt = moment(endsAt)

  return (
    <div>
      <div className={getStyle()}>
        <span className={styles.dateStartsAt}>
          {mStartsAt.isValid() ? format(mStartsAt) : ""}
        </span>

        <span className={styles.sep}>
          &gt;
        </span>

        <span className={styles.dateEndsAt}>
          {mEndsAt.isValid() ? format(mEndsAt) : ""}
        </span>
      </div>
    </div>
  )
}
