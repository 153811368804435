import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"
import { Button } from "primereact/button"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { usePermissions } from "../../../api/queries/usePermissions"
import { useRoles } from "../../../api/queries/useRoles"
import { useOperations } from "../../../api/queries/useOperations"
import { useTeams } from "../../../api/queries/useTeams"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import { useMachines } from "../../../api/queries/useMachines"
import { queryKeys } from "../../../client"
import { useQueryClient } from "react-query"
import { Checkbox } from "primereact/checkbox"

const norm = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export default function EnumUserForm() {
  const { uuid } = useParams()
  const queryClient = useQueryClient()
  const { user } = useContext(userContext)
  const { data: perms = [] } = usePermissions()
  const { data: roles = [] } = useRoles()
  const { data: operations = [] } = useOperations()
  const { data: teams = [] } = useTeams()
  const { data: machineOptions = [] } = useMachines(user.operation ? user.operation.uuid : "")
  const back = () => window.history.back()

  const [name, setName] = useState("")
  const handleNameChange = (e) => {
    let value = e.target.value
    setName(value)
    if (!uuid) {
      let names = value.split(" ")

      let name = norm(names[0])
      let surname = names.length > 1 ? norm(names[1]) : ""

      setUsername(name + surname.substr(0, 2))
    }
  }
  const [active, setActive] = useState(true)
  const [username, setUsername] = useState("")
  const handleUsernameChange = (e) => setUsername(e.target.value)
  const [password, setPassword] = useState("")
  const handlePasswordChange = (e) => setPassword(e.target.value)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const handleIsPasswordVisibleChange = () => setIsPasswordVisible(!isPasswordVisible)
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const handlePasswordConfirmationChange = (e) => setPasswordConfirmation(e.target.value)
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false)
  const handleIsPasswordConfirmationVisibleChange = () => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)
  const [email, setEmail] = useState("")
  const handleEmailChange = (e) => setEmail(e.target.value)
  const [phone, setPhone] = useState("+420")
  const handlePhoneChange = (e) => setPhone(e.target.value)
  const isValidPhoneNumber = () => phone === "" || phone === "+420" || /^\+420[0-9]{9}$/.test(phone)
  const [operation, setOperation] = useState("")
  const handleOperationChange = (e) => setOperation(e.target.value)
  const [team, setTeam] = useState("")
  const handleTeamChange = (e) => setTeam(e.target.value)
  const [role, setRole] = useState("")
  const roleOptionTemplate = (option) => (
    <>
      <span>{option.name}</span> <small className={"text-xs"}>({option.value})</small>
    </>
  )
  const handleRoleChange = (e) => setRole(e.target.value)
  const [perm, setPerm] = useState([])
  const handlePermChange = (e) => setPerm(e.target.value)
  const [machines, setMachines] = useState([])
  const handleMachinesChange = (e) => setMachines(e.value)
  const [sendReport, setSendReport] = useState(false)
  const handleSendReportChange = (e) => setSendReport(e.checked)
  const [sendVZVReport, setSendVZVReport] = useState(false)
  const handleSendVZVReportChange = (e) => setSendVZVReport(e.checked)

  const create = () => {
    const _phone = phone === "+420" ? "" : phone

    let payload = {
      name, username, password, email, phone: _phone, operation, team, role,
      permissions: perm, machines, send_report: sendReport, send_vzv_report: sendVZVReport,
    }

    api.put("/users", payload)
      .then(back)
  }
  const update = () => {
    const _phone = phone === "+420" ? "" : phone

    let payload = {
      uuid, name, email, phone: _phone, operation, team, role, permissions: perm, machines,
      send_report: sendReport, send_vzv_report: sendVZVReport,
    }

    if (password) {
      payload.password = password
    }

    api.post(`/users/${uuid}`, payload)
      .then(back)
  }
  const remove = () => {
    queryClient.invalidateQueries(queryKeys.USERS, { refetchInactive: true })
    api.post(`/users/${uuid}`, { uuid, active: false })
      .then(back)
  }
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.USERS, { refetchInactive: true })
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/users/${uuid}`)
        .then(({ data }) => {
          setActive(data.data.active)
          setName(data.data.name)
          setUsername(data.data.username)
          if (data.data.operation) {
            setOperation(data.data.operation.uuid)
          }
          if (data.data.team) {
            setTeam(data.data.team.uuid)
          }
          setRole(data.data.role)
          setPerm(data.data.permissions)
          setEmail(data.data.email || "")
          setPhone(data.data.phone || "")
          setMachines(data.data.machines.map((machine) => machine.uuid))
          setSendReport(data.data.send_report)
          setSendVZVReport(data.data.send_vzv_report)
        })
    }
  }, [uuid])

  return (
    <userContext.Consumer>
      {({ hasPerm, isAdmin }) => (
        <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
          <div className={"p-fluid"}>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Příjmení a jméno:</label>
              <InputText required={true} value={name} onChange={handleNameChange} />
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Přihlašovací jméno:</label>
              <InputText required={true} value={username} onChange={handleUsernameChange}
                disabled={!!uuid} />
            </div>

            <div className={"field"}>
              <label className={uuid ? "" : commonStyles.labelRequired}>Heslo:</label>
              <div className={"p-inputgroup"}>
                <InputText type={isPasswordVisible ? "text" : "password"} required={!uuid}
                  value={password} onChange={handlePasswordChange} />
                <Button type={"button"} className={"p-button-secondary"}
                  icon={isPasswordVisible ? "pi pi-eye-slash" : "pi pi-eye"}
                  onClick={handleIsPasswordVisibleChange} />
              </div>
              {uuid &&
                <small className={commonStyles.fieldHelp}>Nechte prázdné pro zachování
                  hesla.</small>}
            </div>

            <div className={"field"}>
              <label className={uuid ? "" : commonStyles.labelRequired}>Potvrzení hesla:</label>
              <div className={"p-inputgroup"}>
                <InputText type={isPasswordConfirmationVisible ? "text" : "password"}
                  required={!uuid} value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange} />
                <Button type={"button"} className={"p-button-secondary"}
                  icon={isPasswordConfirmationVisible ? "pi pi-eye-slash" : "pi pi-eye"}
                  onClick={handleIsPasswordConfirmationVisibleChange} />
              </div>
              {passwordConfirmation && passwordConfirmation !== password && (
                <small className={commonStyles.fieldError}>Zadaná hesla se neshodují.</small>
              )}
            </div>

            <div className={"field"}>
              <label>E-mail:</label>
              <InputText value={email} onChange={handleEmailChange} type={"email"} />
            </div>

            <div className={"field"}>
              <label>Telefon:</label>
              <InputText value={phone} onChange={handlePhoneChange} type={"tel"} />
              <small className={commonStyles.fieldHelp}>Zadávejte v mezinárodním formátu a bez mezer
                (+420xxxyyyzzz)</small>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Provoz:</label>
              <Dropdown required={true} value={operation} options={operations} optionValue={"uuid"}
                optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                onChange={handleOperationChange} />
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Tým:</label>
              <Dropdown required={true} value={team} options={teams} optionValue={"uuid"}
                optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                onChange={handleTeamChange} />
            </div>

            <div className={"field"}>
              <label>Stroj:</label>
              <MultiSelect required={true} value={machines}
                options={machineOptions} optionValue={"uuid"} optionLabel={"name"}
                emptyMessage={"Žádné záznamy"} selectedItemsLabel={"{0} záznamů vybráno"}
                onChange={handleMachinesChange} />
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Uživatelská role:</label>
              <Dropdown required={true} value={role}
                options={roles} optionValue={"value"} optionLabel={"name"}
                itemTemplate={roleOptionTemplate} emptyMessage={"Žádné záznamy"}
                onChange={handleRoleChange} />
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Uživatelská práva:</label>
              <MultiSelect required={true} value={perm}
                options={perms} optionValue={"value"} optionLabel={"name"}
                emptyMessage={"Žádné záznamy"} selectedItemsLabel={"{0} záznamů vybráno"}
                onChange={handlePermChange} />
            </div>
          </div>

          <div className="field-checkbox">
            <Checkbox checked={sendReport} onChange={handleSendReportChange} inputId={"sendReport"}
              disabled={!isAdmin()}
            />
            <label htmlFor="sendReport">Posílat GFP report</label>
          </div>

          <div className="field-checkbox">
            <Checkbox checked={sendVZVReport} onChange={handleSendVZVReportChange} inputId={"sendVZVReport"}
              disabled={!isAdmin()}
            />
            <label htmlFor="sendVZVReport">Posílat VZV report</label>
          </div>

          <div className={"flex"}>
            <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
              onClick={backConfirmation} />
            <div className={"ml-auto"}>
              <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
                onClick={removeConfirmation}
                disabled={!uuid || !isAdmin() || !active} />
              <Button type={"submit"} label={"Uložit"} className={"m-1"}
                disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE") || password !== passwordConfirmation || !isValidPhoneNumber() || !active} />
            </div>
          </div>
        </form>
      )}
    </userContext.Consumer>
  )
}
