import { useEffect, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import api from "../../../api"
import query from "../../../function/query"
import Tmpl from "../../../function/Tmpl"
import { storage } from "../../../storage"

export default function EnumTaskTagList() {
  const history = useHistory()
  const search = query.parse(useLocation().search)
  const [sortField, setSortField] = useState(query.sortField)
  const [sortOrder, setSortOrder] = useState(query.sortOrder)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const nameTmpl = (row) => (
    <Tmpl title={"Název"}>
      <NavLink to={`/enum/task-tag/${row.uuid}`}>{row.name}</NavLink>
    </Tmpl>
  )
  const abbrTmpl = (row) => (
    <Tmpl title={"Zkratka"} value={row.abbr} />
  )
  const positionTmpl = (row) => (
    <Tmpl title={"Pozice"} value={row.position} />
  )

  useEffect(() => {
    setIsLoading(true)
    const queryParamsStr = query.toString({
      limit, offset, sortField, sortOrder,
    })
    history.push({ search: queryParamsStr })

    api.get(`/task-tags${queryParamsStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setData(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [limit, offset, history, sortField, sortOrder])

  return (
    <>
      <div className={"col-12"}>
        <NavLink to={"/enum/task-tag"} className={"p-button m-1"}>Nový</NavLink>
      </div>

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField} sortOrder={sortOrder}
          className={"p-datatable-sm"}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          <Column header={"Název"} body={nameTmpl} sortable={true} sortField={"name"} />
          <Column header={"Zkratka"} body={abbrTmpl} />
          <Column header={"Pozice"} body={positionTmpl} />
        </DataTable>
      </div>
    </>
  )
}
