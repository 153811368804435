import { useContext, useState } from "react"
import { useTaskProcesses } from "../../../../api/queries/useTaskProcesses"
import api from "../../../../api"
import { useHistory, useParams } from "react-router-dom"
import { Dropdown } from "primereact/dropdown"
import { InputTextarea } from "primereact/inputtextarea"
import { Button } from "primereact/button"
import moment from "moment"
import userContext from "../../../../context/userContext"

export const TaskReportProcessForm = () => {
  const history = useHistory()
  const { uuid } = useParams()
  const { user: userDetail } = useContext(userContext)

  const { data: taskProcesses = [] } = useTaskProcesses()
  const [additionalTaskProcess, setAdditionalTaskProcess] = useState("")
  const onAdditionalTaskProcessChange = (event) => setAdditionalTaskProcess(event.value || "")

  const [additionalProcessDescription, setAdditionalProcessDescription] = useState("")
  const onAdditionalProcessDescriptionChange = (event) => setAdditionalProcessDescription(event.target.value)

  const onTaskProcessSubmit = async (event) => {
    event.preventDefault()

    await api.post(`/tasks/${uuid}`, {
      uuid, additional: {
        task_process: additionalTaskProcess,
        process_description: additionalProcessDescription,
        process_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        processed_by: userDetail.uuid,
      }
    })

    onBack()
  }

  const onBack = () => history.push(`/task/report/${uuid}/detail`)

  return (
    <form className={"col-12 md:col-7"} onSubmit={onTaskProcessSubmit}>
      <div className="p-fluid">
        <div className="field">
          <label>Postup:</label>
          <Dropdown options={taskProcesses}
            value={additionalTaskProcess} onChange={onAdditionalTaskProcessChange}
            optionLabel="name" optionValue="uuid" showClear={true} />
        </div>

        <div className="field">
          <label>Detailní popis:</label>
          <InputTextarea value={additionalProcessDescription}
            onChange={onAdditionalProcessDescriptionChange} />
        </div>
      </div>

      <div>
        <Button label="Zpět" type="button" className="mr-3 p-button-warning" onClick={onBack} />
        <Button label="Uložit" disabled={!(additionalTaskProcess && additionalProcessDescription)} />
      </div>
    </form>
  )
}
