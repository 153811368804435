import { useQuery } from "react-query"
import { queryKeys } from "../../client"
import api from "../../api"

const getData = async () => {
  const { data } = await api.get(`/task-repeated`)
  return data.data
}

export const useTaskRepeated = () => {
  const { data, ...props } = useQuery(
    queryKeys.TASK_REPEATED,
    () => getData(),
  )
  return { data, ...props }
}
