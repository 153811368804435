import {createContext} from "react"

const userContext = createContext({
  user: {},
  login: () => {},
  logout: () => {},
  hasPerm: (name) => {},
  hasRole: (name) => {},
  isAdmin: () => false,
})

export default userContext
