const Tmpl = (props) => {
  return (
    <>
      <span className={"p-column-title"}>{props.title}:</span>
      {/* only one of these should be present - value OR children */}
      {props.value}
      {props.children}
    </>
  )
}

export default Tmpl
