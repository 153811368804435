import { NavLink, useHistory, useLocation } from "react-router-dom"
import { useEffect, useState, useContext } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import moment from "moment"
import api from "../../api"
import query from "../../function/query"
import Tmpl from "../../function/Tmpl"
import { useWarehouseStates } from "../../api/queries/useWarehouseStates"
import { useWarehouseCategories } from "../../api/queries/useWarehouseCategories"
import { useWarehouseParts } from "../../api/queries/useWarehouseParts"
import { useWarehouseWarehouses } from "../../api/queries/useWarehouseWarehouses"
import SupplierFilter from "../../filter/SupplierFilter"
import MachineFilter from "../../filter/MachineFilter"
import { storage } from "../../storage"
import { useOperations } from "../../api/queries/useOperations"
import userContext from "../../context/userContext"
import { InputSwitch } from "primereact/inputswitch"

export default function WarehouseList() {
  const history = useHistory()
  const search = query.parse(useLocation().search)
  const { user } = useContext(userContext)
  const { data: operations } = useOperations()
  const [sortField, setSortField] = useState(query.sortField)
  const [sortOrder, setSortOrder] = useState(query.sortOrder)
  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }
  const [data, setData] = useState([])
  const [limit, setLimit] = useState(search.limit || storage.get("limit") || 20)
  const [offset, setOffset] = useState(search.offset || 0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const onPage = ({ first, rows }) => {
    setOffset(first)
    setLimit(rows)
    storage.set("limit", rows)
  }

  const [showDeleted, setShowDeleted] = useState(!!search.showDisabled || false)
  const handleShowDeletedChange = (e) => setShowDeleted(e.value)

  const referenceNumberTmpl = (row) => <Tmpl title={"Ref. č."} value={row.reference_number} />
  const { data: categories = [] } = useWarehouseCategories()
  const categoryTmpl = (row) => <Tmpl title={"Kategorie"}>{row.category.name}</Tmpl>
  const [category, setCategory] = useState(search.category || "")
  const handleCategoryChange = (e) => setCategory(e.target.value)
  const categoryFilter = (
    <Dropdown options={categories} value={category} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Kategorie"} showClear={true} filter={true}
      onChange={handleCategoryChange} />
  )

  const { data: parts = [] } = useWarehouseParts()
  const partTmpl = (row) => <Tmpl title={"Díl"}>{row.part.name}</Tmpl>
  const [part, setPart] = useState(search.part || "")
  const handlePartChange = (e) => setPart(e.target.value)
  const partFilter = (
    <Dropdown options={parts} value={part} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Díl"} showClear={true} filter={true}
      onChange={handlePartChange} />
  )

  const labelTmpl = (row) => (
    <Tmpl title={"Označení"}>
      {!row.active && <i className={"fas fa-trash-can mr-1 text-pink-500"} title={"Smazaný"} />}
      <NavLink to={`/warehouse/${row.uuid}`} style={{ wordBreak: "break-all" }}>{row.label}</NavLink>
    </Tmpl>
  )

  const countTmpl = (row) => <Tmpl title={"Počet"}>{row.count}</Tmpl>

  const machineTmpl = (row) => (
    <Tmpl title={"Stroj"}>
      {row.machines.map((machine) => machine.name).join(",")}
    </Tmpl>
  )
  const [machine, setMachine] = useState(search.machine || null)
  const handleMachineChange = (e) => setMachine(e.target.value)
  const machineFilter = <MachineFilter value={machine} onChange={handleMachineChange} />

  const { data: warehouses = [] } = useWarehouseWarehouses()
  const warehouseTmpl = (row) => <Tmpl title={"Sklad"}>{row.warehouse.name}</Tmpl>
  const [warehouse, setWarehouse] = useState(search.warehouse || "")
  const handleWarehouseChange = (e) => setWarehouse(e.target.value)
  const warehouseFilter = (
    <Dropdown options={warehouses} value={warehouse} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Sklad"} showClear={true} filter={true}
      onChange={handleWarehouseChange} />
  )

  const { data: warehouseStates = [] } = useWarehouseStates()
  const getWarehouseStateName = (state) => {
    const warehouseState = warehouseStates.find((ws) => ws.value === state)
    if (warehouseState) {
      return warehouseState.name
    }
    return ""
  }
  const stateTmpl = (row) => <Tmpl title={"Status dílu"}>{getWarehouseStateName(row.state)}</Tmpl>
  const [state, setState] = useState(search.state || "")
  const handleStateChange = (e) => setState(e.target.value)
  const stateFilter = (
    <Dropdown options={warehouseStates} value={state} optionLabel={"name"}
      optionValue={"value"} placeholder={"Stav"} showClear={true} filter={true}
      onChange={handleStateChange} />
  )

  const dateTmpl = (row) => <Tmpl
    title={"Termín pohybu"}>{moment(row.date).format("DD. MM. YYYY")}</Tmpl>


  const operationTmpl = (row) => <Tmpl title={"Operace"}>{row.warehouse.operation.name}</Tmpl>
  const [operation, setOperation] = useState(search.operation || (user.operation ? user.operation.uuid : ""))
  const handleOperationChange = (e) => setOperation(e.target.value)
  const operationFilter = (
    <Dropdown options={operations} value={operation} onChange={handleOperationChange} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Operace"} showClear={true} filter={true} />
  )

  const positionTmpl = (row) => <Tmpl title={"Umístění"}>{row.position.name}</Tmpl>

  const supplierTmpl = (row) => (
    <Tmpl title={"Dodavatel"}>
      {row.supplier ? row.supplier.name : ""}
    </Tmpl>
  )
  const [supplier, setSupplier] = useState(search.supplier || null)
  const handleSupplierChange = (e) => setSupplier(e.target.value)
  const supplierFilter = <SupplierFilter value={supplier} onChange={handleSupplierChange} />

  const noteTmpl = (row) => <Tmpl title={"Poznámka"}>{row.note}</Tmpl>

  const [selectedRow, setSelectedRow] = useState("")
  const onRowClick = (e) => setSelectedRow(
    selectedRow && selectedRow.uuid === e.data.uuid
      ? ""
      : e.data
  )

  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const toggleFilters = () => setIsFiltersVisible(!isFiltersVisible)

  const header = (
    <Button className={"button-toggle-filters m-1 p-button-raised p-button-text"}
      label={"Filtrovat"} onClick={toggleFilters} />
  )

  useEffect(() => {
    setIsLoading(true)

    let params = {
      limit, offset, state, category, part, warehouse, supplier, machine,
      sortField, sortOrder, operation
    }

    if (showDeleted) {
      params.showDisabled = 1
    }

    const queryParamsStr = query.toString(params)

    history.push({ search: queryParamsStr })

    api.get(`/warehouses${queryParamsStr}`)
      .then(({ data, headers }) => {
        setTotal(parseInt(headers["x-total-count"], 10))
        setData(data.data)
      })
      .finally(() => setIsLoading(false))
  }, [limit, offset, history, sortField, sortOrder, state, category, part, warehouse, supplier,
    machine, operation, showDeleted])

  return (
    <>
      <div className={"col-12"}>
        <div className={"flex align-items-center"}>
          <NavLink to={"/warehouse"} className={"p-button m-1"}>Naskladnit díl</NavLink>

          <InputSwitch checked={showDeleted} onChange={handleShowDeletedChange}
            className={"ml-auto mr-1"} inputId={"showDeleted"} />
          <label htmlFor={"showDeleted"}>Zobrazit smazané</label>
        </div>
      </div>

      <div className={"col-12"}>
        <DataTable emptyMessage={"Žádné záznamy"} value={data} paginator={true} lazy={true}
          rows={limit} first={offset} totalRecords={total} onPage={onPage}
          loading={isLoading} onSort={onSort} sortField={sortField}
          sortOrder={sortOrder}
          className={`p-datatable-sm p-datatable-collapsible ${isFiltersVisible ? "p-datatable-filters" : ""}`}
          selectionMode={"single"} selection={selectedRow} onRowClick={onRowClick}
          header={header}
          paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
          rowsPerPageOptions={[20, 50, 100]}>
          <Column header={"Označení"} body={labelTmpl} sortable={true} sortField={"label"} />
          <Column header={"Kategorie"} body={categoryTmpl} sortable={true}
            sortField={"category"} filter={true} filterElement={categoryFilter} />
          <Column header={"Ev. č."} body={referenceNumberTmpl} sortable={true}
            sortField={"reference_number"} className={"p-column-hidden"} />
          <Column header={"Díl"} body={partTmpl} sortable={true}
            sortField={"part"} filter={true} filterElement={partFilter}
            className={"p-column-hidden"} />
          <Column header={"Počet"} body={countTmpl} className={"p-column-hidden"} />
          <Column header={"Stroj"} body={machineTmpl} sortable={true}
            sortField={"machine"} filter={true} filterElement={machineFilter}
            className={"p-column-hidden"} />
          <Column header={"Sklad"} body={warehouseTmpl} sortable={true} sortField={"warehouse"}
            filter={true} filterElement={warehouseFilter} className={"p-column-hidden"} />
          <Column header={"Statut dílu"} body={stateTmpl} filter={true}
            filterElement={stateFilter} className={"p-column-hidden"} />
          <Column header={"Termín pohybu"} body={dateTmpl} sortable={true} sortField={"date"}
            className={"p-column-hidden"} />
          <Column header={"Umístění"} body={positionTmpl} sortable={true}
            sortField={"position"} className={"p-column-hidden"} />
          <Column header={"Provoz"} body={operationTmpl} sortable={true} filter={true} filterElement={operationFilter} sortField={"operation"} className={"p-column-hidden"} />
          <Column header={"Dodavatel"} body={supplierTmpl} sortable={true}
            sortField={"supplier"} filter={true} filterElement={supplierFilter}
            className={"p-column-hidden"} />
          <Column header={"Poznámka"} body={noteTmpl} className={"p-column-hidden"} />
        </DataTable>
      </div>
    </>
  )
}
