import { Dropdown } from "primereact/dropdown"
import { useSuppliers } from "../api/queries/useSuppliers"

export default function SupplierFilter(props) {
  const { data: suppliers = [] } = useSuppliers()

  return (
    <Dropdown options={suppliers} value={props.value} optionLabel={"name"}
      optionValue={"uuid"} placeholder={"Dodavatel"} showClear={true} filter={true}
      onChange={props.onChange} />
  )
}
