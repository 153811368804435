import { useContext, useEffect, useState } from "react"
import { useParams, useHistory, NavLink } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Badge } from "primereact/badge"
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider"
import { useImmer } from "use-immer"
import api from "../../api"
import userContext from "../../context/userContext"
import commonStyles from "../common.module.css"
import FileUpload from "../File/FileUpload"

export default function GFPSubTaskForm() {
  const { hasRole, isAdmin } = useContext(userContext)
  const { uuid } = useParams()
  const history = useHistory()
  const back = () => history.goBack()
  const backToForm = () => history.push(`/gfp/${uuid}`)

  const [isGWPConfirmed, setIsGWPConfirmed] = useState(false)
  const [isGWPDialogVisible, setIsGWPDialogVisible] = useState(false)
  const onGWPDialogHide = () => {
    setIsGWPConfirmed(true)
    setIsGWPDialogVisible(false)
  }

  const [task, setTask] = useState({
    machine: { name: "" },
  })
  const [refresher, setRefresher] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [subTasks, setSubTasks] = useImmer([])
  const handleSubTaskChange = (subTask, key, value) => {
    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)

      if (key === "value") {
        value = parseFloat(value)
      }

      draftSubTask[key] = value
    })
  }

  const setSubTaskFiles = (subTask, files) => {
    setSubTasks((draft) => {
      const draftSubTask = draft.find((draftSubTask) => draftSubTask.uuid === subTask.uuid)
      draftSubTask.files = [...files]
    })
  }

  const [subTaskOpen, setSubTaskOpen] = useState(null)
  const openSubTask = (subTask) => setSubTaskOpen(subTask)

  const updateSubtask = async (subTask, state) => {
    setIsLoading(true)

    await api.post(`/gfs/${uuid}`, { uuid, state: "IN_PROGRESS" })

    const params = {
      uuid: subTask.uuid, state, state_description: subTask.state_description,
      files: subTask.files, problem_description: subTask.problem_description,
    }

    if (subTask.value) {
      params.value = subTask.value
    }

    await api.post(`/gfs/${uuid}/sub/${subTask.uuid}`, params)

    // new files
    const files = subTask.files.filter((file) => !file.createdBy)

    for (let i = 0; i < files.length; i++) {
      const file = Object.assign({}, files[i])
      delete file.uuid
      await api.put(`/gfs/${uuid}/sub/${subTask.uuid}/file`, file)
    }

    setRefresher(!refresher)
    setIsLoading(false)
  }

  const updateSubtaskNOK = (subTask) => {
    updateSubtask(subTask, "NOK")
    setSubTaskOpen(null)
  }

  const reopenSubtask = async (subTask) => {
    await api.post(`/gfs/${uuid}`, { uuid, state: "IN_PROGRESS" })
    await api.post(`/gfs/${uuid}/sub/${subTask.uuid}`, {
      uuid: subTask.uuid,
      state: "UNKNOWN",
    })
    setRefresher(!refresher)
  }

  const finishGFPTask = async () => {
    let state = "FINISHED"

    if (subTasks.some((subTask) => subTask.state === "UNKNOWN")) {
      state = "NOT_FINISHED"
    }

    await api.post(`/gfs/${uuid}`, { uuid, state })
    setRefresher(!refresher)
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/gfs/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)
          setSubTasks((draft) => {
            draft.splice(0, draft.length)
            data.data.tasks.forEach((subTask) => draft.push(subTask))
          })
        })
    }
  }, [uuid, refresher, setSubTasks])

  useEffect(() => {
    if (task.machine.gwp && task.machine.gwp.length > 0 && !isGWPConfirmed) {
      setIsGWPDialogVisible(true)
    }
  }, [task, isGWPConfirmed])

  const getSubTaskBadgeValue = (subTask) => {
    if (subTask.state === "UNKNOWN") {
      return "otevřeno"
    }

    return subTask.state.toLowerCase()
  }

  const getSubTaskStateSeverity = (subTask) => {
    if (subTask.state === "OK") {
      return "success"
    }

    if (subTask.state === "NOK") {
      return "danger"
    }

    return "info"
  }

  const isTaskFisnihed = task.state === "FINISHED" || task.state === "NOT_FINISHED"

  return (
    <div className={"col-12 md:col-6"}>
      <div className={"p-fluid"}>
        <div className={"field"}>
          <label>Číslo:</label>
          <InputText value={task.id || ""} disabled={true} />
        </div>

        <div className={"field"}>
          <label>Stroj:</label>
          <InputText value={task.machine.name || ""} disabled={true} />
        </div>

        {subTasks.map((subTask, i) => (
          <div key={subTask.uuid}>
            <Divider>
              <Badge className={"mr-1"}
                value={getSubTaskBadgeValue(subTask)}
                severity={getSubTaskStateSeverity(subTask)}
              />
              <label>Podúkol č. {i + 1}: <i className={"my-2"}>{subTask.description}</i></label>
            </Divider>

            {subTask.last_nok && (
              <div className="mb-3 text-pink-500 flex align-items-center">
                <i className="fa-solid fa-triangle-exclamation mr-2" />
                <span>
                  Na předchozí směně byl úkol dokončen stavem NOK s poznámkou:{" "}
                  <i>{subTask.last_nok.state_description || "bez poznámky"}</i>
                </span>
              </div>
            )}

            {subTask.value_description && (
              <div className={"field"}>
                <label className={commonStyles.labelRequired}>
                  {subTask.value_description} ({subTask.value_unit})
                </label>
                <InputText placeholder={subTask.value_description} type={"number"}
                  value={subTask.value || ""} disabled={subTask.state !== "UNKNOWN"}
                  onChange={(e) => handleSubTaskChange(subTask, "value", e.target.value)} />
              </div>
            )}

            <div className={"field"}>
              <label>Poznámka:</label>
              <div className={"p-inputgroup"}>
                <InputText value={subTask.state_description || ""}
                  placeholder={`Poznámka k podúkolu č. ${i + 1}`}
                  disabled={subTask.state !== "UNKNOWN" || isTaskFisnihed}
                  onChange={(e) => handleSubTaskChange(subTask, "state_description", e.target.value)} />

                {subTask.state === "UNKNOWN"
                  && !subTaskOpen
                  && !isTaskFisnihed
                  && (
                    <>
                      <Button type={"button"}
                        className={"p-button-success"} icon={"pi pi-check"}
                        onClick={() => updateSubtask(subTask, "OK")}
                        disabled={(subTask.value_description && !subTask.value) || isLoading} />

                      <Button type={"button"}
                        className={"p-button-danger"} icon={"pi pi-times"}
                        // onClick={() => updateSubtask(subTask, "NOK")}
                        onClick={() => openSubTask(subTask)}
                        disabled={isLoading} />
                    </>
                  )}

                {subTask.state !== "UNKNOWN"
                  && !isTaskFisnihed
                  && (
                    <Button type={"button"} title={"Znovu otevřít podúkol"}
                      className={"p-button-primary"} icon={"pi pi-refresh"}
                      onClick={() => reopenSubtask(subTask)} />
                  )}
              </div>

              {((subTaskOpen && subTaskOpen.uuid === subTask.uuid)
                || (subTask.state === "NOK" && subTask.problem_description)) && (
                  <div className={"field mt-3"}>
                    <label className={subTask.state === "UNKNOWN" ? commonStyles.labelRequired : ""}>Popis problému:</label>
                    <InputText value={subTask.problem_description || ""}
                      placeholder={"Uvedťe popis problému"}
                      disabled={!subTaskOpen}
                      onChange={(e) => handleSubTaskChange(subTask, "problem_description", e.target.value)} />

                    {subTaskOpen && subTaskOpen.uuid === subTask.uuid && (
                      <Button type={"button"} label={"Uložit"} className={"p-button-danger mt-3"}
                        disabled={!subTask.problem_description} onClick={() => updateSubtaskNOK(subTask)} />
                    )}
                  </div>
                )}

              <div className={"field mt-3"}>
                {(subTask.files.length > 0 || subTask.state === "UNKNOWN" || !isTaskFisnihed) && (
                  <label>Přílohy k dokončení podúkolu:</label>
                )}

                <FileUpload files={subTask.files}
                  setFiles={(files) => setSubTaskFiles(subTask, files)}
                  disabled={subTask.state !== "UNKNOWN" || isTaskFisnihed}
                  showClear={subTask.state === "UNKNOWN"} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={"flex"}>
        <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
          onClick={back} />

        {(isAdmin() || hasRole("SUPERVISOR")) && (
          <Button type={"button"} label={"Přehled"} className={"p-button-warning m-1"}
            onClick={backToForm} />
        )}

        {!hasRole("USER") &&
          task.state === "IN_PROGRESS" &&
          !subTasks.some((subTask) => subTask.state === "UNKNOWN") && (
            <Button type={"button"} className={"p-button-primary m-1"}
              onClick={finishGFPTask} label={"Dokončit Go first part"} />
          )}
      </div>

      <Dialog visible={isGWPDialogVisible} header={"Na tomto stroji jsou aktivní GWP:"}
        closable={false} draggable={false}
        footer={
          <Button label="Beru na vědomí" onClick={onGWPDialogHide} />
        }>
        {task.machine.gwp && task.machine.gwp.map((item, index) => (
          <div className="mb-3">
            <h3 className="m-0">
              {index + 1}.{" "}
              <NavLink to={`/gwp/${item.uuid}`} className="text-primary">
                {item.name}
                <i className="pi pi-link ml-2" style={{ fontSize: ".9rem" }} />
              </NavLink>
            </h3>
            <p className="m-0">{item.description}</p>
          </div>
        ))}
      </Dialog>
    </div>
  )
}
