import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {InputText} from "primereact/inputtext"
import {Dropdown} from "primereact/dropdown"
import {Button} from "primereact/button"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { useOperations } from "../../../api/queries/useOperations"
import backConfirmation from "../../../function/backConfirmation"
import confirmation from "../../../function/confirmation"
import { queryKeys } from "../../../client"
import { useQueryClient } from "react-query"

export default function EnumPlaceForm() {
  const {uuid} = useParams()
  const { data: operations = [] } = useOperations()
  const back = () => window.history.back()
  const queryClient = useQueryClient()

  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const [operation, setOperation] = useState("")
  const handleOperationChange = (e) => setOperation(e.target.value)

  const create = () => api
    .put("/places", {name, operation})
    .then(back)
  const update = () => api
    .post(`/places/${uuid}`, {uuid, name, operation})
    .then(back)
  const remove = () => {
    queryClient.invalidateQueries(queryKeys.PLACES, { refetchInactive: true })
    api.delete(`/places/${uuid}`)
    .then(back)
  }
  const removeConfirmation = (e) => confirmation(e.currentTarget, "Opravdu smazat?", remove)
  const handleFormSubmit = (e) => {
    e.preventDefault()
    queryClient.invalidateQueries(queryKeys.PLACES, { refetchInactive: true })
    uuid ? update() : create()
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/places/${uuid}`)
        .then(({data}) => {
          setName(data.data.name)
          setOperation(data.data.operation.uuid)
        })
    }
  }, [uuid])

  return (
    <userContext.Consumer>
      {({hasPerm, isAdmin}) => (
        <form className={"col-12 md:col-6"} onSubmit={handleFormSubmit}>
          <div className={"p-fluid"}>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Název:</label>
              <InputText required={true} value={name} onChange={handleNameChange}/>
            </div>
            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Provoz:</label>
              <Dropdown required={true} value={operation} options={operations} optionValue={"uuid"}
                        optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                        onChange={handleOperationChange}/>
            </div>
          </div>

          <div className={"flex"}>
            <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
                    onClick={backConfirmation}/>
            <div className={"ml-auto"}>
              <Button type={"button"} label={"Smazat"} className={"p-button-danger m-1"}
                      onClick={removeConfirmation}
                      disabled={!uuid || !isAdmin()}/>
              <Button type={"submit"} label={"Uložit"} className={"m-1"}
                      disabled={!hasPerm("ROLE_PERM_ENUMS_WRITE")}/>
            </div>
          </div>
        </form>
      )}
    </userContext.Consumer>
  )
}
