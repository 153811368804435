import axios from "axios"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

const envName = process.env.REACT_APP_ENV_NAME
const lsKey = `dora.auth${envName}`

api.setToken = (token) => {
  localStorage.setItem(lsKey, token)
  api.defaults.headers.common.Authorization = `Bearer ${token}`
}

api.removeToken = () => {
  localStorage.removeItem(lsKey, token)
  delete api.defaults.headers.common.Authorization
}

const token = localStorage.getItem(lsKey)

if (token) {
  api.setToken(token)
}

export default api
