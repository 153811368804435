import React from "react"
import styles from "./styles.module.css"

export default function Initials({name}) {
  if(!name) {
    return ""
  }

  const split = name.split(" ")
  let initials

  if(split.length === 1) {
    initials = split[0]
      .charAt(0)
      .toUpperCase()
  }

  if(split.length > 1) {
    initials = split[0]
      .charAt(0)
      .toUpperCase() + split[1]
      .charAt(0)
      .toUpperCase()
  }

  return (
    <div className={styles.initials} title={name}>
      {initials}
    </div>
  )
}
