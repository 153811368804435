const getSubTaskStateName = (state) => {
  if (state === "FINISHED") {
    return "dokončeno"
  }

  if (state === "NOT_FINISHED") {
    return "nedokončeno"
  }

  return "otevřeno"
}

export default getSubTaskStateName
